import React from "react";

const Footer = () => {
  return (
    <div className="row">
      <div className="col-12 col-xl-12">
        {/* <!--Animated Block--> */}
        <div className="ptf-animated-block" data-aos="fade" data-aos-delay="0">
          <div className="ptf-footer-heading text-white">
            Boost Your Business Up To High Level
          </div>

          <a className="fz-40 " href="mailto:support@githubit.com">
            support@githubit.com
          </a>
          <br />
          <a className="fz-40 " href="mailto:mr.nigmatov@gmail.com ">
            mr.nigmatov@gmail.com
          </a>
          <br />
          <a className="fz-40 " href="tel:+12023401032">
            +1 202 3401032
          </a>
          <br />
          <a className="fz-40 " href="tel:+998977556914">
            +99897 755 69 14
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
