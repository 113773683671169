import React from "react";
import { Link } from "react-router-dom";

const LocalFront = () => {
  return (
    <div
      className="introduction "
      data-aos="fade-right"
      data-aos-delay="300"
      data-aos-duration="1000"
    >
      <h1 className="title">Frontend</h1>
      <p className="inner-text">
        Please, to setup frontend, download nodeJs and install it in your
        computer.
      </p>
      Windows:{" "}
      <a
        href="https://nodejs.org/dist/v16.17.0/node-v16.17.0-x64.msi"
        className="introduction-link"
      >
        https://nodejs.org/dist/v16.17.0/node-v16.17.0-x64.msi
      </a>
      <br />
      Mac:{" "}
      <a
        href="https://nodejs.org/dist/v16.17.0/node-v16.17.0.pkg"
        className="introduction-link"
      >
        https://nodejs.org/dist/v16.17.0/node-v16.17.0.pkg
      </a>
      <br />
      Linux:{" "}
      <a
        href="https://nodejs.org/dist/v16.17.0/node-v16.17.0-linux-x64.tar.xz"
        className="introduction-link"
      >
        https://nodejs.org/dist/v16.17.0/node-v16.17.0-linux-x64.tar.xz
      </a>
      <br />
      <div className="mt-4" />
      <div className="iframe-wrapper">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/ocdDqJzMbDM"
          title="How to run frontend website on MacOS"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
      <div className="mt-4" />
      <div className="iframe-wrapper">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/qhJVvkceEfk"
          title="How to run frontend website with backend on MacOS"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
      <div className="center-page-container">
        <Link to="/goshops-documentation/flutter-sdk" className="btn  previous">
          <p>Previous</p>
          <p className="link"> Mobile App </p>
        </Link>
        <Link to="/goshops-documentation/local-server" className="btn  next">
          <p>Next</p>
          <p className="link"> Backend </p>
        </Link>
      </div>
    </div>
  );
};

export default LocalFront;
