import React from "react";

const BlogCommentForm = () => {
  return (
    <form>
      <div className="ptf-form-group">
        <textarea
          rows="5"
          placeholder="Write your comment here"
          name="comment"
        />
      </div>

      <div className="ptf-form-row two-col" style={{ marginBottom: ".625rem" }}>
        <div className="ptf-form-group">
          <input placeholder="Your name *" type="text" name="name" />
        </div>
        <div className="ptf-form-group">
          <input placeholder="Your Email *" name="email" type="text" />
        </div>
      </div>

      <div
        className="ptf-form-group agreement-checkbox "
        style={{ marginBottom: "3.75rem" }}
      >
        <input name="acceptTerms" type="checkbox" id="acceptTerms" />

        <label className="ptf-checkbox" htmlFor="acceptTerms">
          <span className="ptf-checkbox__checkmark"></span>Save my name & email
          in this browser for next time I comment.
        </label>
      </div>

      <p className="form-submit">
        <button className="ptf-submit-button ptf-submit-button--style-2">
          Submit
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 17 17"
          >
            <path d="M16 .997V10h-1V2.703L4.683 13l-.707-.708L14.291 1.997H6.975v-1H16z" />
          </svg>
        </button>
      </p>
    </form>
  );
};

export default BlogCommentForm;
