import React from "react";
import { useFormik } from "formik";
import authService from "../../../../service/AuthService";
import InputPassword from "../../../../components/Input/PasswordInput";
import { setUserData } from "../../../../redux/slices/auth";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoadingIndicator from "../../../../components/Loader/loadingIndicator";
import { ArrowTopRight } from "../../../../components/Svg/goTop-svg";
import GoogleSignIn from "../GoogleSignIn";
import EnvatoSignIn from "../EnvatoSingIn";

const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values, { setSubmitting }) => {
      const body = {
        email: values.email,
        password: values.password,
      };
      authService
        .login(body)
        .then((res) => {
          const token = res.data.access_token;
          localStorage.setItem("token", token);
          dispatch(
            setUserData({ ...res.data, ...res.data.user, user: undefined })
          );
          navigate("/");
        })
        .finally(() => setSubmitting(false));
    },
    validate: (values) => {
      const errors = {};
      if (!values.email) {
        errors.email = "Required";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
      ) {
        errors.email = "Invalid email address";
      }
      if (!values.password) {
        errors.password = "required password";
      }
      return errors;
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} name="login">
      <div className="ptf-form-group">
        <label data-number="01">What’s your email address?</label>
        <input
          name="email"
          type="email"
          className={
            formik.errors?.email && formik.touched?.email ? "error" : ""
          }
          value={formik.values.email}
          onChange={formik.handleChange}
          autoComplete="off"
        />
      </div>

      <div className="ptf-form-group">
        <label data-number="02">What’s your password?</label>
        <InputPassword
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          className={
            formik.errors?.password && formik.touched?.password ? "error" : ""
          }
        />
      </div>

      <div className="login-footer">
        <button
          className="ptf-submit-button"
          type="submit"
          disabled={formik.isSubmitting}
        >
          <span>Submit</span>
          {formik.isSubmitting ? <LoadingIndicator /> : <ArrowTopRight />}
        </button>
        <div className="footer-social">
          <EnvatoSignIn />
          <GoogleSignIn />
        </div>
      </div>
    </form>
  );
};

export default LoginForm;
