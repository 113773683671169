import React from "react";
import { Route } from "react-router-dom";
import UzmartDoc from "../views/Uzmart-doc";
import UzmartIntroduction from "../views/Uzmart-doc/Introduction";
import UzmartBasic from "../views/Uzmart-doc/Basic";
import UzmartServer from "../views/Uzmart-doc/Server";
import UzmartFront from "../views/Uzmart-doc/front";
import UzmartAdmin from "../views/Uzmart-doc/admin";
import Recommendations from "../views/Foodyman-doc/recommendations";
import UzmartLocalFront from "../views/Uzmart-doc/local-front";
import UzmartMobileApp from "../views/Uzmart-doc/Mobile-app";
import UzmartFlutterSDK from "../views/Uzmart-doc/Flutter-SDK";
import UzmartLocalServer from "../views/Uzmart-doc/Local-server";
import UzmartInstallOnServer from "../views/Uzmart-doc/Install-on-server";
import UzmartMandatorySetup from "../views/Uzmart-doc/Mandatory-setup";
import UzmartCustomization from "../views/Uzmart-doc/Customization";
import UzmartMandatorySetupMobile from "../views/Uzmart-doc/Mandatory-setup-mobile";
import UzmartMandatorySetupBackend from "../views/Uzmart-doc/Mandatory-setup-backend";
import PaymentInstallation from "../views/Uzmart-doc/Payment-Installation";
import UzmartImageSettings from "../views/Uzmart-doc/image-settings";
import UzmartMandatorySetupVendor from "../views/Uzmart-doc/Moderator-setup-vendor";
import UzmartMandatorySetupCustomer from "../views/Uzmart-doc/Moderator-setup-customer";
import UzmartMandatorySetupPos from "../views/Uzmart-doc/Moderator-setup-pos";
import UzmartMandatorySetupDeliveryboy from "../views/Uzmart-doc/Moderator-setup-deliveryboy";
import UzmartCustomizationMobile from "../views/Uzmart-doc/Customization-mobile";
import CustomizationMobileDelivery from "../views/Foodyman-doc/Customization-mobile-delivery";
import UzmartCustomizationMobileCustomer from "../views/Uzmart-doc/Customization-mobile-customer";
import CustomizationMobilePos from "../views/Foodyman-doc/Customization-mobile-pos";
import AppBuildReleaseCustomer from "../views/Foodyman-doc/App-build-release-customer";
import AppBuildReleasePos from "../views/Foodyman-doc/App-build-release-pos";
import AppBuildReleaseVendor from "../views/Foodyman-doc/App-build-release-vendor";
import AppBuildReleaseDelivery from "../views/Foodyman-doc/App-build-release-deliveryboy";
import UzmartMandatorySetupWeb from "../views/Uzmart-doc/Mandatory-setup-web";
import UzmartBuildCodeAndSetupOnServer from "../views/Uzmart-doc/Build-code-and-setup-on-server";
import UzmartBuildCodeAndSetupOnServerBackend from "../views/Uzmart-doc/Build-code-and-setup-on-server-backend";
import UzmartUpdateAdminPanel from "../views/Uzmart-doc/Update-admin-panel";
import UzmartUpdateAppWeb from "../views/Uzmart-doc/Update-app-web";
import UzmartSupportPlan from "../views/Uzmart-doc/supportPlan";
import UzmartFirebaseSetup from "../views/Uzmart-doc/firebase-setup";
import TroubleshootingBackend from "../views/Foodyman-doc/Troubleshooting-backend";
import TroubleshootingAdmin from "../views/Foodyman-doc/Troubleshooting-admin";
import UpdateFooyman from "../views/Foodyman-doc/update";
import MobileVendor from "../views/Uzmart-doc/Mobile-Vendor";

const Uzmart = () => {
  return (
      <Route path="/uzmart-documentation" element={<UzmartDoc />}>
              <Route
                  path="/uzmart-documentation/introduction"
                  element={<UzmartIntroduction />}
              />
              <Route
                  path="/uzmart-documentation/basic"
                  element={<UzmartBasic />}
              />
              <Route
                  path="/uzmart-documentation/server"
                  element={<UzmartServer />}
              />
              <Route
                  path="/uzmart-documentation/front"
                  element={<UzmartFront />}
              />
              <Route
                  path="/uzmart-documentation/admin"
                  element={<UzmartAdmin />}
              />
              <Route
                  path="/uzmart-documentation/recommendations"
                  element={<Recommendations />}
              />
              <Route
                  path="/uzmart-documentation/local-front"
                  element={<UzmartLocalFront />}
              />
              <Route
                  path="/uzmart-documentation/mobile-app"
                  element={<UzmartMobileApp />}
              />
              <Route
                  path="/uzmart-documentation/vendor-app"
                  element={<MobileVendor />}
              />
              <Route
                  path="/uzmart-documentation/driver-app"
                  element={<MobileVendor />}
              />
              <Route
                  path="/uzmart-documentation/pos-app"
                  element={<MobileVendor />}
              />
              <Route
                  path="/uzmart-documentation/flutter-sdk"
                  element={<UzmartFlutterSDK />}
              />
              <Route
                  path="/uzmart-documentation/local-server"
                  element={<UzmartLocalServer />}
              />
              <Route
                  path="/uzmart-documentation/install-on-server"
                  element={<UzmartInstallOnServer />}
              />
              <Route
                  path="/uzmart-documentation/mandatory-setup"
                  element={<UzmartMandatorySetup />}
              />
              <Route
                  path="/uzmart-documentation/customization"
                  element={<UzmartCustomization />}
              />
              <Route
                  path="/uzmart-documentation/mandatory-setup-mobile"
                  element={<UzmartMandatorySetupMobile />}
              />
              <Route
                  path="/uzmart-documentation/mandatory-setup-backend"
                  element={<UzmartMandatorySetupBackend />}
              />
              <Route
                  path="/uzmart-documentation/payment-installation"
                  element={<PaymentInstallation />}
              />
              <Route
                  path="/uzmart-documentation/image-settings"
                  element={<UzmartImageSettings />}
              />
              <Route
                  path="/uzmart-documentation/mandatory-setup-vendor"
                  element={<UzmartMandatorySetupVendor />}
              />
              <Route
                  path="/uzmart-documentation/mandatory-setup-customer"
                  element={<UzmartMandatorySetupCustomer />}
              />
              <Route
                  path="/uzmart-documentation/mandatory-setup-pos"
                  element={<UzmartMandatorySetupPos />}
              />
              <Route
                  path="/uzmart-documentation/mandatory-setup-deliveryboy"
                  element={<UzmartMandatorySetupDeliveryboy />}
              />
              <Route
                  path="/uzmart-documentation/customization-vendor"
                  element={<UzmartCustomizationMobile />}
              />
              <Route
                  path="/uzmart-documentation/customization-deliveryboy"
                  element={<CustomizationMobileDelivery />}
              />
              <Route
                  path="/uzmart-documentation/customization-customer"
                  element={<UzmartCustomizationMobileCustomer />}
              />
              <Route
                  path="/uzmart-documentation/customization-pos"
                  element={<CustomizationMobilePos />}
              />
              <Route
                  path="/uzmart-documentation/customer-app-build-release"
                  element={<AppBuildReleaseCustomer />}
              />
              <Route
                  path="/uzmart-documentation/pos-app-build-release"
                  element={<AppBuildReleasePos />}
              />
              <Route
                  path="/uzmart-documentation/vendor-app-build-release"
                  element={<AppBuildReleaseVendor />}
              />
              <Route
                  path="/uzmart-documentation/deliveryboy-app-build-release"
                  element={<AppBuildReleaseDelivery />}
              />
              <Route
                  path="/uzmart-documentation/mandatory-setup-web"
                  element={<UzmartMandatorySetupWeb />}
              />
              <Route
                  path="/uzmart-documentation/build-code-and-setup-on-server"
                  element={<UzmartBuildCodeAndSetupOnServer />}
              />
              <Route
                  path="/uzmart-documentation/build-code-and-setup-on-server-backend"
                  element={<UzmartBuildCodeAndSetupOnServerBackend />}
              />
              <Route
                  path="/uzmart-documentation/update-admin-panel"
                  element={<UzmartUpdateAdminPanel />}
              />
              <Route
                  path="/uzmart-documentation/update-app-web"
                  element={<UzmartUpdateAppWeb />}
              />
              <Route
                  path="/uzmart-documentation/support-plan"
                  element={<UzmartSupportPlan />}
              />
              <Route
                  path="/uzmart-documentation/firebase"
                  element={<UzmartFirebaseSetup />}
              />

              <Route
                  path="/uzmart-documentation/troubleshooting-backend"
                  element={<TroubleshootingBackend />}
              />
              <Route
                  path="/uzmart-documentation/troubleshooting-admin"
                  element={<TroubleshootingAdmin />}
              />
              <Route
                  path="/uzmart-documentation/update"
                  element={<UpdateFooyman />}
              />
      </Route>
  );
};

export default Uzmart;
