import React from "react";
// Homepage Demo

import HomeDark from "../views/All-home-version/HomeDark";

// Service
import Service from "../views/Inner-pages/service/Service";
import ServiceDetails from "../views/Inner-pages/service/ServiceDetails";

// About
import AboutUs from "../views/Inner-pages/about/AboutUs";
import AboutMe from "../views/Inner-pages/about/AboutMe";

// Blog
import BlogGrid from "../views/Inner-pages/blog/BlogGrid";
import BlogMasonry from "../views/Inner-pages/blog/BlogMasonry";
import BlogSidebar from "../views/Inner-pages/blog/BlogSidebar";
import BlogDetails from "../views/Inner-pages/blog/BlogDetails";
import BlogDetailsSidebar from "../views/Inner-pages/blog/BlogDetailsSidebar";

// Portfolio
import WorksGrid from "../views/Inner-pages/portfolio/WorksGrid";
import WorksMasonry from "../views/Inner-pages/portfolio/WorksMasonry";
import WorksListing from "../views/Inner-pages/portfolio/WorksListing";
import WorksCarousel from "../views/Inner-pages/portfolio/WorksCarousel";
import WorksShowcase from "../views/Inner-pages/portfolio/WorksShowcase";

// Others
import NotFound from "../views/NotFound";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollTopBehaviour from "../components/ScrollTopBehaviour";
import Login from "../views/Inner-pages/auth/login/login";
import Register from "../views/Inner-pages/auth/register";

import NewTicket from "../views/Inner-pages/ticket/new-ticket/newTicket";
import Tickets from "../views/Inner-pages/ticket/tikets/tickets";
import TicketChat from "../views/Inner-pages/ticket/tikets/ticketChat";
import Contact from "../views/Inner-pages/Contact";

import Protected from "../layouts/Protected";
import GoshopsDemo from "../views/Inner-pages/service/Goshops-multi-shop";
import EnvatoAuth from "../views/Inner-pages/auth/envato";

// career page
import Career from "../views/Inner-pages/career/career";
import ForJobForm from "../views/Inner-pages/for_job_form";

// vendor doc
import VendorDocumentations from "../views/Vendor-documentations";
import VendorCustomizationMobile from "../views/Vendor-documentations/Customization-mobile";
import VendorAppBuildRelease from "../views/Vendor-documentations/App-build-release";
import VendorUpdateAppWeb from "../views/Vendor-documentations/Update-app-web";

import Activator from "../views/Inner-pages/activator";
import AllWebProduct from "../views/Inner-pages/service/AllWebProduct";
import AllAndroidProduct from "../views/Inner-pages/service/AllAndroidProduct";
import FoodymanSingle from "../views/Inner-pages/service/FoodymanSingleServise";
import FoodymanMulti from "../views/Inner-pages/service/FoodymanMultiServise";
import QrcodeMulti from "../views/Inner-pages/service/QrcodeMultiServise";
import GoshopsMultiShop from "../views/Inner-pages/service/Goshops-multi-shop";
import GoshopsMultiSeller from "../views/Inner-pages/service/Goshops-multi-seller";
import GoshopSingleShop from "../views/Inner-pages/service/Goshops-single-shop";
import SundaymartMultiVendor from "../views/Inner-pages/service/Sundaymart-multi-vendor";
import SundaymartMultiVendorNew from "../views/Inner-pages/service/Sundaymart-multi-vendor-new";
import UserProfile from "../components/User/User-profile";

// damda
import DamdaService from "../views/Inner-pages/service/Damda/DamdaService";
import DamdaLandlord from "../views/Inner-pages/service/Damda/DamdaLandlord";
import DamdaApp from "../views/Inner-pages/service/Damda/DamdaSayyoh";

import UzmartMultiServise from "../views/Inner-pages/service/UzmartMultiServise";
import MobileUiKitService from "../views/Inner-pages/service/Mobile-ui-kit-service";
import SaasMulti from "../views/Inner-pages/service/SaasMultiServise";
import Demand24MultiService from "../views/Inner-pages/service/Demand24MultiServise";

//Routes
import Goshops from "./Routing-goshops";
import Saas from "./Routing-saas";
import Sundaymart from "./Routing-sundaymart";
import Foodyman from "./Routing-foodyman";
import Uzmart from "./Routing-uzmart";
import Qrcode from "./Routing-qrcode";
import SingleFoodyman from "./Routing-single-foodyman";
import Gstore from "./Routing-gstore";
import Demand24 from "./Routing-demand24";
import SingleUzmart from "./Routing-single-uzmart";
import SingleUzmartMultiServise from "../views/Inner-pages/service/SingleUzmartMultiServise";

const Routing = () => {
  return (
    <>
      <BrowserRouter>
        <ScrollTopBehaviour />
        <Routes>
          <Route exact path="/" element={<HomeDark />} />
          {/* Service */}
          <Route path="/career" element={<Career />} />
          <Route path="/career/form" element={<ForJobForm />} />
          <Route path="/service" element={<Service />} />
          <Route path="/service-details" element={<ServiceDetails />} />
          <Route path="/goshop-multi-shop" element={<GoshopsMultiShop />} />
          <Route path="/goshop-multi-seller" element={<GoshopsMultiSeller />} />
          <Route path="/goshop-single-shop" element={<GoshopSingleShop />} />

          {/* damda service */}
          <Route path="/damda-service" element={<DamdaService />} />
          <Route path="/damda-landlord" element={<DamdaLandlord />} />
          <Route path="/damda-app" element={<DamdaApp />} />

          {/* sundaymart service */}
          <Route
            path="/sundaymart-multi-vendor"
            element={<SundaymartMultiVendor />}
          />
          <Route
            path="/sundaymart-multi-vendor-new"
            element={<SundaymartMultiVendorNew />}
          />
          <Route path="/foodyman-multi-service" element={<FoodymanMulti />} />
          <Route path="/qrcode-multi-service" element={<QrcodeMulti />} />
          <Route path="/saas-multi-service" element={<SaasMulti />} />
          <Route path="/foodyman-single-service" element={<FoodymanSingle />} />
          <Route
            path="/mobile-ui-kit-service"
            element={<MobileUiKitService />}
          />

          <Route
            path="/uzmart-multi-service"
            element={<UzmartMultiServise />}
          />

          <Route
            path="/uzmart-single-multi-service"
            element={<SingleUzmartMultiServise />}
          />

          <Route
            path="/demand24-multi-service"
            element={<Demand24MultiService />}
          />

          <Route path="/demo" element={<GoshopsDemo />} />
          <Route path="/all-web-project" element={<AllWebProduct />} />
          <Route path="/all-android-project" element={<AllAndroidProduct />} />

          {/* About */}
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/about-me" element={<AboutMe />} />

          {/* Blog */}
          <Route path="/blog-grid" element={<BlogGrid />} />
          <Route path="/blog-masonry" element={<BlogMasonry />} />
          <Route path="/blog-sidebar" element={<BlogSidebar />} />
          <Route path="/blog-details" element={<BlogDetails />} />
          <Route
            path="/blog-details-sidebar"
            element={<BlogDetailsSidebar />}
          />

          {/* Portfolio */}
          <Route path="/works-grid" element={<WorksGrid />} />
          <Route path="/works-listing" element={<WorksListing />} />
          <Route path="/works-carousel" element={<WorksCarousel />} />
          <Route path="/works-showcase" element={<WorksShowcase />} />

          {/* others */}
          <Route path="/login" element={<Login />} />
          <Route path="/registration" element={<Register />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/activator" element={<Activator />} />
          <Route path="/envato/auth" element={<EnvatoAuth />} />

          {/* projects */}
          <Route path="/projects" element={<WorksMasonry />} />
          <Route path="/projects/gshop" element={<WorksShowcase />} />

          <Route path="/user-profile" element={<UserProfile />} />
          {/* protected */}
          <Route
            path="/new-ticket"
            element={
              <Protected>
                <NewTicket />
              </Protected>
            }
          />
          <Route
            path="/tickets"
            element={
              <Protected>
                <Tickets />
              </Protected>
            }
          />
          <Route
            path="/tickets/:id"
            element={
              <Protected>
                <TicketChat />
              </Protected>
            }
          />

          {/*vendor documentation */}
          <Route
            path="/vendor-documentation"
            element={<VendorDocumentations />}
          >
            <Route
              path="/vendor-documentation/customization-mobile"
              element={<VendorCustomizationMobile />}
            />
            <Route
              path="/vendor-documentation/app-build-release"
              element={<VendorAppBuildRelease />}
            />
            <Route
              path="/vendor-documentation/update-app"
              element={<VendorUpdateAppWeb />}
            />
          </Route>
          {Goshops()}
          {Saas()}
          {Sundaymart()}
          {Foodyman()}
          {Uzmart()}
          {SingleUzmart()}
          {Qrcode()}
          {SingleFoodyman()}
          {Gstore()}
          {Demand24()}
          {/*page notfound*/}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Routing;
