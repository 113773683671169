export const qrcodeDocMenu = [
  {
    name: "Introduction",
    dropDownItems: [
      {
        name: "Introduction",
        routerPath: "/qrcode-documentation/introduction",
      },
      {
        name: "Server requirements",
        routerPath: "/qrcode-documentation/recommendations",
      },
    ],
  },
  {
    name: "Backend Api",
    dropDownItems: [
      {
        name: "Requirements",
        routerPath: "/qrcode-documentation/server",
      },
      {
        name: "Installation",
        routerPath: "/qrcode-documentation/mandatory-setup-backend",
      },
      {
        name: "Payment installation",
        routerPath: "/qrcode-documentation/payment-installation",
      },
      {
        name: "Troubleshooting",
        routerPath: "/qrcode-documentation/troubleshooting-backend",
      },
    ],
  },
  {
    name: "Admin Panel",
    dropDownItems: [
      {
        name: "Requirements",
        routerPath: "/qrcode-documentation/admin",
      },
      {
        name: "Installation",
        routerPath: "/qrcode-documentation/install-on-server",
      },
      {
        name: "Troubleshooting",
        routerPath: "/qrcode-documentation/troubleshooting-admin",
      },
    ],
  },
  {
    name: "Customer Website",
    dropDownItems: [
      {
        name: "Requirements",
        routerPath: "/qrcode-documentation/front",
      },
      {
        name: "Installation",
        routerPath: "/qrcode-documentation/mandatory-setup-web",
      },
      // {
      //   name: "Troubleshooting",
      //   routerPath: "/",
      // },
    ],
  },
  // {
  //   name: "Customer App",
  //   dropDownItems: [
  //     {
  //       name: "Requirements",
  //       routerPath: "/qrcode-documentation/mobile-app",
  //     },
  //     {
  //       name: "Installation",
  //       routerPath: "/qrcode-documentation/mandatory-setup-customer",
  //     },
  //     {
  //       name: "Customization",
  //       routerPath: "/qrcode-documentation/customization-customer",
  //     },
  //     {
  //       name: "App build & release",
  //       routerPath: "/qrcode-documentation/customer-app-build-release",
  //     },
  //     // {
  //     //   name: "Troubleshooting",
  //     //   routerPath: "/",
  //     // },
  //   ],
  // },
  // {
  //   name: "Vendor App",
  //   dropDownItems: [
  //     {
  //       name: "Requirements",
  //       routerPath: "/qrcode-documentation/vendor-app",
  //     },
  //     {
  //       name: "Installation",
  //       routerPath: "/qrcode-documentation/mandatory-setup-vendor",
  //     },
  //     {
  //       name: "Customization",
  //       routerPath: "/qrcode-documentation/customization-vendor",
  //     },
  //     {
  //       name: "App build & release",
  //       routerPath: "/qrcode-documentation/vendor-app-build-release",
  //     },
  //     // {
  //     //   name: "Troubleshooting",
  //     //   routerPath: "/",
  //     // },
  //   ],
  // },
  {
    name: "Waiter App",
    dropDownItems: [
      {
        name: "Requirements",
        routerPath: "/qrcode-documentation/waiter-app",
      },
      {
        name: "Installation",
        routerPath: "/qrcode-documentation/mandatory-setup-waiter",
      },
      {
        name: "Customization",
        routerPath: "/qrcode-documentation/customization-waiter",
      },
      {
        name: "App build & release",
        routerPath: "/qrcode-documentation/waiter-app-build-release",
      },
      // {
      //   name: "Troubleshooting",
      //   routerPath: "/",
      // },
    ],
  },
  {
    name: "Pos App",
    dropDownItems: [
      {
        name: "Requirements",
        routerPath: "/qrcode-documentation/pos-app",
      },
      {
        name: "Installation",
        routerPath: "/qrcode-documentation/mandatory-setup-pos",
      },
      {
        name: "Customization",
        routerPath: "/qrcode-documentation/customization-pos",
      },
      {
        name: "App build & release",
        routerPath: "/qrcode-documentation/pos-app-build-release",
      },
      // {
      //   name: "Troubleshooting",
      //   routerPath: "/",
      // },
    ],
  },
  {
    name: "Firebase setup",
    routerPath: "/qrcode-documentation/firebase",
  },
  {
    name: "Update",
    routerPath: "/qrcode-documentation/update",
  },
];
