import React, { useState } from "react";
import RegistrationForm from "./registrationForm";
import ConfirmPasswordEmail from "./confirmPasswordEmail";
import RegistrationDetailsForm from "./registrationDetailsForm";
import { Helmet } from "react-helmet";
import HeaderDark from "../../../../components/Header/HeaderDark";
import Address from "../../../../components/Address";
import FooterThreeDark from "../../../../components/Footer/FooterThreeDark";
import CopyRight from "../../../../components/Footer/Copyright/CopyRight";
import { Link } from "react-router-dom";
import FooterFour from "../../../../components/Footer/FooterFour";

const Registration = () => {
  const [step, setStep] = useState(0);
  const [verifyEmail, setVerifyEmail] = useState(null);

  const renderForm = () => {
    switch (step) {
      case 0:
        return (
          <RegistrationForm setStep={setStep} setVerifyEmail={setVerifyEmail} />
        );

      case 1:
        return (
          <ConfirmPasswordEmail
            setStep={setStep}
            verifyEmail={verifyEmail}
            setVerifyEmail={setVerifyEmail}
          />
        );

      case 2:
        return <RegistrationDetailsForm verifyEmail={verifyEmail} />;
      default:
        break;
    }
  };

  return (
    <div>
      <Helmet>
        <title>GitHubit - Registration</title>
      </Helmet>
      <HeaderDark />

      <div className="ptf-main">
        <div className="ptf-page ptf-page--contact">
          <section>
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
            />

            <div className="container-xxl">
              <div className="row">
                <div className="col-xl-10">
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <h1 className="large-heading text-white">Registration</h1>
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "3rem", "--ptf-md": "2.5rem" }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
            />
          </section>

          <section>
            <div className="container-xxl">
              <div className="row">
                <div className="col-lg-4">
                  <Address />
                </div>

                <div className="col-lg-8">
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="300"
                  >
                    <h5 className="fz-16 text-uppercase has-black-color fw-normal">
                      You already have an account, please{" "}
                      <Link to="/login" className="text-danger">
                        Login
                      </Link>
                    </h5>
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "3.125rem" }}
                    />
                    {renderForm()}
                  </div>
                </div>
              </div>
            </div>

            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
            />
          </section>
        </div>
      </div>
    </div>
  );
};

export default Registration;
