import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import NavigationBtns from "./Navigation-btns";

const Introduction = () => {
  return (
    <div
      className="introduction "
      data-aos="fade-right"
      data-aos-delay="300"
      data-aos-duration="1000"
    >
      <div className="alert alert-primary mb-5" role="alert">
        <div className="mb-2">
          <AiOutlineInfoCircle size={22} /> <strong>INFO</strong>
        </div>
        Keep /configs/credential.php and /configs/init.php file while updating.
        If you don’t keep them, you may have a problem with lisence.
      </div>

      <h1 className="title">Introduction</h1>
      <strong className="introduction-subTitle">
        Build Your Business with our -
        <strong className="strong"> Website</strong>
      </strong>
      <div className="iframe-wrapper mb-5">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/ftMmawk41OA"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
      <strong className="introduction-subTitle">
        Build Your Business with our -
        <strong className="strong"> Mobile app</strong>
      </strong>
      <div className="iframe-wrapper mb-5">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/NKv9g64_qnM"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        />
      </div>
      <strong className="introduction-subTitle">
        Build Your Business with our -
        <strong className="strong"> Restaurant manager app </strong>
      </strong>
      <div className="iframe-wrapper mb-5">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/MgbVA06C_NI"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        />
      </div>
      <strong className="introduction-subTitle">
        Build Your Business with our -
        <strong className="strong"> Delivery boy app</strong>
      </strong>
      <div className="iframe-wrapper mb-5">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/IHK-mF0P50Q"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        />
      </div>
      <h3 className="introduction-title">Overview</h3>
      <strong className="introduction-subTitle">
        Welcome to - <strong className="strong">Foodyman Single.</strong>
      </strong>

      <div>
        A single & multi-branch restaurant & grocery store food ordering and
        delivery platform. <br /> Multi-branch management Benefit from a
        powerful restaurant & grocery store food ordering that centralizes your
        multi-branch management activities
        <div className="line-break" />
        Foodyman consists of 2 Mobile Applications, 5 Web Panel, 1 website:
        <br />
        <div className="introduction-img-container">
          <SimpleReactLightbox>
            <SRLWrapper>
              <a
                href="/assets/img/doc/admin-banner.jpg"
                data-fancybox
                rel="nofollow"
              >
                <img
                  src="/assets/img/doc/admin-banner.jpg"
                  alt="admin"
                  loading="lazy"
                  className="img-responsive-full"
                />
              </a>
            </SRLWrapper>
          </SimpleReactLightbox>
          <SimpleReactLightbox>
            <SRLWrapper>
              <a
                href="./assets/img/project/single-banner.png"
                data-fancybox
                rel="nofollow"
              >
                <img
                  src="./assets/img/project/single-banner.png"
                  alt="web main"
                  loading="lazy"
                  className="img-responsive-full"
                />
              </a>
            </SRLWrapper>
          </SimpleReactLightbox>
        </div>
        <div className="line-break" />
        <h3 className="introduction-contentTitle">
          3 Apps developed using Flutter
        </h3>
        • Customer application <br />
        • Delivery Boy application (not included) <br />
        • Manager application (not included) <br />
        <h3 className="introduction-contentTitle">
          5 web panel developed using Laravel & React Js
        </h3>
        • Admin web panel
        <br />
        • Manager web panel <br />
        • Store web panel <br />
        • Moderator web panel <br />
        • Delivery boy web panel <br />
        <h3 className="introduction-contentTitle">
          1 web site developed using Typescript & Next Js
        </h3>
        • Customer web site <br />
        <div className="line-break" />
      </div>

      <NavigationBtns />
    </div>
  );
};

export default Introduction;
