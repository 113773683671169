// Goshops document
import GoshopsDoc from "../views/goshops-docs";
import GoshopsIntroduction from "../views/goshops-docs/Introduction";
import GoshopsBasic from "../views/goshops-docs/Basic";
import GoshopsServer from "../views/goshops-docs/Server";
import GoshopsMobileApp from "../views/goshops-docs/Mobile-app";
import GoshopsFlutterSDK from "../views/goshops-docs/Flutter-SDK";
import GoshopsLocalServer from "../views/goshops-docs/Local-server";
import GoshopsInstallOnServer from "../views/goshops-docs/Install-on-server";
import GoshopsMandatorySetup from "../views/goshops-docs/Mandatory-setup";
import GoshopsCustomization from "../views/goshops-docs/Customization";
import GoshopsMandatorySetupMobile from "../views/goshops-docs/Mandatory-setup-mobile";
import GoshopsCustomizationMobile from "../views/goshops-docs/Customization-mobile";
import GoshopsAppBuildRelease from "../views/goshops-docs/App-build-release";
import GoshopsMandatorySetupWeb from "../views/goshops-docs/Mandatory-setup-web";
import GoshopsBuildCodeAndSetupOnServer from "../views/goshops-docs/Build-code-and-setup-on-server";
import GoshopsUpdateAdminPanel from "../views/goshops-docs/Update-admin-panel";
import GoshopsUpdateAppWeb from "../views/goshops-docs/Update-app-web";
import GoshopsFront from "../views/goshops-docs/front";
import GoshopsLocalFront from "../views/goshops-docs/local-front";
import GoshopsSupportPlan from "../views/goshops-docs/supportPlan";
import Troubleshooting from "../views/goshops-docs/Troubleshooting";
import GoshopsFirebaseSetup from "../views/goshops-docs/firebase-setup";
import GoshopsMandatorySetupBackend from "../views/goshops-docs/Mandatory-setup-backend";
import GoshopsTroubleshootingBackend from "../views/goshops-docs/Troubleshooting-backend";
import GoshopsAdmin from "../views/goshops-docs/admin";
import GoshopsTroubleshootingAdmin from "../views/goshops-docs/Troubleshooting-admin";
import GoshopsMandatorySetupCustomer from "../views/goshops-docs/Moderator-setup-customer";
import GoshopsCustomizationMobileCustomer from "../views/goshops-docs/Customization-mobile-customer";
import GoshopsAppBuildReleaseCustomer from "../views/goshops-docs/App-build-release-customer";
import GoshopsMandatorySetupVendor from "../views/goshops-docs/Moderator-setup-vendor";
import GoshopsAppBuildReleaseVendor from "../views/goshops-docs/App-build-release-vendor";
import GoshopsMandatorySetupDeliveryboy from "../views/goshops-docs/Moderator-setup-deliveryboy";
import GoshopsCustomizationMobileDelivery from "../views/goshops-docs/Customization-mobile-delivery";
import GoshopsAppBuildReleaseDelivery from "../views/goshops-docs/App-build-release-deliveryboy";
import GoshopsMandatorySetupPos from "../views/goshops-docs/Moderator-setup-pos";
import GoshopsCustomizationMobilePos from "../views/goshops-docs/Customization-mobile-pos";
import GoshopsAppBuildReleasePos from "../views/goshops-docs/App-build-release-pos";
import UpdateGoshops from "../views/goshops-docs/update";
import ChangeStaticData from "../views/goshops-docs/change-static-data";
import GoshopsRecommendations from "../views/goshops-docs/recommendations";
import React from "react";
import { Route } from "react-router-dom";

const Goshops = () => {
  return (
    <Route path="/goshops-documentation" element={<GoshopsDoc />}>
      <Route path="/goshops-documentation" element={<GoshopsIntroduction />} />
      <Route
        path="/goshops-documentation/recommendations"
        element={<GoshopsRecommendations />}
      />
      <Route path="/goshops-documentation/basic" element={<GoshopsBasic />} />
      <Route path="/goshops-documentation/server" element={<GoshopsServer />} />
      <Route path="/goshops-documentation/front" element={<GoshopsFront />} />
      <Route
        path="/goshops-documentation/local-front"
        element={<GoshopsLocalFront />}
      />
      <Route
        path="/goshops-documentation/mobile-app"
        element={<GoshopsMobileApp />}
      />
      <Route
        path="/goshops-documentation/vendor-app"
        element={<GoshopsMobileApp />}
      />
      <Route
        path="/goshops-documentation/driver-app"
        element={<GoshopsMobileApp />}
      />
      <Route
        path="/goshops-documentation/flutter-sdk"
        element={<GoshopsFlutterSDK />}
      />
      <Route
        path="/goshops-documentation/local-server"
        element={<GoshopsLocalServer />}
      />
      <Route
        path="/goshops-documentation/install-on-server"
        element={<GoshopsInstallOnServer />}
      />
      <Route
        path="/goshops-documentation/mandatory-setup"
        element={<GoshopsMandatorySetup />}
      />
      <Route
        path="/goshops-documentation/customization"
        element={<GoshopsCustomization />}
      />
      <Route
        path="/goshops-documentation/mandatory-setup-mobile"
        element={<GoshopsMandatorySetupMobile />}
      />
      <Route
        path="/goshops-documentation/customization-mobile"
        element={<GoshopsCustomizationMobile />}
      />
      <Route
        path="/goshops-documentation/app-build-release"
        element={<GoshopsAppBuildRelease />}
      />
      <Route
        path="/goshops-documentation/mandatory-setup-web"
        element={<GoshopsMandatorySetupWeb />}
      />
      <Route
        path="/goshops-documentation/build-code-and-setup-on-server"
        element={<GoshopsBuildCodeAndSetupOnServer />}
      />
      <Route
        path="/goshops-documentation/update-admin-panel"
        element={<GoshopsUpdateAdminPanel />}
      />
      <Route
        path="/goshops-documentation/update-app-web"
        element={<GoshopsUpdateAppWeb />}
      />
      <Route
        path="/goshops-documentation/support-plan"
        element={<GoshopsSupportPlan />}
      />
      <Route
        path="/goshops-documentation/firebase"
        element={<GoshopsFirebaseSetup />}
      />
      <Route
        path="/goshops-documentation/troubleshooting"
        element={<Troubleshooting />}
      />
      <Route
        path="/goshops-documentation/mandatory-setup-backend"
        element={<GoshopsMandatorySetupBackend />}
      />
      <Route
        path="/goshops-documentation/troubleshooting-backend"
        element={<GoshopsTroubleshootingBackend />}
      />
      <Route path="/goshops-documentation/admin" element={<GoshopsAdmin />} />
      <Route
        path="/goshops-documentation/troubleshooting-admin"
        element={<GoshopsTroubleshootingAdmin />}
      />
      <Route
        path="/goshops-documentation/mandatory-setup-customer"
        element={<GoshopsMandatorySetupCustomer />}
      />
      <Route
        path="/goshops-documentation/customization-customer"
        element={<GoshopsCustomizationMobileCustomer />}
      />
      <Route
        path="/goshops-documentation/customer-app-build-release"
        element={<GoshopsAppBuildReleaseCustomer />}
      />
      <Route
        path="/goshops-documentation/mandatory-setup-vendor"
        element={<GoshopsMandatorySetupVendor />}
      />
      <Route
        path="/goshops-documentation/customization-vendor"
        element={<GoshopsCustomizationMobile />}
      />
      <Route
        path="/goshops-documentation/vendor-app-build-release"
        element={<GoshopsAppBuildReleaseVendor />}
      />
      <Route
        path="/goshops-documentation/mandatory-setup-deliveryboy"
        element={<GoshopsMandatorySetupDeliveryboy />}
      />
      <Route
        path="/goshops-documentation/customization-deliveryboy"
        element={<GoshopsCustomizationMobileDelivery />}
      />
      <Route
        path="/goshops-documentation/deliveryboy-app-build-release"
        element={<GoshopsAppBuildReleaseDelivery />}
      />
      <Route
        path="/goshops-documentation/pos-app"
        element={<GoshopsMobileApp />}
      />
      <Route
        path="/goshops-documentation/mandatory-setup-pos"
        element={<GoshopsMandatorySetupPos />}
      />
      <Route
        path="/goshops-documentation/customization-pos"
        element={<GoshopsCustomizationMobilePos />}
      />
      <Route
        path="/goshops-documentation/pos-app-build-release"
        element={<GoshopsAppBuildReleasePos />}
      />
      <Route path="/goshops-documentation/update" element={<UpdateGoshops />} />
      <Route
        path="/goshops-documentation/change-static-data"
        element={<ChangeStaticData />}
      />
    </Route>
  );
};

export default Goshops;
