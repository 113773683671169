import React from "react";
import { Helmet } from "react-helmet";
import CopyRight from "../../../components/Footer/Copyright/CopyRight";
import HeaderDark from "../../../components/Header/HeaderDark";
import FooterThreeDark from "../../../components/Footer/FooterThreeDark";
import OnePage from "../../../components/Hero/OnePage";
import { FaUserCog } from "react-icons/fa";
import { BiRadioCircle } from "react-icons/bi";
import { TbTruckDelivery } from "react-icons/tb";
import { AiFillApple, AiOutlineGlobal, AiOutlineHistory } from "react-icons/ai";
import { IoDocumentText } from "react-icons/io5";
import { TiVendorAndroid } from "react-icons/ti";
import { features } from "../../../fake-data/Foodyman-feature";
import KeyFeatures from "../../../components/KeyFeatures";
import PaymentList from "../../../components/Payment";

const skillContent = [
  {
    icon: <FaUserCog />,
    link: "https://admin-qr.foodyman.org/login",
    title: "Admin panel",
    delayAnimation: "100",
    target: "_blank",
  },
  {
    icon: <TbTruckDelivery />,
    link: "https://testflight.apple.com/join/NnIIbHBx",
    title: "Waiter app TestFligh",
    delayAnimation: "200",
    target: "_blank",
  },
  {
    icon: <TbTruckDelivery />,
    link: "https://drive.google.com/file/d/1Z190ezVMawWgpcR1a8t-vd73c7wXX7h6/view?usp=sharing",
    title: "Waiter app Google play",
    delayAnimation: "300",
    target: "_blank",
  },
  {
    icon: <TiVendorAndroid />,
    link: "https://drive.google.com/file/d/1YN_-4z4nPPMT5oKw-Rs189doC1B3gW8h/view?usp=sharing",
    title: "Manager table and Kitchet POS",
    delayAnimation: "300",
    target: "_blank",
  },
  {
    icon: <AiFillApple />,
    link: "https://testflight.apple.com/join/ISsNaxPN",
    title: "Manager table and Kitchet POS",
    delayAnimation: "300",
    target: "_blank",
  },
  {
    icon: <AiOutlineGlobal />,
    link: "https://qr-single.foodyman.org/",
    title: "Website",
    delayAnimation: "400",
    target: "_blank",
  },
  {
    icon: <IoDocumentText />,
    link: "/qrcode-documentation/introduction",
    title: "Documentation",
    delayAnimation: "600",
    target: "_blank",
  },
  {
    icon: <AiOutlineHistory />,
    link: "/qrcode-documentation/update",
    title: "Update",
    delayAnimation: "600",
    target: "_blank",
  },
];

export default function FoodymanMulti() {
  return (
    <div className="ptf-site-wrapper animsition ptf-is--work-showcase-1">
      <Helmet>
        <title>
          Githubit - A table QR-code based contactless ordering and order
          management system
        </title>
      </Helmet>

      <HeaderDark />
      <div className="ptf-site-wrapper__inner">
        <div className="main">
          <article className="ptf-page ptf-page--single-work-1">
            <section>
              <OnePage
                skillContent={skillContent}
                buy_link={
                  "https://codecanyon.net/item/a-table-qrcode-based-contactless-ordering-and-order-management-system-admin-pos-kitchen-waiter/46383370"
                }
                descriptions="A table QR-code based contactless ordering and order management system (Admin, POS, Kitchen, Waiter)"
                subTitle="A table QR-code based contactless ordering and order management system"
                title="Qrcode"
                img_url="qrcode1.jpg"
              />
            </section>

            <section>
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "10rem", "--ptf-md": "3.125rem" }}
              />
              <PaymentList />

              <div className="container-xxl">
                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <p className="fz-20 has-black-color">
                    Qrcode - A table QR-code based contactless ordering and
                    order management system
                  </p>
                  <p className="fz-20 has-black-color">
                    <strong>All-in-one:</strong> <br />
                    <BiRadioCircle size={10} /> Website; (
                    <a href="https://qr-single.foodyman.org">
                      https://qr-single.foodyman.org
                    </a>{" "}
                    )
                    <br />
                    <BiRadioCircle size={10} /> Mobile Waiter apps (Android and
                    iOS); <br />
                    <BiRadioCircle size={10} /> Admin Panel (web); <br />
                    <BiRadioCircle size={10} /> Waiter Panel (web). <br />
                  </p>
                  <p className="fz-20 has-black-color">
                    <strong>Multi-vendor options</strong>
                  </p>
                  <p className="fz-20 has-black-color">
                    <strong>A growing list of features:</strong> <br />
                    <BiRadioCircle size={10} /> Top-notch UI design; <br />
                  </p>
                  <p className="fz-20 has-black-color">
                    <strong>Versatile: </strong> <br />
                    designed with a range of department stores in mind (Food and
                    Grocery.)
                  </p>
                  <p className="fz-20 has-black-color">
                    <strong className="fas fa-band-aid">
                      Core technologies:
                    </strong>
                    <br />
                    <BiRadioCircle size={10} /> Laravel (backend) <br />
                    <BiRadioCircle size={10} /> Flutter (mobile apps)
                    <br />
                    <BiRadioCircle size={10} /> Reactjs (admin panels)
                    <br />
                    <BiRadioCircle size={10} /> Vite (website)
                    <br />
                    <BiRadioCircle size={10} /> Rest API
                  </p>
                </div>

                <div className="mt-4" />

                <div
                  className="ptf-divider"
                  style={{
                    "--ptf-height": "1px",
                    "--ptf-color": "var(--ptf-color-14)",
                  }}
                />
              </div>
            </section>
          </article>
        </div>
      </div>

      <footer className="ptf-footer ptf-footer--style-5">
        <div className="container-xxl">
          <div
            className="ptf-divider"
            style={{
              "--ptf-height": "1px",
              "--ptf-color": "var(--border-color)",
            }}
          />
          <div className="ptf-footer__top">
            <FooterThreeDark />
          </div>

          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
  );
}
