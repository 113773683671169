import React from "react";
import { GiCampfire } from "react-icons/gi";
import { Link } from "react-router-dom";

const UpdateAdminPanel = () => {
  return (
    <div
      className="introduction "
      data-aos="fade-right"
      data-aos-delay="300"
      data-aos-duration="1000"
    >
      <h1 className="title">Admin Panel</h1>
      <h3 className="introduction-title">Update</h3>
      For update the admin panel just upload admin_front and admin_backend
      folders in your project root folder and extract it. Build project.
      <div className="alert alert-danger mt-4" role="alert">
        Keep config/init.php and config/credential.php while updating
      </div>
      <div className="mt-4" />
      <div className="iframe-wrapper">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/I6bhGMcVR5E"
          title="How to update project"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
      <div className="alert alert-danger mt-4" role="alert">
        <div className="mb-2">
          <GiCampfire size={22} /> <strong>WARNING</strong>
        </div>
        You have to be a developer in this case. If something goes wrong, we
        won't be responsible for that.
      </div>
      <div className="center-page-container">
        <Link to="/uzmart-single-documentation/firebase" className="btn  previous">
          <p>Previous</p>
          <p className="link"> Firebase </p>
        </Link>
        <Link to="/uzmart-single-documentation/update-app-web" className="btn  next">
          <p>Next</p>
          <p className="link"> App & Web </p>
        </Link>
      </div>
    </div>
  );
};

export default UpdateAdminPanel;
