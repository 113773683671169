import React from "react";
import { Route } from "react-router-dom";
import AppBuildReleaseCustomer from "../views/Foodyman-doc/App-build-release-customer";
import UpdateFooyman from "../views/Foodyman-doc/update";
import GstoreDoc from "../views/g-store";
import GstoreMobileApp from "../views/g-store/Mobile-app";
import CustomizationGstore from "../views/g-store/Customization-mobile-customer";
import GstoreMandatorySetupCustomer from "../views/g-store/Moderator-setup-customer";
import FoodyFirebaseSetup from "../views/Foodyman-doc/firebase-setup";

const Gstore = () => {
  return (
      <Route path="/gstore-documentation" element={<GstoreDoc />}>
          <Route
              index
              path="/gstore-documentation"
              element={<GstoreMobileApp />}
          />
          <Route
              index
              path="/gstore-documentation/mobile-app"
              element={<GstoreMobileApp />}
          />
          <Route
              path="/gstore-documentation/customization-customer"
              element={<CustomizationGstore />}
          />
          <Route
              path="/gstore-documentation/mandatory-setup-customer"
              element={<GstoreMandatorySetupCustomer />}
          />
          <Route
              path="/gstore-documentation/customer-app-build-release"
              element={<AppBuildReleaseCustomer />}
          />
          <Route
              path="/gstore-documentation/firebase"
              element={<FoodyFirebaseSetup />}
          />
          <Route
              path="/gstore-documentation/update"
              element={<UpdateFooyman />}
          />
      </Route>
  );
};

export default Gstore;
