import { IoLanguageSharp } from "react-icons/io5";
import {
  MdOutlineAnalytics,
  MdOutlineCollections,
  MdOutlineComputer,
  MdOutlineReviews,
} from "react-icons/md";
import { HiOutlinePuzzle, HiOutlineReceiptRefund } from "react-icons/hi";
import { FaRegMap } from "react-icons/fa";

export const features = [
  {
    tag: "Language",
    title: "Multiple Language & RTL",
    subTitle: "Active RTL. You can easily change your preferred language",
    icon: <IoLanguageSharp />,
    color: "#F0EAF6",
    bgColor: "#E2626B",
    delayAnimation: "100",
  },
  {
    tag: "Delivery Zone",
    title: "Delivery zone",
    subTitle: "Create a zone by clicking on the map",
    icon: <FaRegMap />,
    color: "#fff7e6",
    bgColor: "#FAB758",
    delayAnimation: "300",
  },
  {
    tag: "POS Systems",
    title: "Orders",
    subTitle: "Create orders",
    icon: <MdOutlineComputer />,
    color: "#e6fff2",
    bgColor: "#45C4A0",
    delayAnimation: "200",
  },
  {
    tag: "Refunds",
    title: "Order refunds",
    subTitle: "Return your created order",
    icon: <HiOutlineReceiptRefund />,
    color: "#E0E9FA",
    bgColor: "#747ED1",
    delayAnimation: "400",
  },
  {
    tag: "Addon",
    title: "Product addons",
    subTitle: "You can add extras to your products",
    icon: <HiOutlinePuzzle />,
    color: "#f2e6ff",
    bgColor: "#4d0099",
    delayAnimation: "500",
  },
  {
    tag: "Reviews",
    title: "Reviews",
    subTitle: "You can add extras to your products",
    icon: <MdOutlineReviews />,
    color: "#e6e6ff",
    bgColor: "#000099",
    delayAnimation: "600",
  },
  {
    tag: "LookBooks",
    title: "LookBooks",
    subTitle:
      "Unlike product catalogs, this is a presentation of the fashion brand's originality for the new season",
    icon: <MdOutlineCollections />,
    color: "#ccffff",
    bgColor: "#008080",
    delayAnimation: "700",
  },
  {
    tag: "Analytics",
    title: "Analytics",
    subTitle: "View the values and amounts of your orders and products live",
    icon: <MdOutlineAnalytics />,
    color: "#ccf2ff",
    bgColor: "#0099cc",
    delayAnimation: "700",
  },
];
