import React from "react";
import {payments} from "../fake-data/Uzmart-doc-menu";

const PaymentList = ({data}) => {
  return (
    <div className="container-xxl">
      <div
        className="ptf-animated-block text-center"
        data-aos="fade"
        data-aos-delay="0"
      >
        <h5 style={{ color: "chocolate" }}>INTEGRATED PAYMENT GATEWAYS</h5>

        <div className="row mb-5 mt-5">
          {(data ? data : payments).map((item, idx) => (
            <div
              key={idx}
              className="col-lg-3 col-md-3 col-sm-3 col-6 mb-4"
              data-aos="fade"
              data-aos-delay={item.delayAnimation}
            >
              <span className="has-gray-color">{item.icon}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PaymentList;
