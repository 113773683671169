import React from "react";
import { Link } from "react-router-dom";

const FlutterSdk = () => {
  return (
    <div
      className="introduction "
      data-aos="fade-right"
      data-aos-delay="300"
      data-aos-duration="1000"
    >
      <h1 className="title">Mobile app</h1>
      <p className="inner-text">
        Please download and setup flutter from flutter.dev. You can find
        documentation for your own device via the following links.
      </p>
      Windows:{" "}
      <a
        href="https://docs.flutter.dev/get-started/install/windows"
        className="introduction-link"
      >
        https://docs.flutter.dev/get-started/install/windows
      </a>
      <br />
      Mac:{" "}
      <a
        href="https://docs.flutter.dev/get-started/install/macos"
        className="introduction-link"
      >
        https://docs.flutter.dev/get-started/install/macos
      </a>
      <br />
      Linux:{" "}
      <a
        href="https://docs.flutter.dev/get-started/install/linux"
        className="introduction-link"
      >
        https://docs.flutter.dev/get-started/install/linux
      </a>
      <br />
      <div className="center-page-container">
        <Link to="/foodyman-documentation/mobile-app" className="btn  previous">
          <p>Previous</p>
          <p className="link"> Mobile App </p>
        </Link>
        <Link to="/foodyman-documentation/local-front" className="btn  next">
          <p>Next</p>
          <p className="link"> Frontend Local </p>
        </Link>
      </div>
    </div>
  );
};

export default FlutterSdk;
