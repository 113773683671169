import React, { useState } from "react";
import { GiCampfire } from "react-icons/gi";
import { Link } from "react-router-dom";

const UpdateAppWeb = () => {
  const [text, setText] = useState(null);

  const copyToClipBoard = async (copyMe, id) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setText(id);
    } catch (err) {
      setText("Failed to copy!");
    }
  };
  return (
    <div
      className="introduction "
      data-aos="fade-right"
      data-aos-delay="300"
      data-aos-duration="1000"
    >
      <h1 className="title">App & Web</h1>
      <h3 className="introduction-title">Update mobile App</h3>
      Download source code and replace it with old one and build.
      <h3 className="introduction-title">Update mobile Web</h3>
      Upload front files to server and do following steps:
      <br />
      • Install required package <br />
      <div className="introduction-code">
        <div>
          <span className="ps-0">yarn</span>
          <span
            className={text === 3 ? "bg-success copy" : "copy"}
            onClick={() => copyToClipBoard("yarn", 3)}
          >
            {text === 3 ? "copied!" : "copy"}
          </span>
        </div>
      </div>
      <p className="mt-2">or</p>
      <div className="introduction-code">
        <div>
          <span className="ps-0">npm install</span>
          <span
            className={text === 4 ? "bg-success copy" : "copy"}
            onClick={() => copyToClipBoard("npm install", 4)}
          >
            {text === 4 ? "copied!" : "copy"}
          </span>
        </div>
      </div>
      <br />
      • Build frontend using following command <br />
      <div className="introduction-code">
        <div>
          <span className="ps-0">yarn next build</span>
          <span
            className={text === 5 ? "bg-success copy" : "copy"}
            onClick={() => copyToClipBoard("yarn build", 5)}
          >
            {text === 5 ? "copied!" : "copy"}
          </span>
        </div>
      </div>
      <p className="mt-2">or</p>
      <div className="introduction-code">
        <div>
          <span className="ps-0">npm next build</span>
          <span
            className={text === 6 ? "bg-success copy" : "copy"}
            onClick={() => copyToClipBoard("npm run build", 6)}
          >
            {text === 6 ? "copied!" : "copy"}
          </span>
        </div>
      </div>
      <br />
      • Restart project with pm2 <br />
      <div className="introduction-code">
        <div>
          <span className="ps-0">pm2 stop 0 & pm2 start 0</span>
          <span
            className={text === 7 ? "bg-success copy" : "copy"}
            onClick={() => copyToClipBoard("yarn build", 7)}
          >
            {text === 7 ? "copied!" : "copy"}
          </span>
        </div>
      </div>
      <div className="mt-4" />
      <div className="iframe-wrapper">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/I6bhGMcVR5E"
          title="How to update project"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
      <div className="alert alert-danger mt-4" role="alert">
        <div className="mb-2">
          <GiCampfire size={22} /> <strong>WARNING</strong>
        </div>
        You have to be a developer in this case. If something goes wrong, we
        won't be responsible for that.
      </div>
      <div className="center-page-container">
        <Link
          to="/uzmart-single-documentation/update-admin-panel"
          className="btn  previous"
        >
          <p>Previous</p>
          <p className="link"> Admin Panel </p>
        </Link>
        <Link to="/uzmart-single-documentation/support-plan" className="btn  next">
          <p>Next</p>
          <p className="link"> Support plan </p>
        </Link>
      </div>
    </div>
  );
};

export default UpdateAppWeb;
