export const saasDocMenu = [
  {
    name: "Introduction",
    dropDownItems: [
      {
        name: "Introduction",
        routerPath: "/saas-documentation/introduction",
      },
      {
        name: "Server requirements",
        routerPath: "/saas-documentation/recommendations",
      },
    ],
  },
  {
    name: "Backend Api",
    dropDownItems: [
      {
        name: "Requirements",
        routerPath: "/saas-documentation/server",
      },
      {
        name: "Installation",
        routerPath: "/saas-documentation/mandatory-setup-backend",
      },
      {
        name: "Troubleshooting",
        routerPath: "/saas-documentation/troubleshooting-backend",
      },
    ],
  },
  {
    name: "Admin Panel",
    dropDownItems: [
      {
        name: "Requirements",
        routerPath: "/saas-documentation/admin",
      },
      {
        name: "Installation",
        routerPath: "/saas-documentation/install-on-server",
      },
      {
        name: "Troubleshooting",
        routerPath: "/saas-documentation/troubleshooting-admin",
      },
    ],
  },
  {
    name: "Customer Website",
    dropDownItems: [
      {
        name: "Requirements",
        routerPath: "/saas-documentation/front",
      },
      {
        name: "Installation",
        routerPath: "/saas-documentation/mandatory-setup-web",
      },
    ],
  },
  {
    name: "Landing Page",
    dropDownItems: [
      {
        name: "Requirements",
        routerPath: "/saas-documentation/landing",
      },
      {
        name: "Installation",
        routerPath: "/saas-documentation/mandatory-setup-landing",
      },
    ],
  },
  {
    name: "Waiter App",
    dropDownItems: [
      {
        name: "Requirements",
        routerPath: "/saas-documentation/waiter-app",
      },
      {
        name: "Installation",
        routerPath: "/saas-documentation/mandatory-setup-waiter",
      },
      {
        name: "Customization",
        routerPath: "/saas-documentation/customization-waiter",
      },
      {
        name: "App build & release",
        routerPath: "/saas-documentation/waiter-app-build-release",
      },
    ],
  },
  {
    name: "Pos App",
    dropDownItems: [
      {
        name: "Requirements",
        routerPath: "/saas-documentation/pos-app",
      },
      {
        name: "Installation",
        routerPath: "/saas-documentation/mandatory-setup-pos",
      },
      {
        name: "Customization",
        routerPath: "/saas-documentation/customization-pos",
      },
      {
        name: "App build & release",
        routerPath: "/saas-documentation/pos-app-build-release",
      },
    ],
  },
  {
    name: "Firebase setup",
    routerPath: "/saas-documentation/firebase",
  },
  {
    name: "Update",
    routerPath: "/saas-documentation/update",
  },
];
