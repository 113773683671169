import React, { useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

const BuildCodeAndSetupOnServerBackend = () => {
  const [text, setText] = useState(null);

  const copyToClipBoard = async (copyMe, id) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setText(id);
    } catch (err) {
      setText("Failed to copy!");
    }
  };
  return (
    <div
      className="introduction "
      data-aos="fade-right"
      data-aos-delay="300"
      data-aos-duration="1000"
    >
      <h1 className="title">Build code and setup on server</h1>
      <h3 className="introduction-contentTitle"> Admin Panel front </h3>
      • Open /next.config.js and change BASE_URL with your server url
      <br />
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a href="/assets/img/doc/web-1.jpg" data-fancybox rel="nofollow">
              <img
                src="/assets/img/doc/sundaymartWebSettings.png"
                alt="images"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      For building web data for deployment, you have to run commands:
      <br />
      • Install required package <br />
      <div className="introduction-code">
        <div>
          <span className="ps-0">yarn</span>
          <span
            className={text === 3 ? "bg-success copy" : "copy"}
            onClick={() => copyToClipBoard("yarn", 3)}
          >
            {text === 3 ? "copied!" : "copy"}
          </span>
        </div>
      </div>
      <p className="mt-2">or</p>
      <div className="introduction-code">
        <div>
          <span className="ps-0">npm install</span>
          <span
            className={text === 4 ? "bg-success copy" : "copy"}
            onClick={() => copyToClipBoard("npm install", 4)}
          >
            {text === 4 ? "copied!" : "copy"}
          </span>
        </div>
      </div>
      <br />
      • Build frontend using following command <br />
      <div className="introduction-code">
        <div>
          <span className="ps-0">yarn build</span>
          <span
            className={text === 5 ? "bg-success copy" : "copy"}
            onClick={() => copyToClipBoard("yarn build", 5)}
          >
            {text === 5 ? "copied!" : "copy"}
          </span>
        </div>
      </div>
      <p className="mt-2">or</p>
      <div className="introduction-code">
        <div>
          <span className="ps-0">npm run build</span>
          <span
            className={text === 6 ? "bg-success copy" : "copy"}
            onClick={() => copyToClipBoard("npm run build", 6)}
          >
            {text === 6 ? "copied!" : "copy"}
          </span>
        </div>
      </div>
      <br />
      • Run project with pm2 <br />
      <div className="introduction-code">
        <div>
          <span className="ps-0">pm2 start "npm run start"</span>
          <span
            className={text === 6 ? "bg-success copy" : "copy"}
            onClick={() => copyToClipBoard("npm run start", 6)}
          >
            {text === 6 ? "copied!" : "copy"}
          </span>
        </div>
      </div>
      <div className="alert alert-primary mt-3" role="alert">
        <div className="mb-2">
          <AiOutlineInfoCircle size={22} /> <strong>INFO</strong>
        </div>
        You have to configure your server for front website. Front website runs
        in port 3000. open server configuration file and add{" "}
        <div className="introduction-code">
          <div>
            <span className="ps-0">
              ProxyPass / http://localhost:3000 <br /> ProxyPassReverse /
              http://localhost:3000
            </span>
            <span
              className={text === 6 ? "bg-success copy" : "copy"}
              onClick={() =>
                copyToClipBoard(
                  "ProxyPass / http://localhost:3000  ProxyPassReverse /http://localhost:3000",
                  7
                )
              }
            >
              {text === 7 ? "copied!" : "copy"}
            </span>
          </div>
        </div>{" "}
        in your domain configuration section. Rewrite mode should be enabled in
        your server. After adding, restart your server
      </div>
      <div className="center-page-container">
        <Link
          to="/saas-documentation/build-code-and-setup-on-server"
          className="btn  previous"
        >
          <p>Previous</p>
          <p className="link"> Build code and setup on server </p>
        </Link>
        <Link
          to="/saas-documentation/mandatory-setup-customer"
          className="btn  next"
        >
          <p>Next</p>
          <p className="link"> Mandatory setup customer </p>
        </Link>
      </div>
    </div>
  );
};

export default BuildCodeAndSetupOnServerBackend;
