import React from "react";
import { BsFillBagCheckFill } from "react-icons/bs";

const CareerMenu = () => {
  return (
    <div className="vakansiya" id="myTab" role="tablist">
      <div
        className="card active"
        id="home-tab"
        data-bs-toggle="tab"
        data-bs-target="#frontend"
        role="tab"
        aria-controls="frontend"
        aria-selected="true"
      >
        <a href="#frontend">
          <div className="card-header">
            <div className="d-flex">
              <h4 className="card-header-title">Frontend Developer</h4>
            </div>
            <div className="card-header-place">Tashkent Uzbekistan</div>
          </div>
          <div className="card-status">
            <span className="status">
              <BsFillBagCheckFill /> Full-time
            </span>
          </div>
          <div className="card-body">
            <p>
              • 2 years developer experience
              <br />
              • Experience with HTML, CSS, and Javascript
              <br />
              • Experience working on customer facing projects capturing
              requirements and troubleshooting defects.
              <br />
            </p>
          </div>
        </a>
      </div>

      <div
        className="card"
        id="home-tab"
        data-bs-toggle="tab"
        data-bs-target="#backend"
        role="tab"
        aria-controls="backend"
        aria-selected="true"
      >
        <a href="#backend">
          <div className="card-header">
            <div className="d-flex">
              <h4 className="card-header-title">Backend Developer</h4>
              <span className="header-status">new</span>
            </div>
            <div className="card-header-place">Tashkent Uzbekistan</div>
          </div>
          <div className="card-status">
            <span className="status">
              <BsFillBagCheckFill /> Full-time
            </span>
          </div>
          <div className="card-body">
            <p>
              • PHP – The platform consists of a backend and frontend that are
              both written in PHP; the frontend utilizes Bootstrap/JQuery
              framework. <br />
              • MySQL/Aurora RDS – Data is persisted in a relational database
              provided by the MySQL flavor of Aurora RDS on AWS.
              <br />
              • AWS – The platform makes use of a number of AWS technologies:
              EC2, Aurora RDS, Cloud Watch, SES, and S3.
              <br />
            </p>
          </div>
        </a>
      </div>

      <div
        className="card"
        id="home-tab"
        data-bs-toggle="tab"
        data-bs-target="#flutter"
        role="tab"
        aria-controls="flutter"
        aria-selected="true"
      >
        <a href="#flutter">
          <div className="card-header">
            <h4 className="card-header-title">Flutter Developer</h4>
            <div className="card-header-place">Tashkent Uzbekistan</div>
          </div>
          <div className="card-status">
            <span className="status">
              <BsFillBagCheckFill />
              Full-time
            </span>
            <span className="status">
              <BsFillBagCheckFill />
              Part-time
            </span>
          </div>
          <div className="card-body">
            <p>
              • Developing mobile apps for cross-platform mobile applications
              <br />
              • Actively participate in the full development lifecycle alongside
              teammates
              <br />
              • Fixing bugs
              <br />
              • Work as part of a cross-functional team through the full
              lifecycle development, which includes UI/UX designers, project
              managers, back-end developers, and other specialists as needed
              <br />
              • Following new trends in development
              <br />
            </p>
          </div>
        </a>
      </div>
    </div>
  );
};

export default CareerMenu;
