import React from "react";
import { Link } from "react-router-dom";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

const TroubleshootingBackend = () => {
  return (
    <div
      className="introduction "
      data-aos="fade-right"
      data-aos-delay="300"
      data-aos-duration="1000"
    >
      <h1 className="title">Troubleshooting backend</h1>

      <div class="accordion" id="accordionExample">
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingOne">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              Troubleshooting 1
            </button>
          </h2>
          <div
            id="collapseOne"
            class="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <p>
                if you meet your serialized closure might have been modified or
                it's unsafe to be unserialized error you should use this code
              </p>
              <div className="introduction-img-container mb-5">
                <SimpleReactLightbox>
                  <SRLWrapper>
                    <a
                      href="/assets/img/food-doc/trouble.jpg"
                      data-fancybox
                      rel="nofollow"
                    >
                      <img
                        src="/assets/img/food-doc/trouble.jpg"
                        alt="admin install"
                        loading="lazy"
                        className="img-responsive-full"
                      />
                    </a>
                  </SRLWrapper>
                </SimpleReactLightbox>
              </div>
              <div className="introduction-code">
                <p className="mb-0">php artisan storage:link</p>
              </div>
              <div className="introduction-code">
                <p>php artisan optimize:clear</p>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="true"
              aria-controls="collapseTwo"
            >
              Troubleshooting 2
            </button>
          </h2>
          <div
            id="collapseTwo"
            class="accordion-collapse collapse show"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample1"
          >
            <div class="accordion-body">
              <p>
                If you meet this error, check db credentials entered correctly
                in .env file. If everything is correct, run
              </p>
              <div className="introduction-img-container mb-5">
                <SimpleReactLightbox>
                  <SRLWrapper>
                    <a
                      href="/assets/img/food-doc/laravel-migrate.png"
                      data-fancybox
                      rel="nofollow"
                    >
                      <img
                        src="/assets/img/food-doc/laravel-migrate.png"
                        alt="admin install"
                        loading="lazy"
                        className="img-responsive-full"
                      />
                    </a>
                  </SRLWrapper>
                </SimpleReactLightbox>
              </div>
              <div className="introduction-code">
                <p className="mb-0">php artisan optimize:clear</p>
              </div>
              <div className="introduction-code">
                <p className="mb-0">php artisan migrate</p>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingThree">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="true"
              aria-controls="collapseThree"
            >
              Troubleshooting 3
            </button>
          </h2>
          <div
            id="collapseThree"
            class="accordion-collapse collapse show"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionExample3"
          >
            <div class="accordion-body">
              <p>
                If you meet this error while installing, check following
                extentions installed and enabled in your system:
                <ul>
                  <li>openssl</li>
                  <li>fileinfo</li>
                  <li>gd</li>
                  <li>curl</li>
                  <li>sodium</li>
                  <li>zip</li>
                </ul>
              </p>
              <div className="introduction-img-container mb-5">
                <SimpleReactLightbox>
                  <SRLWrapper>
                    <a
                      href="/assets/img/food-doc/trsh-3.png"
                      data-fancybox
                      rel="nofollow"
                    >
                      <img
                        src="/assets/img/food-doc/trsh-3.png"
                        alt="admin install"
                        loading="lazy"
                        className="img-responsive-full"
                      />
                    </a>
                  </SRLWrapper>
                </SimpleReactLightbox>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingFive">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFive"
              aria-expanded="true"
              aria-controls="collapseFive"
            >
              Troubleshooting 4
            </button>
          </h2>
          <div
            id="collapseFive"
            class="accordion-collapse collapse show"
            aria-labelledby="headingFive"
            data-bs-parent="#accordionExample4"
          >
            <div class="accordion-body">
              <h6 className="fw-normal">
                If you meet this error, please check /config/credentials.php
                file. Are your lisence keys in this file ? If not, please, add
                your lisence keys to this file. Then run “php artisan
                optimize:clear”
              </h6>{" "}
              <br /> <br />
              <div className="introduction-img-container mb-5">
                <SimpleReactLightbox>
                  <SRLWrapper>
                    <a
                      href="/assets/img/project/troubleshooting6.jpeg"
                      data-fancybox
                      rel="nofollow"
                    >
                      <img
                        src="/assets/img/project/troubleshooting6.jpeg"
                        alt="admin install"
                        loading="lazy"
                        className="img-responsive-full"
                      />
                    </a>
                  </SRLWrapper>
                </SimpleReactLightbox>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button
              class="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="true"
              aria-controls="collapseTwo"
            >
              Troubleshooting 5
            </button>
          </h2>
          <div
            id="collapseTwo"
            class="accordion-collapse collapse show"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample1"
          >
            <div class="accordion-body">
              <p>
                "message": "SQLSTATE[42000]: Syntax error or access violation:
                1055 'elbarrio_foody_test_bd.u.firstname' isn't in GROUP BY
                (SQL: select u.id as id, u.firstname as firstname, u.img as img,
                u.lastname as lastname, u.phone as phone, sum(distinct
                orders.total_price) as total_price, count(distinct orders.id) as
                count from orders cross join users as u on orders.user_id = u.id
                where date(`orders`.`created_at`) {">"} 2023-02-24 and
                orders.status = delivered group by id having total_price {">"} 0
                or count {">"} 0 order by total_price desc limit 6 offset 0) in
                /home2/elbarrio/api.lavilla.pe/vendor/laravel/framework/src/Illuminate/Database/Connection.php:712"{" "}
                {"}"}
              </p>
              <div className="introduction-img-container mb-5">
                <SimpleReactLightbox>
                  <SRLWrapper>
                    <a
                      href="/assets/img/food-doc/troubleshooting-2.jpg"
                      data-fancybox
                      rel="nofollow"
                    >
                      <img
                        src="/assets/img/food-doc/troubleshooting-2.jpg"
                        alt="admin install"
                        loading="lazy"
                        className="img-responsive-full"
                      />
                    </a>
                  </SRLWrapper>
                </SimpleReactLightbox>
              </div>
              <div className="introduction-code">
                <div>
                  <span>Open configs/database.php and make "strict" false</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="center-page-container">
        <Link
          to="/qrcode-documentation/mandatory-setup-backend"
          className="btn  previous"
        >
          <p>Previous</p>
          <p className="link"> Mandatory setup backend </p>
        </Link>
        <Link
          to="/qrcode-documentation/install-on-server"
          className="btn  next"
        >
          <p>Next</p>
          <p className="link"> Install on server </p>
        </Link>
      </div>
    </div>
  );
};

export default TroubleshootingBackend;
