import React from "react";
import { GiCampfire } from "react-icons/gi";
import { Link } from "react-router-dom";

const Server = () => {
  return (
    <div
      className="introduction "
      data-aos="fade-right"
      data-aos-delay="300"
      data-aos-duration="1000"
    >
      <h1 className="title">Backend requirements</h1>
      <ul>
        <li>PHP v8.0+</li>
      </ul>
      <ul>
        <li>
          Following extension have to be installed and enabled in your php:
          <ul>
            <li>openssl</li>
            <li>fileinfo</li>
            <li>gd</li>
            <li>curl</li>
            <li>sodium</li>
            <li>zip</li>
          </ul>
        </li>
      </ul>
      <br />
      • MySQL 5.7+ <br />
      {/*<p className="mt-3">In most servers these extensions are enabled by default, but you should check with your*/}
      {/*    hosting provider.</p>*/}
      <h3 className="introduction-contentTitle">Tools</h3>
      • For Backend development: Open Server Panel , XAMPP , Wamp Server ,
      PhpStorm <br />
      <h3 className="introduction-contentTitle">Knowledge</h3>
      • For Backend development: PHP , MySQL , Laravel <br />
      <div className="alert alert-danger mt-4" role="alert">
        <div className="mb-2">
          <GiCampfire size={22} /> <strong>WARNING</strong>
        </div>
        We would like to notify that the Envato price doesn’t include any kind
        of installation and app publishing support. We kindly ask you to follow
        the documentation step by step for installation, setup and other
        branding related changes. Please note that, we bear no responsibility
        for your mistake. You are fully in charge for any kind of customizations
        made by your own.
      </div>
      <div className="mt-4" />
      <div className="alert alert-danger mt-4" role="alert">
        <div className="mb-2">
          <GiCampfire size={22} /> <strong>WARNING</strong>
        </div>
        Please do this very carefully. We bear no responsibility for your
        mistake.
      </div>
      <div className="center-page-container">
        <Link to="/qrcode-documentation/basic" className="btn  previous">
          <p>Previous</p>
          <p className="link"> Basic </p>
        </Link>
        <Link to="/qrcode-documentation/front" className="btn  next">
          <p>Next</p>
          <p className="link"> Frontend </p>
        </Link>
      </div>
    </div>
  );
};

export default Server;
