import React from "react";
import { GiCampfire } from "react-icons/gi";
import { Link } from "react-router-dom";

const Recommendations = () => {
  return (
    <div
      className="introduction "
      data-aos="fade-right"
      data-aos-delay="300"
      data-aos-duration="1000"
    >
      <h1 className="title">Recommendations</h1>
      • Operating system: Ubuntu 22.04 64bit <br />
      • Cores: 4 <br />
      • RAM: 4GB <br />
      <div className="mt-4" />
      <div className="mt-4" />
      <div className="alert alert-danger mt-4" role="alert">
        <div className="mb-2">
          <GiCampfire size={22} /> <strong>WARNING</strong>
        </div>
        Please do this very carefully. We bear no responsibility for your
        mistake.
      </div>
      <div className="center-page-container">
        <Link to="/goshops-documentation/introduction" className="btn previous">
          <p>Previous</p>
          <p className="link"> Introduction </p>
        </Link>
        <Link to="/goshops-documentation/server" className="btn next">
          <p>Next</p>
          <p className="link"> Backend Requirements</p>
        </Link>
      </div>
    </div>
  );
};

export default Recommendations;
