import React from "react";
import { useFormik } from "formik";
import authService from "../../../../service/AuthService";
import LoadingIndicator from "../../../../components/Loader/loadingIndicator";
import { ArrowTopRight } from "../../../../components/Svg/goTop-svg";
import GoogleSignIn from "../GoogleSignIn";
import EnvatoSignIn from "../EnvatoSingIn";

const ConfirmPasswordEmail = ({ setStep, setVerifyEmail, verifyEmail }) => {
  const formik = useFormik({
    initialValues: {
      number: "",
    },
    onSubmit: (values, { setSubmitting }) => {
      const body = {
        email: verifyEmail.body.email,
        code: values.number,
      };
      authService
        .optCode(body)
        .then(() => {
          setStep(2);
          setVerifyEmail(verifyEmail.body.email);
        })
        .finally(() => setSubmitting(false));
    },
    validate: (values) => {
      const errors = {};
      if (!values.number) {
        errors.number = "Please code your email";
      }
      return errors;
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="ptf-form-group">
        <label data-number="01">What is your email code ?</label>
        <input
          name="number"
          type="text"
          className={
            formik.errors?.number && formik.touched?.number ? "error" : ""
          }
          value={formik.values.number}
          onChange={formik.handleChange}
          autoComplete="off"
        />
      </div>
      <div className="login-footer">
        <button
          className="ptf-submit-button"
          type="submit"
          disabled={formik.isSubmitting}
        >
          <span>Submit</span>
          {formik.isSubmitting ? <LoadingIndicator /> : <ArrowTopRight />}
        </button>
        <div className="footer-social">
          <EnvatoSignIn />
          <GoogleSignIn />
        </div>
      </div>
    </form>
  );
};

export default ConfirmPasswordEmail;
