import React from "react";
import moment from "moment";

const DeliveryBoy = ({ text, data, type }) => {
  return (
    <div className="delivery-boy-message">
      {text}
      <div className="time">
        {type} {moment(data).format("HH:mm")}
      </div>
    </div>
  );
};

export default DeliveryBoy;
