import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { GiCampfire } from "react-icons/gi";
import { Link } from "react-router-dom";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import NavigationBtns from "./Navigation-btns";

const UpdateFooyman = () => {
  return (
    <div
      className="introduction "
      data-aos="fade-right"
      data-aos-delay="300"
      data-aos-duration="1000"
    >
      <h1 className="title">How do I update existing code with new release?</h1>
      <h4 className="introduction-contentTitle">Special Notes</h4>
      <p>
        As per Envato policy installation, setup and configurations or
        modification are not included in free support. Free support is only for
        any bug/ error in original code. we do not provide installation and
        customization support in FREE SUPPORT. <br /> Still, We are providing
        steps for How to update future release code to existing source code for
        your knowledge purpose.
      </p>
      <h4 className="introduction-contentTitle">
        How to update future release code to existing source code
      </h4>
      <p>
        If you want which file changes are on the latest updated version then
        you have to manage the git repository by yourself.
      </p>
      <p>Here we have provided steps on how to update existing source code.</p>
      <h1 className="title">
        For First time: Initial project downloaded from codecanyon server. Step
      </h1>
      <h4 className="introduction-contentTitle">
        Step 1: Create or login with github
      </h4>
      <p>
        Login or register your account with github:
        <a href="https://github.com/login">https://github.com/login</a>
      </p>
      <h4 className="introduction-contentTitle">
        Step 2: Create a new project in Github
      </h4>
      <p>
        In your dashboard, click the green New button. This opens the New
        project page.
      </p>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a href="/assets/img/doc/c-repo.jpg" data-fancybox rel="nofollow">
              <img
                src="/assets/img/doc/c-repo.jpg"
                alt="images"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a href="/assets/img/doc/c_repo-2.png" data-fancybox rel="nofollow">
              <img
                src="/assets/img/doc/c_repo-2.png"
                alt="images"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <h4 className="introduction-contentTitle">
        Step 3: Clone your project to your local system
      </h4>
      <p>
        Once project is created on your github server. You have to clone the
        project to your local system. You can clone it with the command line.
      </p>
      <p>
        <strong>For ex:</strong> git clone
        https://github.com/YOURUSERNAME/your-repository-name.git
      </p>
      <p>Copy your project url and clone into your exiting system</p>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a href="/assets/img/doc/c_repo-3.png" data-fancybox rel="nofollow">
              <img
                src="/assets/img/doc/c_repo-3.png"
                alt="images"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a
              href="/assets/img/doc/clone-repo.png"
              data-fancybox
              rel="nofollow"
            >
              <img
                src="/assets/img/doc/clone-repo.png"
                alt="images"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <p>
        Once successfully clone then system will create a folder on your system
      </p>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a
              href="/assets/img/doc/local-repo.png"
              data-fancybox
              rel="nofollow"
            >
              <img
                src="/assets/img/doc/local-repo.png"
                alt="images"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <h4 className="introduction-img-container">
        Step 4: Download project from codecanyon server <br />
        Step 5: Copy/paste your initial downloaded project to clone directories.
      </h4>
      <p>
        Once successfully downloaded project from codecanyon, copy/paste your
        downloaded project into clone directories
      </p>
      <p>
        <strong>Note: </strong>
      </p>
      <p>Only orignal source code is put here.</p>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a
              href="/assets/img/doc/local-file.png"
              data-fancybox
              rel="nofollow"
            >
              <img
                src="/assets/img/doc/local-file.png"
                alt="images"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <h4 className="introduction-img-container">
        Step 6: Commit and push to github server
      </h4>
      <p>
        Onces copy/paste your changes to clone directres, you have to push all
        files to your github server. For that use the following commands.
      </p>
      <p>After That follow below steps</p>
      <ul>
        <li>– Goto inside your clone directory project</li>
      </ul>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a
              href="/assets/img/doc/go-repository.png"
              data-fancybox
              rel="nofollow"
            >
              <img
                src="/assets/img/doc/go-repository.png"
                alt="images"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <ul>
        <li>– Add your all files with “git add .” command</li>
        <li>– Now commit your changes with below command</li>
        <li>git commit -m ‘initial commit’</li>
      </ul>
      <p>
        <storng>Note:</storng>
      </p>
      <p>Write your latest version message instead of “initial commit”.</p>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a href="/assets/img/doc/git-add.png" data-fancybox rel="nofollow">
              <img
                src="/assets/img/doc/git-add.png"
                alt="images"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a
              href="/assets/img/doc/git-commit.png"
              data-fancybox
              rel="nofollow"
            >
              <img
                src="/assets/img/doc/git-commit.png"
                alt="images"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <ul>
        <li>– Push your changes to server with below command</li>
        <li>“git push” And provide you github credential details</li>
      </ul>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a href="/assets/img/doc/git-push.png" data-fancybox rel="nofollow">
              <img
                src="/assets/img/doc/git-push.png"
                alt="images"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <ul>
        <li>– Check your all changes to github server</li>
      </ul>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a href="/assets/img/doc/repo.png" data-fancybox rel="nofollow">
              <img
                src="/assets/img/doc/repo.png"
                alt="images"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <h1 className="title mt-4">
        For Update existing code (If Already have old version of project)
      </h1>
      <p>
        <strong>NOTE: </strong>If you remove the project from the local system
        then clone the project again from your github server. Follow the same
        above Step 3: Clone your project to your local system
      </p>
      <h4 className="introduction-contentTitle">
        Steps 1: Download the latest version from codecanyon server.
      </h4>
      <p>
        Once you will received mail for updates. Just download latest code from
        codecanyon server.
      </p>
      <h4 className="introduction-contentTitle">
        Steps 2: Copy/paste your initial downloaded project to clone
        directories.
      </h4>
      <p>
        Once successfully downloaded project from codecanyon, copy/paste your
        downloaded project into clone directories
      </p>
      <p>
        <strong>Note:</strong>
      </p>
      <p>Only orignal source code is put here.</p>
      <h4 className="introduction-contentTitle">
        Steps 3: Commit and push to github server
      </h4>
      <p>Follow same Step 6: Commit and push to github server</p>
      <h4 className="introduction-contentTitle">
        Steps 4: Check updated files
      </h4>
      <p>
        After committing your latest changes. Goto github project dashboard and
        click on commit link.
      </p>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a href="/assets/img/doc/commits.png" data-fancybox rel="nofollow">
              <img
                src="/assets/img/doc/commits.png"
                alt="images"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <p>Click on “XX changed file” to see which file has been changed.</p>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a href="/assets/img/doc/changed.png" data-fancybox rel="nofollow">
              <img
                src="/assets/img/doc/changed.png"
                alt="images"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <p>Connect to server and open project directory</p>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a href="/assets/img/doc/root.png" data-fancybox rel="nofollow">
              <img
                src="/assets/img/doc/root.png"
                alt="images"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <p>Run “git init” command to initialize git</p>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a href="/assets/img/doc/init.png" data-fancybox rel="nofollow">
              <img
                src="/assets/img/doc/init.png"
                alt="images"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <p>
        Run following commant to connect your server with github repository.
        Don’t forget to put your oauth key and github repository url.
      </p>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a
              href="/assets/img/doc/git-remote.png"
              data-fancybox
              rel="nofollow"
            >
              <img
                src="/assets/img/doc/git-remote.png"
                alt="images"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <p>Pull changes using following command</p>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a href="/assets/img/doc/git-pull.png" data-fancybox rel="nofollow">
              <img
                src="/assets/img/doc/git-pull.png"
                alt="images"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <div className="alert alert-danger mt-4" role="alert">
        <div className="mb-2">
          <GiCampfire size={22} /> <strong>WARNING</strong>
        </div>
        if you have any confilicts while pulling, use google to find a solution
      </div>
      <div className="alert alert-primary mt-3" role="alert">
        <div className="mb-2">
          <AiOutlineInfoCircle size={22} /> <strong>INFO</strong>
        </div>
        Do above steps to update admin website, customer website and backend.
      </div>
      <NavigationBtns />

      {/* <div className="introduction-img-container">
        <img
          src="/assets/img/doc/translation2.jpg"
          alt="img"
          className="img-responsive"
        />
        <img
          src="/assets/img/doc/translation1.jpg"
          alt="img"
          className="img-responsive"
        />
      </div>
      <h4 className="introduction-contentTitle">Change App Color</h4>• Open{" "}
      <span className="introduction-step">
        /lib/presentation/styles/style.dart
      </span>
      file and change colors as you want. <br />
      <div className="introduction-img-container">
        <img
          src="/assets/img/doc/color.jpg"
          alt="img"
          className="img-responsive-full"
        />
      </div>
      <h4 className="introduction-contentTitle">Change App Font</h4>• in our
      apps, google font package is installed. You may change app fonts easily by
      selecting new fonts */}
    </div>
  );
};

export default UpdateFooyman;
