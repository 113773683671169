import React from "react";
import { Helmet } from "react-helmet";
import CopyRight from "../../../components/Footer/Copyright/CopyRight";
import Footer from "../../../components/Footer/Footer";
import Social from "../../../components/Social/Social";
import WorksCaseStudy from "./WorksCaseStudy";
import ImageGridThree from "../../../components/Image-grid/ImageGridThree";
import HeaderDark from "../../../components/Header/HeaderDark";

export default function WorksShowcase() {
  return (
    <div className="ptf-site-wrapper animsition ptf-is--work-showcase-1">
      <Helmet>
        <title>Githubit - Project single</title>
      </Helmet>
      <HeaderDark />
      <div className="ptf-site-wrapper__inner">
        <div className="main">
          <article className="ptf-page ptf-page--single-work-1">
            <section>
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
              />
              <div className="container-xxl">
                <div className="row">
                  <div className="col-xl-6">
                    <div
                      className="ptf-animated-block"
                      data-aos="fade"
                      data-aos-delay="0"
                    >
                      <h1 className="large-heading">
                        Gshop <br /> E-commerce
                      </h1>
                      <div
                        className="ptf-spacer"
                        style={{ "--ptf-xxl": "4rem", "--ptf-md": "2.5rem" }}
                      />
                      <Social />
                    </div>
                    <div
                      className="ptf-spacer"
                      style={{
                        "--ptf-lg": "4.375rem",
                        "--ptf-md": "2.1875rem",
                      }}
                    />
                  </div>
                  <WorksCaseStudy />
                </div>
              </div>
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "2.25rem", "--ptf-md": "1rem" }}
              />
            </section>

            <section>
              <div className="container-xxl">
                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  {Array.from(Array(16).keys()).map(
                    (val, i) => (
                      console.log(val),
                      (
                        <div className="ptf-simple-image" key={i}>
                          <img
                            src={`assets/img/project/${val}.png`}
                            alt="work"
                            loading="lazy"
                            width={"100%"}
                          />
                        </div>
                      )
                    )
                  )}
                </div>
              </div>
            </section>

            <section>
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "10rem", "--ptf-md": "3.125rem" }}
              />

              <div className="container">
                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <p className="fz-30 has-black-color">
                    Lewis Studio Website is a startup that aims to supply energy
                    (starting with gas) to domestic household acrossthe UK.
                    Create a very simple yet stunning logotype and promo site
                    that sets the brand of Entice Energy apart from the
                    competitors like a fresh take on an already saturated area.
                  </p>
                </div>

                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "4.375rem", "--ptf-md": "2.1875rem" }}
                />

                <div
                  className="ptf-divider"
                  style={{
                    "--ptf-height": "1px",
                    "--ptf-color": "var(--ptf-color-14)",
                  }}
                />

                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
                />

                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <h2>Challenge</h2>
                </div>

                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "1.5625rem" }}
                />

                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <p className="fz-18">
                    Create an unconventional yet user-friendly website –
                    innovative, with a clean and simple design that communicates
                    brand values and showcases multi-media content. Site that
                    spreads the message: "Islamic stories your child will love"
                  </p>
                </div>

                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "1.5625rem" }}
                />

                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <ul className="fz-18" style={{ lineHeight: 2 }}>
                    <li>
                      Develop easy-to-find and easy-to-navigate mobile friendly
                      website
                    </li>
                    <li>
                      Showcase each type of content: interactive books, animated
                      stories and picture books, audio stories. Create an
                      experience people want to share with others
                    </li>
                    <li>Persuade to download app and subscribe</li>
                  </ul>
                </div>

                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "4.375rem", "--ptf-md": "2.1875rem" }}
                />

                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <div className="ptf-twitter-review ptf-twitter-review--style-3">
                    <div className="ptf-twitter-review__header">
                      <div className="ptf-twitter-review__avatar">
                        <img
                          src="assets/img/root/twitter-avatar.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div className="ptf-twitter-review__meta">
                        <h6 className="ptf-twitter-review__author">
                          Thiago Alcantara
                        </h6>
                        <div className="ptf-twitter-review__info">
                          <a href="/">@thiago.lfc</a> - 15 Dec, 2022
                        </div>
                      </div>
                      <div className="ptf-twitter-review__icon">
                        <i className="socicon-twitter" />
                      </div>
                    </div>
                    <div className="ptf-twitter-review__content">
                      <p>
                        The team at <a href="/">@Githubit</a> is incredibly
                        dedicated, knowledgeable, and helpful. The finished
                        product was beautiful, and worth every penny. I would
                        absolutely recommend Githubit Labs.
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
                />

                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <h2>Solution</h2>
                </div>

                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "1.5625rem" }}
                />

                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <p className="fz-18">
                    Our approach was to present the site as a visual editorial
                    platform with quarterly features based on events and
                    occasions the brand was focused on. Each quarterly focus
                    would be marked by the hero and custom tags that filter
                    content.
                  </p>
                </div>

                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "2.8125rem" }}
                />

                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <ImageGridThree />
                </div>
              </div>
            </section>

            <section>
              <div className="ptf-post-navigation ptf-post-navigation--style-2">
                <div className="container">
                  <span>Next Project</span>
                  <a className="h1 large-heading ptf-filled-link" href="/">
                    SPA Brand
                  </a>
                </div>
              </div>
            </section>
          </article>
        </div>
      </div>

      <footer className="ptf-footer ptf-footer--style-1">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <Footer />
          </div>
          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
  );
}
