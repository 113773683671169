import React from "react";
import { Helmet } from "react-helmet";
import HeaderDark from "../../../../components/Header/HeaderDark";
import Address from "../../../../components/Address";
import FooterThreeDark from "../../../../components/Footer/FooterThreeDark";
import CopyRight from "../../../../components/Footer/Copyright/CopyRight";
import LoginForm from "./loginForm";
import { Link } from "react-router-dom";
import FooterFour from "../../../../components/Footer/FooterFour";

const Login = () => {
  return (
    <div>
      <Helmet>
        <title>Githubit - Login</title>
      </Helmet>
      <HeaderDark />
      <div className="ptf-main">
        <div className="ptf-page ptf-page--contact">
          <section>
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
            />

            <div className="container-xxl">
              <div className="row">
                <div className="col-xl-10">
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <h1 className="large-heading text-white">Login</h1>
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "3rem", "--ptf-md": "2.5rem" }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
            />
          </section>

          <section>
            <div className="container-xxl">
              <div className="row">
                <div className="col-lg-4">
                  <Address />
                </div>
                <div className="col-lg-8">
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="300"
                  >
                    <h5 className="fz-16 text-uppercase has-black-color fw-normal">
                      Don't have an account?{" "}
                      <Link to="/registration" className="text-danger">
                        Register
                      </Link>
                    </h5>
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "3.125rem" }}
                    />
                    <LoginForm />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
            />
          </section>
        </div>
      </div>
    </div>
  );
};

export default Login;
