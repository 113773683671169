import React from "react";
import { Link } from "react-router-dom";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

const Customization = () => {
  return (
    <div
      className="introduction "
      data-aos="fade-right"
      data-aos-delay="300"
      data-aos-duration="1000"
    >
      <h1 className="title">Customization</h1>
      <h4 className="introduction-contentTitle"> Translate admin panel</h4>•
      Translation admin panel is very easy. To translate admin panel, open{" "}
      <br />
      <span className="introduction-step-2">
        Settings{">"} Translations menu
      </span>
      and Translate all words into your language.
      <br />
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            {/* <!--Simple Image--> */}
            <a
              href="/assets/img/doc/translation2.jpg"
              data-fancybox
              rel="nofollow"
            >
              <img
                src="/assets/img/doc/translation2.jpg"
                alt="image01"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
        <SimpleReactLightbox>
          <SRLWrapper>
            <a
              href="/assets/img/doc/translation1.jpg"
              data-fancybox
              rel="nofollow"
            >
              <img
                src="/assets/img/doc/translation1.jpg"
                alt="image02"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <div className="center-page-container">
        <Link
          to="/demand24-documentation/mandatory-setup"
          className="btn  previous"
        >
          <p>Previous</p>
          <p className="link"> Mandatory setup Admin Panel </p>
        </Link>
        <Link
          to="/demand24-documentation/troubleshooting-admin"
          className="btn  next"
        >
          <p>Next</p>
          <p className="link"> Troubleshooting admin</p>
        </Link>
      </div>
    </div>
  );
};

export default Customization;
