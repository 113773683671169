import React from "react";
import { BsPlusCircleDotted } from "react-icons/bs";
import LoadingIndicator from "../Loader/loadingIndicator";

const UploadButton = ({ loading }) => {
  return (
    <div className="text-center">
      <div>
        {loading ? <LoadingIndicator /> : <BsPlusCircleDotted size={50} />}
      </div>
    </div>
  );
};

export default UploadButton;
