import React from "react";
import { Route } from "react-router-dom";
import Recommendations from "../views/Foodyman-doc/recommendations";
import PaymentInstallation from "../views/Uzmart-doc/Payment-Installation";
import AppBuildReleaseCustomer from "../views/Foodyman-doc/App-build-release-customer";
import AppBuildReleasePos from "../views/Foodyman-doc/App-build-release-pos";
import AppBuildReleaseVendor from "../views/Foodyman-doc/App-build-release-vendor";
import AppBuildReleaseDelivery from "../views/Foodyman-doc/App-build-release-deliveryboy";
import QrDoc from "../views/qrcode-doc";
import QrIntroduction from "../views/qrcode-doc/Introduction";
import QrBasic from "../views/qrcode-doc/Basic";
import QrServer from "../views/qrcode-doc/Server";
import QrFront from "../views/qrcode-doc/front";
import QrAdmin from "../views/qrcode-doc/admin";
import QrLocalFront from "../views/qrcode-doc/local-front";
import QrMobileApp from "../views/qrcode-doc/Mobile-app";
import QrFlutterSDK from "../views/qrcode-doc/Flutter-SDK";
import QrLocalServer from "../views/qrcode-doc/Local-server";
import QrInstallOnServer from "../views/qrcode-doc/Install-on-server";
import QrMandatorySetup from "../views/qrcode-doc/Mandatory-setup";
import QrCustomization from "../views/qrcode-doc/Customization";
import QrMandatorySetupMobile from "../views/qrcode-doc/Mandatory-setup-mobile";
import QrMandatorySetupBackend from "../views/qrcode-doc/Mandatory-setup-backend";
import QrMandatorySetupVendor from "../views/qrcode-doc/Moderator-setup-vendor";
import QrMandatorySetupCustomer from "../views/qrcode-doc/Moderator-setup-customer";
import QrMandatorySetupPos from "../views/qrcode-doc/Moderator-setup-pos";
import QrMandatorySetupDeliveryboy from "../views/qrcode-doc/Moderator-setup-deliveryboy";
import QrCustomizationMobile from "../views/qrcode-doc/Customization-mobile";
import CustomizationMobileDeliveryQr from "../views/qrcode-doc/Customization-mobile-delivery";
import CustomizationMobileCustomerQr from "../views/qrcode-doc/Customization-mobile-customer";
import CustomizationMobilePosQr from "../views/qrcode-doc/Customization-mobile-pos";
import QrMandatorySetupWeb from "../views/qrcode-doc/Mandatory-setup-web";
import QrBuildCodeAndSetupOnServer from "../views/qrcode-doc/Build-code-and-setup-on-server";
import QrBuildCodeAndSetupOnServerBackend from "../views/qrcode-doc/Build-code-and-setup-on-server-backend";
import QrUpdateAdminPanel from "../views/qrcode-doc/Update-admin-panel";
import QrUpdateAppWeb from "../views/qrcode-doc/Update-app-web";
import QrSupportPlan from "../views/qrcode-doc/supportPlan";
import QrFirebaseSetup from "../views/qrcode-doc/firebase-setup";
import TroubleshootingBackendQr from "../views/qrcode-doc/Troubleshooting-backend";
import TroubleshootingAdminQr from "../views/qrcode-doc/Troubleshooting-admin";
import UpdateFooymanQr from "../views/qrcode-doc/update";

const Qrcode = () => {
  return (
      <Route path="/qrcode-documentation" element={<QrDoc />}>
          <Route
              path="/qrcode-documentation/introduction"
              element={<QrIntroduction />}
          />
          <Route path="/qrcode-documentation/basic" element={<QrBasic />} />
          <Route path="/qrcode-documentation/server" element={<QrServer />} />
          <Route path="/qrcode-documentation/front" element={<QrFront />} />
          <Route path="/qrcode-documentation/admin" element={<QrAdmin />} />
          <Route
              path="/qrcode-documentation/recommendations"
              element={<Recommendations />}
          />
          <Route
              path="/qrcode-documentation/local-front"
              element={<QrLocalFront />}
          />
          <Route
              path="/qrcode-documentation/mobile-app"
              element={<QrMobileApp />}
          />
          <Route
              path="/qrcode-documentation/vendor-app"
              element={<QrMobileApp />}
          />
          <Route
              path="/qrcode-documentation/driver-app"
              element={<QrMobileApp />}
          />
          <Route
              path="/qrcode-documentation/waiter-app"
              element={<QrMobileApp pageTitle="Waiter app" />}
          />
          <Route
              path="/qrcode-documentation/pos-app"
              element={<QrMobileApp />}
          />
          <Route
              path="/qrcode-documentation/flutter-sdk"
              element={<QrFlutterSDK />}
          />
          <Route
              path="/qrcode-documentation/local-server"
              element={<QrLocalServer />}
          />
          <Route
              path="/qrcode-documentation/install-on-server"
              element={<QrInstallOnServer />}
          />
          <Route
              path="/qrcode-documentation/mandatory-setup"
              element={<QrMandatorySetup />}
          />
          <Route
              path="/qrcode-documentation/customization"
              element={<QrCustomization />}
          />
          <Route
              path="/qrcode-documentation/mandatory-setup-mobile"
              element={<QrMandatorySetupMobile />}
          />
          <Route
              path="/qrcode-documentation/mandatory-setup-backend"
              element={<QrMandatorySetupBackend />}
          />
          <Route
              path="/qrcode-documentation/payment-installation"
              element={<PaymentInstallation />}
          />
          <Route
              path="/qrcode-documentation/mandatory-setup-vendor"
              element={<QrMandatorySetupVendor />}
          />
          <Route
              path="/qrcode-documentation/mandatory-setup-customer"
              element={<QrMandatorySetupCustomer />}
          />
          <Route
              path="/qrcode-documentation/mandatory-setup-pos"
              element={<QrMandatorySetupPos />}
          />
          <Route
              path="/qrcode-documentation/mandatory-setup-deliveryboy"
              element={<QrMandatorySetupDeliveryboy />}
          />
          <Route
              path="/qrcode-documentation/mandatory-setup-waiter"
              element={<QrMandatorySetupDeliveryboy />}
          />
          <Route
              path="/qrcode-documentation/customization-vendor"
              element={<QrCustomizationMobile />}
          />
          <Route
              path="/qrcode-documentation/customization-deliveryboy"
              element={<CustomizationMobileDeliveryQr />}
          />
          <Route
              path="/qrcode-documentation/customization-waiter"
              element={<CustomizationMobileDeliveryQr />}
          />
          <Route
              path="/qrcode-documentation/customization-customer"
              element={<CustomizationMobileCustomerQr />}
          />
          <Route
              path="/qrcode-documentation/customization-pos"
              element={<CustomizationMobilePosQr />}
          />
          <Route
              path="/qrcode-documentation/customer-app-build-release"
              element={<AppBuildReleaseCustomer />}
          />
          <Route
              path="/qrcode-documentation/pos-app-build-release"
              element={<AppBuildReleasePos />}
          />
          <Route
              path="/qrcode-documentation/vendor-app-build-release"
              element={<AppBuildReleaseVendor />}
          />
          <Route
              path="/qrcode-documentation/deliveryboy-app-build-release"
              element={<AppBuildReleaseDelivery />}
          />
              <Route
                  path="/qrcode-documentation/waiter-app-build-release"
                  element={<AppBuildReleaseDelivery />}
              />
          <Route
              path="/qrcode-documentation/mandatory-setup-web"
              element={<QrMandatorySetupWeb />}
          />
          <Route
              path="/qrcode-documentation/build-code-and-setup-on-server"
              element={<QrBuildCodeAndSetupOnServer />}
          />
          <Route
              path="/qrcode-documentation/build-code-and-setup-on-server-backend"
              element={<QrBuildCodeAndSetupOnServerBackend />}
          />
          <Route
              path="/qrcode-documentation/update-admin-panel"
              element={<QrUpdateAdminPanel />}
          />
          <Route
              path="/qrcode-documentation/update-app-web"
              element={<QrUpdateAppWeb />}
          />
          <Route
              path="/qrcode-documentation/support-plan"
              element={<QrSupportPlan />}
          />
          <Route
              path="/qrcode-documentation/firebase"
              element={<QrFirebaseSetup />}
          />

          <Route
              path="/qrcode-documentation/troubleshooting-backend"
              element={<TroubleshootingBackendQr />}
          />
          <Route
              path="/qrcode-documentation/troubleshooting-admin"
              element={<TroubleshootingAdminQr />}
          />
          <Route
              path="/qrcode-documentation/update"
              element={<UpdateFooymanQr />}
          />
      </Route>
  );
};

export default Qrcode;
