import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import NavigationBtns from "./Navigation-btns";

const ImageSettings = () => {
  return (
    <div
      className="introduction "
      data-aos="fade-right"
      data-aos-delay="300"
      data-aos-duration="1000"
    >
      <h1 className="title"> Image Settings</h1>
      <div className="alert alert-danger mt-3" role="alert">
        <div className="mb-2">
          <AiOutlineInfoCircle size={22} /> <strong>INFO</strong>
        </div>
        You can upload images to server storage or aws s3 space.
      </div>
      <h4>
        1. If you want to use server storage to upload, do following steps:
      </h4>
      <div className="introduction-img-container mb-5">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a
              href="/assets/img/doc/img-settings.png"
              data-fancybox
              rel="nofollow"
            >
              <img
                src="/assets/img/doc/img-settings.png"
                alt="admin install"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <ol>
        <li>
          Open .env file and change <strong>example.com</strong> with your
          backend website url
        </li>
        <li>Open public folder and remove storage</li>
        <li>
          Run following commands on terminal: "php artisan storage link" and
          "php artisan optimize:clear
        </li>
        <li>
          Open admin website, go to General settings page and press permission
          tab. Disable aws and save.
        </li>
      </ol>
      <div className="introduction-img-container mb-5">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a
              href="/assets/img/doc/img_settings.jpg"
              data-fancybox
              rel="nofollow"
            >
              <img
                src="/assets/img/doc/img_settings.jpg"
                alt="admin install"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>

      <h4>
        2. If you want to use aws s3 bucket to upload images, do following
        steps:
      </h4>
      <ol>
        <li>Configure your aws s3 bucket</li>
        <li>
          Open .env and put your aws bucket link and all necessary aws keys.
          Then save.
        </li>
        <div className="introduction-img-container mb-5">
          <SimpleReactLightbox>
            <SRLWrapper>
              <a
                href="/assets/img/doc/img-setting-storage.png"
                data-fancybox
                rel="nofollow"
              >
                <img
                  src="/assets/img/doc/img-setting-storage.png"
                  alt="admin install"
                  loading="lazy"
                  className="img-responsive-full"
                />
              </a>
            </SRLWrapper>
          </SimpleReactLightbox>
        </div>
        <li>run following command on terminal: "php artisan optimize:clear"</li>
      </ol>
      <NavigationBtns />
    </div>
  );
};

export default ImageSettings;
