import authRequest from "./auth-interceptor";

const authService = {
  login: (data) => authRequest.post("/api/v1/auth/login", data, null),
  register: (params) => authRequest.post("/api/v1/auth/register", params, null),
  socialRegister: (params) =>
    authRequest.post("/api/v1/auth/google/register", params, null),
  socialLogin: (params) =>
    authRequest.post("/api/v1/auth/google/login", params, null),
  optCode: (params) =>
    authRequest.post("/api/v1/auth/check-code", params, null),
  registrationForm: (params) =>
    authRequest.post("/api/v1/auth/user/store", params, null),
  envatoAuth: (data) => authRequest.post("/api/v1/auth/envato/login", data),
};

export default authService;
