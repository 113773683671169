export const portfolioContent = [
  {
    tabContent: [
      {
        img: "Banner 1 version2.png",
        title: "Goshops - Multi - seller e-commerce marketplace",
        description: "Web & Mobile development",
        link: "/goshop-multi-seller",
      },
      {
        img: "Banner 1 version2.png",
        title: "Goshops - Multi - shop e-commerce marketplace",
        description: "Web & Mobile development",
        link: "/goshop-multi-shop",
      },
      {
        img: "Banner 1 version2.png",
        title: "Goshops - Single shop e-commerce marketplace",
        description: "Web & Mobile development",
        link: "/goshop-single-shop",
      },
      {
        img: "New Home.jpg",
        title: "Sundaymart - Multivendor grocery e-commerce (new design)",
        description: "Web & Mobile development",
        link: "/sundaymart-multi-vendor-new",
      },
      {
        img: "View all store.jpg",
        title: "Sundaymart - Multivendor grocery e-commerce",
        description: "Web & Mobile development",
        link: "/sundaymart-multi-vendor",
      },
      {
        img: "foodyman-banner.jpg",
        title: "Foodyman - Multi-Restaurant Food",
        description: "Web & Mobile development",
        link: "/foodyman-multi-service",
      },
      {
        img: "Restaurant - Group order bag fill.png",
        title: "Foodyman - Single Restaurant",
        description: "Web & Mobile development",
        link: "/foodyman-single-service",
      },
      {
        img: "18.png",
        title: "Uzmart E-Commerce",
        description: "Web & Mobile development",
        link: "/uzmart-multi-service",
      },
      {
        img: "20.png",
        title: "Uzmart  - Single multi branch E-Commerce",
        description: "Web & Mobile development",
        link: "/uzmart-single-multi-service",
      },
      {
        img: "16.jpg",
        title: "Damda - Travel to Uzbekistan",
        description: "Web & Mobile development",
        link: "/damda-service",
      },
      {
        img: "18.avif",
        title: "Qrcode - A table QR-code based contactless ordering",
        description: "Web & Mobile development",
        link: "/qrcode-multi-service",
      },
      {
        img: "g-store.png",
        title: "G-store - Mobile UI Kit",
        description: "Mobile development",
        link: "/mobile-ui-kit-service",
      },
      {
        img: "saas-banner.jpg",
        title: "Foodyman-SAAS",
        description: "Mobile development",
        link: "/saas-multi-service",
      },
    ],
  },
  {
    tabContent: [
      {
        img: "Banner 1 version2.png",
        title: "Goshops - Multi - seller e-commerce marketplace",
        description: "Web & Mobile development",
        link: "/goshop-multi-seller",
      },
      {
        img: "Banner 1 version2.png",
        title: "Goshops - Multi - shop e-commerce marketplace",
        description: "Web & Mobile development",
        link: "/goshop-multi-shop",
      },
      {
        img: "Banner 1 version2.png",
        title: "Goshops - Single shop e-commerce marketplace",
        description: "Web & Mobile development",
        link: "/goshop-single-shop",
      },
    ],
  },
  {
    tabContent: [
      {
        img: "New Home.jpg",
        title: "Sundaymart - Multivendor grocery e-commerce (new design)",
        description: "Web & Mobile development",
        link: "/sundaymart-multi-vendor-new",
      },
      {
        img: "View all store.jpg",
        title: "Sundaymart - Multivendor grocery e-commerce",
        description: "Web & Mobile development",
        link: "/sundaymart-multi-vendor",
      },
    ],
  },
  {
    tabContent: [
      {
        img: "foodyman-banner.jpg",
        title: "Foodyman - Multi-Restaurant Food",
        description: "Web & Mobile development",
        link: "/foodyman-multi-service",
      },
      {
        img: "Restaurant - Group order bag fill.png",
        title: "Foodyman - Single Restaurant",
        description: "Web & Mobile development",
        link: "/foodyman-single-service",
      },
    ],
  },
  {
    tabContent: [
      {
        img: "16.jpg",
        title: "Damda - Travel to Uzbekistan",
        description: "Web & Mobile development",
        link: "/damda-service",
      },
    ],
  },
];
