import React from "react";
import { Helmet } from "react-helmet";
import CopyRight from "../../../../components/Footer/Copyright/CopyRight";
import HeaderDark from "../../../../components/Header/HeaderDark";
import FooterThreeDark from "../../../../components/Footer/FooterThreeDark";
import OnePage from "../../../../components/Hero/OnePage";

import { AiFillYoutube } from "react-icons/ai";
import { IoDocumentText } from "react-icons/io5";
import { features } from "../../../../fake-data/Damda-feature";
import KeyFeatures from "../../../../components/KeyFeatures";

const skillContent = [
  {
    icon: <AiFillYoutube />,
    link: "https://youtu.be/T1Zsw9zYj-U",
    title: "Damda Travel",
    delayAnimation: "500",
    target: "_blank",
  },
  {
    icon: <IoDocumentText />,
    link: "https://damda.uz/en/for/guides",
    title: "Documentation",
    delayAnimation: "600",
    target: "_blank",
  },
];

const DamdaAndroid = () => {
  return (
    <div className="ptf-site-wrapper animsition ptf-is--work-showcase-1">
      <Helmet>
        <title>Githubit - Damda Traveling</title>
      </Helmet>
      <HeaderDark />
      <div className="ptf-site-wrapper__inner">
        <div className="main">
          <article className="ptf-page ptf-page--single-work-1">
            <section>
              <OnePage
                skillContent={skillContent}
                buy_link={
                  "https://play.google.com/store/apps/details?id=com.damda.damdac"
                }
                descriptions="If you want to travel around Uzbekistan, damda.uz can be your personal guide. You can find all the information about the best touristic attractions, holiday destinations, open-air museums and book online any hotel, resort, dacha just by one click. Moreover, you can contact with professional guides through this website."
                subTitle="Sayyoh"
                title="Damda"
                img_url="16.jpg"
                button_text={"Download project"}
              />
            </section>

            <section>
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "10rem", "--ptf-md": "3.125rem" }}
              />

              <KeyFeatures
                text={"Main features of damda project"}
                features={features}
                keyFeatures={"PROJECT TOP FEATURES"}
              />

              <div className="container-xxl pt-5">
                <div
                  className="row ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <div className="col-lg-6">
                    <img
                      src="assets/img/root/app.png"
                      alt="img"
                      className="rounded"
                      width={500}
                      height={300}
                    />
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-5">
                      <h4>Android supported (Flutter App) </h4>
                      The Damda landlord mobile app is developed after lots of
                      UI/UX thinking, So the app is very user friendly.
                    </div>
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.damda.damdac"
                    >
                      <button className="btn btn-success">
                        Google Play (Damda sayyoh)
                      </button>
                    </a>
                  </div>
                </div>
                <div className="mt-5" />
              </div>
            </section>
          </article>
        </div>
      </div>

      <footer className="ptf-footer ptf-footer--style-5">
        <div className="container-xxl">
          <div
            className="ptf-divider"
            style={{
              "--ptf-height": "1px",
              "--ptf-color": "var(--border-color)",
            }}
          />
          <div className="ptf-footer__top">
            <FooterThreeDark />
          </div>

          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default DamdaAndroid;
