import React from "react";

export default function KeyFeatures({
  features,
  text,
  description,
  keyFeatures = "KEY FEATURES",
}) {
  return (
    <>
      <div className="container-xxl">
        <div
          className="ptf-animated-block text-center"
          data-aos="fade"
          data-aos-delay="0"
        >
          <h5 style={{ color: "chocolate" }}>{keyFeatures}</h5>
          <h3 className="large-heading fw-semibold mb-4 mt-3">{text}</h3>
          <p className="large-heading fw-semibold mb-4 mt-3">{description}</p>

          <div className="row mb-5 mt-5">
            {features.map((item, idx) => (
              <div
                key={idx}
                className="col-xxl-2 col-xl-3 col-md-3 col-sm-4 mb-4"
                data-aos="fade"
                data-aos-delay={item.delayAnimation}
              >
                <div className="card features h-100">
                  <div className="card-header">
                    <span
                      className="features-icon"
                      style={{ color: item.bgColor }}
                    >
                      {item.icon}
                    </span>
                  </div>
                  <div className="card-body">
                    <h5 className="card-title has-white-color">{item.title}</h5>
                    <p className="card-text has-white-color">{item.subTitle}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
