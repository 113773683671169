import React from "react";
import { Helmet } from "react-helmet";
import Award from "../../../components/Award/Award";
import Brand from "../../../components/Brand/Brand";
import Counter from "../../../components/Counter/Counter";
import CopyRight from "../../../components/Footer/Copyright/CopyRight";
import Footer from "../../../components/Footer/Footer";
import ImageGrid from "../../../components/Image-grid/ImageGrid";
import ServiceOne from "../../../components/Service/ServiceOne";
import Testimonial from "../../../components/Testimonial/Testimonial";
import HeaderDark from "../../../components/Header/HeaderDark";

const AboutUs = () => {
  return (
    <div className="ptf-site-wrapper animsition  ptf-is--about-us">
      <Helmet>
        <title>Githubit - About Us</title>
      </Helmet>
      {/* End Page SEO Content */}
      <div className="ptf-site-wrapper__inner">
        <HeaderDark />
        {/* End  HeaderHomeDefault */}

        <div className="ptf-main">
          <div className="ptf-page ptf-page--about-us">
            <section>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
              ></div>

              <div className="container-xxl">
                <div className="row align-items-center">
                  <div className="col-xl-8">
                    <div className="row">
                      <div className="col-xl-8">
                        <div
                          className="ptf-animated-block"
                          data-aos="fade"
                          data-aos-delay="200"
                        >
                          <p className="fz-24">
                            Start exciting journey of innovation and growth with
                            us We are a team of young yet experienced explorers,
                            driven by curiosity and a deep passion for
                            information technology and graphic design. In
                            everything we do, we strive for excellence. Our core
                            values are built on honesty, a great sense of
                            creativity, and an unwavering commitment to crafting
                            long-lasting and valuable products for our clients.
                            Listening to your ideas and understanding your
                            vision is paramount to us. We take great pleasure in
                            collaborating with you to uncover the best ways to
                            empower your business with a diverse range of
                            information technologies. Whether it’s web
                            development, graphic design, or any other digital
                            solution, we are here to help your business excel.
                            Reach out to us on Telegram (1 202 340 1032) to
                            embark on an exciting journey of innovation and
                            growth together.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-4">
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xl": "5.625rem" }}
                    ></div>
                    {/* <!--Animated Block--> */}
                    <div
                      className="ptf-animated-block"
                      data-aos="fade"
                      data-aos-delay="300"
                    >
                      <div className="text-center">
                        {/* <!--Mask Image--> */}
                        <div className="ptf-mask-image">
                          <img
                            src="assets/img/root/about-us/about-us-main-image-mask.png"
                            alt="layer"
                            loading="lazy"
                            className="lay2"
                            style={{
                              zIndex: "1",
                            }}
                            srcSet="assets/img/about-us-main-image.jpg"
                          />
                          <img
                            src="assets/img/root/about-us/about-us-main-image-layer-1.png"
                            alt="layer"
                            loading="lazy"
                            style={{ zIndex: "2" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
              ></div>
            </section>

            <section>
              <div className="container-xxl">
                {/* <!--Divider--> */}
                <div className="ptf-divider"></div>
              </div>
            </section>
          </div>
        </div>
        <footer className="ptf-footer ptf-footer--style-1">
          <div className="container-xxl">
            <div className="ptf-footer__top">
              <Footer />
            </div>
            <div className="ptf-footer__bottom">
              <CopyRight />
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default AboutUs;
