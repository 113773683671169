import React from "react";
import { Helmet } from "react-helmet";
import CopyRight from "../../../components/Footer/Copyright/CopyRight";
import HeaderDark from "../../../components/Header/HeaderDark";
import FooterThreeDark from "../../../components/Footer/FooterThreeDark";
import ImageGridDemo from "../../../components/Image-grid/ImageGridDemo";
import OnePage from "../../../components/Hero/OnePage";
import { FaApple, FaAppStore, FaGooglePlay, FaUserCog } from "react-icons/fa";
import { BiRadioCircle } from "react-icons/bi";
import { RiCustomerService2Fill, RiGitRepositoryLine } from "react-icons/ri";
import { TbTruckDelivery } from "react-icons/tb";
import { AiFillYoutube, AiOutlineHistory } from "react-icons/ai";
import { IoDocumentText } from "react-icons/io5";
import { TiVendorAndroid } from "react-icons/ti";
import { demand24 } from "../../../fake-data/demand-feature";
import KeyFeatures from "../../../components/KeyFeatures";
import PaymentList from "../../../components/Payment";
import {SiGoogledomains} from "react-icons/si";
import {payments} from "../../../fake-data/Uzmart-doc-menu";

const imageList = [
  {
    img: "demand-19",
    column: "col-lg-12",
    alt: "Demand24",
    link: "https://demand24.org",
    new: false,
  },
];

const skillContent = [
  {
    icon: <FaUserCog />,
    link: "https://admin.demand24.org",
    title: "Admin panel",
    delayAnimation: "100",
    target: "_blank",
  },
  {
    icon: <SiGoogledomains />,
    link: "https://demand24.org",
    title: "Web Site",
    delayAnimation: "100",
    target: "_blank",
  },
  {
    icon: <FaAppStore />,
    link: "https://testflight.apple.com/join/mmJJo9tO",
    title: "Customer app",
    delayAnimation: "200",
    target: "_blank",
  },
  {
    icon: <FaGooglePlay />,
    link: "https://drive.google.com/file/d/1yUcApA80hKOc4FZpMObZVJg3GX-f5ity/view?usp=drive_link",
    title: "Customer app",
    delayAnimation: "200",
    target: "_blank",
  },
  {
    icon: <TbTruckDelivery />,
    link: "https://drive.google.com/file/d/1q4XJFZllTFtImhsWiGHIoW-fi4RaxnXE/view?usp=sharing",
    title: "Delivery app",
    delayAnimation: "300",
    target: "_blank",
  },
  {
    icon: <TiVendorAndroid />,
    link: "https://drive.google.com/file/d/1y7qlwMAOe375M2UoFARTAUcrVp7O3Egu/view?usp=sharing",
    title: "Seller app",
    delayAnimation: "300",
    target: "_blank",
  },
  {
    icon: <FaApple />,
    link: "https://testflight.apple.com/join/1qqI50Pg",
    title: "Seller app",
    delayAnimation: "300",
    target: "_blank",
  },
  {
    icon: <TbTruckDelivery />,
    link: "https://drive.google.com/file/d/1q4XJFZllTFtImhsWiGHIoW-fi4RaxnXE/view?usp=sharing",
    title: "Delivery app (android)",
    delayAnimation: "300",
    target: "_blank",
  },
  {
    icon: <TbTruckDelivery />,
    link: "https://testflight.apple.com/join/wacQcYvI",
    title: "Delivery app (iOS)",
    delayAnimation: "300",
    target: "_blank",
  },
  {
    icon: <RiGitRepositoryLine />,
    link: "https://testflight.apple.com/join/LhZGzQrs",
    title: "POS (iOS)",
    delayAnimation: "300",
    target: "_blank",
  },
  {
    icon: <RiGitRepositoryLine />,
    link: "https://drive.google.com/file/d/1SuCtsAoI_ThsizEbV2N3gZKz7XCtuT4F/view?usp=sharing",
    title: "POS (android)",
    delayAnimation: "300",
    target: "_blank",
  },
  {
    icon: <RiGitRepositoryLine />,
    link: "https://drive.google.com/file/d/1lqArSRY1_5MVwTfNUeNw40IyXoN9UCbw/view?usp=sharing",
    title: "POS (windows)",
    delayAnimation: "300",
    target: "_blank",
  },
  {
    icon: <AiFillYoutube />,
    link: "https://www.youtube.com/@githubit1528/videos",
    title: "Website",
    delayAnimation: "400",
    target: "_blank",
  },
  {
    icon: <IoDocumentText />,
    link: "/demand24-documentation/introduction",
    title: "Documentation",
    delayAnimation: "600",
    target: "_blank",
  },
  {
    icon: <AiOutlineHistory />,
    link: "/demand24-documentation/update",
    title: "Update",
    delayAnimation: "600",
    target: "_blank",
  },
];


export default function Demand24MultiService() {
  return (
    <div className="ptf-site-wrapper animsition ptf-is--work-showcase-1">
      <Helmet>
        <title>Githubit - Demand24 Booking & E-Commerce</title>
      </Helmet>

      <HeaderDark />
      <div className="ptf-site-wrapper__inner">
        <div className="main">
          <article className="ptf-page ptf-page--single-work-1">
            <section>
              <OnePage
                skillContent={skillContent}
                buy_link={
                  "https://demand24.org"
                }
                descriptions="Demand24 is Laravel, Flutter & Next js Framework based online Commerce Full Solution.
                Which provide Admin, Seller Web panel, Website & Flutter (Customer, Delivery Boy, Seller) Mobile App"
                subTitle="Multivendor Booking & digital product, eCommerce"
                title="Demand24"
                img_url="19.png"
              />
            </section>

            <section>
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "10rem", "--ptf-md": "3.125rem" }}
              />

              <KeyFeatures
                text={
                  "Welcome to the world of boundless demand24 and comfort! Here, in our unique service for booking masters, we offer you not just services – we create unforgettable experiences."
                }
                description={"Imagine: you wake up in the morning, radiating freshness and readiness for new adventures, knowing that today you have a meeting with your personal demand24 wizard. Whether you need an urgent makeup for an important event, a perfect hairstyle for a date with your loved one, or perhaps you dream of a unique look for your wedding – our makeup artists will bring your dreams to life with ease and talent.\n" +
                    "\n" +
                    "And maybe your home requires special attention and coziness? Our cleaners are real magicians, capable of turning even the most neglected space into an oasis of cleanliness and order. Imagine returning home after a hectic day to find perfectly clean and cozy rooms – as if stepping into a cloud of harmony.\n" +
                    "\n" +
                    "And for those occasions when your hair or nails require special care, we offer the services of our professional hairdressers and nail technicians. They don't just look after your demand24 – they create art on your hair and nails, making you true stars.\n" +
                    "\n" +
                    "And that's not all! Our plumbers and locksmiths are ready to help at any moment, to fix any malfunction and make your life more comfortable.\n" +
                    "\n" +
                    "So don't hesitate, trust us with the care of your demand24 and comfort – and you will gain not only the perfect look but also confidence in yourself, knowing that real professionals are always by your side, ready to turn your dreams into reality. Welcome to the world of demand24 and comfort – welcome to our master booking service!\n" +
                    "\n" +
                    "Demand24 is not just a service, but a concern for your demand24 and comfort.\n" +
                    "\n" +
                    "And all this is available in - Demand24"}
                features={demand24}
              />

              <PaymentList data={payments} />

              <div className="container-xxl">
                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <p className="fz-20 has-black-color">
                    Welcome to Demand24: Booking, Electronics Ordering and Delivery
                    Marketplace.
                  </p>
                  <p className="fz-20 has-black-color">
                    Wandering what would you get when you purchase Demand24?
                    Please read the text and see our visuals below:
                  </p>
                  <p className="fz-20 has-black-color">
                    <strong>All-in-one:</strong> <br />
                    <BiRadioCircle size={10} /> Website; (
                    <a href="https://demand24.org/">https://demand24.org/</a> )
                    <br />
                    <BiRadioCircle size={10} /> Mobile Customer apps (Android
                    and iOS); <br />
                    <BiRadioCircle size={10} /> Admin Panel (web); <br />
                    <BiRadioCircle size={10} /> Seller Panel (web). <br />A
                    Vendor app and delivery app is available but sold
                    separately.
                  </p>
                  <p className="fz-20 has-black-color">
                    <strong>SEO and lighting fast page loading times: </strong>
                    If you already have or strive your Booking & E-commerce to expand
                    beyond your neighborhood, you will need a solution that
                    provides an excellent page load time, search engine
                    optimization (SEO) and high-throughput readiness . Demand24
                    uses various technologies to offer these key features.
                    Specifically, we built the front-end (website) with Nextjs
                    to maximize page loading time and SEO. Our database is
                    optimized for high throughput (no impact on performance in a
                    live project with over 100,000 unique products).
                  </p>
                  <p className="fz-20 has-black-color">
                    <strong>Multi-vendor options</strong>
                  </p>
                  <p className="fz-20 has-black-color">
                    <strong>A growing list of features:</strong> <br />
                    <BiRadioCircle size={10} /> Top-notch UI design; <br />
                    <BiRadioCircle size={10} /> Cashbacks; <br />
                    {/*<BiRadioCircle size={10} /> Referral; <br />*/}
                    <BiRadioCircle size={10} /> An extensive list of reports.
                    <br />
                    <BiRadioCircle size={10} /> Story; <br />
                    <BiRadioCircle size={10} /> Subscription (as part of an
                    extended license);
                    <br />
                    <BiRadioCircle size={10} /> Multiple countries selection for
                    deliveries and points.
                  </p>
                  <p className="fz-20 has-black-color">
                    <strong>Versatile: </strong> <br />
                    designed with a range of department stores in mind (Product,Digital.)
                  </p>
                  <p className="fz-20 has-black-color">
                    <strong className="fas fa-band-aid">
                      Core technologies:
                    </strong>
                    <br />
                    <BiRadioCircle size={10} /> Laravel (backend) <br />
                    <BiRadioCircle size={10} /> Flutter (mobile apps)
                    <br />
                    <BiRadioCircle size={10} /> Reactjs (admin panels)
                    <br />
                    <BiRadioCircle size={10} /> Typescript and Nextjs (website)
                    <br />
                    <BiRadioCircle size={10} /> Rest API
                  </p>
                </div>

                <div className="mt-4" />

                <div
                  className="ptf-divider"
                  style={{
                    "--ptf-height": "1px",
                    "--ptf-color": "var(--ptf-color-14)",
                  }}
                />

                <div className="mt-5" />

                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <h2>All demos</h2>
                </div>

                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "2.8125rem" }}
                />

                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <ImageGridDemo list={imageList} />
                </div>

                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "2.8125rem" }}
                />
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "2.8125rem" }}
                />
              </div>
            </section>
          </article>
        </div>
      </div>

      <footer className="ptf-footer ptf-footer--style-5">
        <div className="container-xxl">
          <div
            className="ptf-divider"
            style={{
              "--ptf-height": "1px",
              "--ptf-color": "var(--border-color)",
            }}
          />
          <div className="ptf-footer__top">
            <FooterThreeDark />
          </div>

          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
  );
}
