import axios from "axios";
import { toast } from "react-toastify";

const request = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 8000,
});

const TOKEN_PAYLOAD_KEY = "authorization";
const AUTH_TOKEN = "token";
const AUTH_TOKEN_TYPE = "Bearer";

request.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(AUTH_TOKEN);

    if (token) {
      config.headers[TOKEN_PAYLOAD_KEY] = AUTH_TOKEN_TYPE + " " + token;
    }
    return config;
  },
  (error) => errorHandler(error)
);

const errorHandler = (error) => {
  if (error?.response) {
    if (error.response?.data?.message) {
      toast.error(error.response.data.message);
    }

    if (error?.response?.status === 403) {
    } else if (error?.response?.status === 401) {
      // store.dispatch(logout())
    }
  }

  return Promise.reject(error.response);
};

request.interceptors.response.use((response) => response.data, errorHandler);

export default request;
