import axios from "axios";
import { toast } from "react-toastify";

const authRequest = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 8000,
});

const errorHandler = (error) => {
  if (error?.response) {
    if (error.response?.data?.message) {
      toast.error(error.response.data.message);
    }

    if (error?.response?.status === 403) {
    } else if (error?.response?.status === 401) {
      //   store.dispatch(logout())
    }
  }

  return Promise.reject(error.response);
};

authRequest.interceptors.response.use(
  (response) => response.data,
  errorHandler
);

export default authRequest;
