import React, { useEffect, useState } from "react";
import moment from "moment";
import ChatDate from "../../../../components/Chat-data";
import DeliveryBoy from "../../../../components/Chat-delivety";
import UserMessage from "../../../../components/Chat-user";
import MessageInput from "../../../../components/Chat-message";
import "../../../../assets/scss/components/chat.scss";
import { useParams } from "react-router-dom";
import ticketService from "../../../../service/TicketService";
import Loader from "../../../../components/Loader";

const TicketsShowChat = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [groupMessages, setGroupMessages] = useState([]);

  function getMessages(ticket) {
    const { chats } = ticket;
    if (!chats.length) return [];
    const groups = chats.reduce((groups, item) => {
      const date = moment(new Date(item.created_at)).format("DD-MM-YYYY");
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(item);
      return groups;
    }, {});
    const groupArrays = Object.keys(groups).map((date) => {
      return {
        date,
        messages: groups[date],
      };
    });
    return groupArrays;
  }

  const fetchTickets = () => {
    setLoading(true);
    ticketService
      .showTickets(id)
      .then((res) => {
        setData(res.data);
        setGroupMessages(getMessages(res.data));
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchTickets();
  }, []);

  return (
    <div>
      {!loading ? (
        <div>
          <div className="chat-card">
            <div className="ticket-info">
              Subject: <b>{data.subject}</b> <br />
              Message: <b>{data.message}</b> <br />
              Ticket status: <b>{data.status}</b> <br />
              Posted:{" "}
              <b>{moment(data.created_at).format("MMMM Do YYYY, h:mm")}</b>
              <br />
              Attachments:{" "}
              {data.galleries?.map((item) => (
                <a
                  key={item.id}
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  href={process.env.REACT_APP_BASE_URL + item.path}
                  className="text-decoration-underline"
                  style={{ marginRight: 8 }}
                >
                  {item.title}
                </a>
              ))}
            </div>
          </div>
          <div className="chat-box">
            {groupMessages.map((group, idx) => (
              <div key={"group-" + idx}>
                <ChatDate date={group.date} />
                {group.messages.map((item, index) => (
                  <div className="sms-box" key={index}>
                    {item.message_owner === "manager" ? (
                      <DeliveryBoy
                        text={item.message}
                        data={item.created_at}
                        type={item.manager?.name}
                      />
                    ) : (
                      <UserMessage
                        text={item.message}
                        data={item.created_at}
                        type={data.client?.name}
                        images={item.galleries}
                      />
                    )}
                  </div>
                ))}
              </div>
            ))}
          </div>
          <MessageInput id={data.id} fetchTickets={fetchTickets} />
        </div>
      ) : (
        <div className="text-center">
          <Loader />
        </div>
      )}
    </div>
  );
};

export default TicketsShowChat;
