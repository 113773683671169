import {
  IoAlarmOutline, IoCalendarOutline, IoColorWandOutline, IoCutOutline, IoGiftOutline, IoHeartOutline,
  IoIdCardOutline, IoNewspaperOutline, IoNotificationsOutline, IoTodayOutline
} from "react-icons/io5";
import {GiRingmaster} from "react-icons/gi";
import {uzmart} from "./Foodyman-feature";

export const demand24 = [
  {
    tag: "Bookings",
    title: "Bookings",
    subTitle: "Book masters online/offline in/offline out",
    icon: <IoIdCardOutline />,
    color: "#e6e6ff",
    bgColor: "#000099",
    delayAnimation: "600",
  },
  {
    tag: "services",
    title: "Services",
    subTitle: "The master can create as many services for himself as he wants",
    icon: <IoCutOutline />,
    color: "#e6e6ff",
    bgColor: "#000099",
    delayAnimation: "600",
  },
  {
    tag: "service-extras",
    title: "Service extra options",
    subTitle: "Add under services to attract customers and get more profit",
    icon: <IoColorWandOutline />,
    color: "#e6e6ff",
    bgColor: "#000099",
    delayAnimation: "600",
  },
  {
    tag: "masters",
    title: "Masters",
    subTitle: "Create/Invite masters for your shop",
    icon: <GiRingmaster />,
    color: "#e6e6ff",
    bgColor: "#000099",
    delayAnimation: "600",
  },
  {
    tag: "master-closed-dates",
    title: "Master closed dates",
    subTitle: "The master can add non-working days",
    icon: <IoTodayOutline />,
    color: "#e6e6ff",
    bgColor: "#000099",
    delayAnimation: "600",
  },
  {
    tag: "master-disabled-dates",
    title: "Master disabled dates",
    subTitle: "The master can dynamically block time from/to either by day or by week or by month",
    icon: <IoCalendarOutline />,
    color: "#e6e6ff",
    bgColor: "#000099",
    delayAnimation: "600",
  },
  {
    tag: "master-working-days",
    title: "Master working days",
    subTitle: "The master can indicate his working hours under the days",
    icon: <IoAlarmOutline />,
    color: "#e6e6ff",
    bgColor: "#000099",
    delayAnimation: "600",
  },
  {
    tag: "form-options",
    title: "Form options",
    subTitle: "Every master can create custom form options",
    icon: <IoNewspaperOutline />,
    color: "#e6e6ff",
    bgColor: "#000099",
    delayAnimation: "600",
  },
  {
    tag: "gift-carts",
    title: "Gift carts",
    subTitle: "Add gift cards so that clients can give them to their loved ones",
    icon: <IoGiftOutline />,
    color: "#e6e6ff",
    bgColor: "#000099",
    delayAnimation: "600",
  },
  {
    tag: "memberships",
    title: "Memberships",
    subTitle: "Add memberships to attract clients to repeat sessions",
    icon: <IoHeartOutline />,
    color: "#e6e6ff",
    bgColor: "#000099",
    delayAnimation: "600",
  },
  {
    tag: "service-master-notifications",
    title: "Master notifications by every service",
    subTitle: "Masters can add notifications for their services to notify clients that it is time for a second session",
    icon: <IoNotificationsOutline />,
    color: "#e6e6ff",
    bgColor: "#000099",
    delayAnimation: "600",
  }
].concat(uzmart)
