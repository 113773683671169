import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

const Videos = () => {
  return (
    <div
      className="introduction "
      data-aos="fade-right"
      data-aos-delay="300"
      data-aos-duration="1000"
    >
      <h1 className="title"> How to installation Videos</h1>
      <iframe width="560" height="315" src="https://www.youtube.com/embed/NKnxSUWjCrE?si=KPOZ24tHlYECVS75"
              title="YouTube video player" frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
      <br/>
      <iframe width="560" height="315" src="https://www.youtube.com/embed/IwuCFS2X0gc?si=KPOZ24tHlYECVS75"
              title="YouTube video player" frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
      <br/>
      <iframe width="560" height="315" src="https://www.youtube.com/embed/nQnfv3A5y4o?si=KPOZ24tHlYECVS75"
              title="YouTube video player" frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
      <br/>
      <iframe width="560" height="315" src="https://www.youtube.com/embed/8OqbYt9lZZQ?si=KPOZ24tHlYECVS75"
              title="YouTube video player" frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
      <br/>
    </div>
  );
};

export default Videos;
