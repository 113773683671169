import React from "react";
import { Link } from "react-router-dom";

const MandatorySetup = () => {
  return (
    <div
      className="introduction "
      data-aos="fade-right"
      data-aos-delay="300"
      data-aos-duration="1000"
    >
      <h1 className="title"> Mandatory setup</h1>
      <h5 className="introduction-contentTitle"> Map Configuration</h5>A client
      should get <strong className="strong">Map API</strong> from Google in
      order to enable the maps into the panels.
      <h5 className="introduction-contentTitle">
        {" "}
        Firebase Configuration (for notification)
      </h5>
      The Firebase Push Notification will send messages for every order status.
      If admin turn on the status, customers, store, delivery man will get
      status notification when order status changed and if he turned off that
      then no one will get that message. To set up firebase notification go to
      admin panel’s <strong className="strong">
        Notification settings
      </strong>{" "}
      menu.
      <h5 className="introduction-contentTitle"> Payment Configuration</h5>
      Here, Admin will be introduced with the payment gateways. Cash on
      delivery, Digital Payment like Razor pay, Paypal, Stripe, Paystack,
      MercadoPago, Payment accept are available for payment gateways. Admin can
      make the necessary setup to make the status active or inactive for
      mentioned payment gateways.
      <h5 className="introduction-contentTitle"> SMS Module Configuration</h5>
      SMS Module is utilized for SMS Gateways for OTP sending in the simplest
      way of user verification. Customer will receive OTP when they create their
      own account or it is used for password recovery.
      <h5 className="introduction-contentTitle"> Firebase configuration</h5>
      <span className="youtube-blog">
        <iframe
          src="https://www.youtube.com/embed/OLwNp_e5bxM"
          title="YouTube video player"
          frameBorder="11"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        />
      </span>
      <h5 className="introduction-contentTitle">
        {" "}
        Firebase auth configuration
      </h5>
      <span className="youtube-blog">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/5HzrGiY9cFo"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </span>
      <h5 className="introduction-contentTitle">
        {" "}
        Firebase chat configuration
      </h5>
      <span className="youtube-blog">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/29ARDVIXvXk"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </span>
      <h5 className="introduction-contentTitle">
        {" "}
        Firebase notification configuration
      </h5>
      <span className="youtube-blog">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/3E-kEe5X2bg"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </span>
      <h5 className="introduction-contentTitle">
        {" "}
        How to connect firebase to project
      </h5>
      <span className="youtube-blog">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/jCgZZiz1480"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </span>
      <div className="center-page-container">
        <Link
          to="/qrcode-documentation/install-on-server"
          className="btn  previous"
        >
          <p>Previous</p>
          <p className="link"> Install on server </p>
        </Link>
        <Link to="/qrcode-documentation/customization" className="btn  next">
          <p>Next</p>
          <p className="link"> Customization</p>
        </Link>
      </div>
    </div>
  );
};

export default MandatorySetup;
