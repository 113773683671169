export const portfolioContent = [
  {
    tabContent: [
      {
        img: "app-banner.png",
        title: "Goshops - Customer app",
        description: "Mobile and IOS development",
        link: "/goshop-multi-shop",
      },
      {
        img: "app-banner.png",
        title: "Goshops - Delivery App",
        description: "Mobile and IOS development",
        link: "/goshop-multi-shop",
      },
      {
        img: "app-banner.png",
        title: "Goshops - Customer App",
        description: "Mobile and IOS development",
        link: "/goshop-multi-shop",
      },
      {
        img: "sunday.jpg",
        title: "Sundaymart Customer apps",
        description: "Mobile and IOS development",
        link: "/sundaymart-multi-vendor",
      },
      {
        img: "customer.png",
        title: "Foodyman - Restaurant and Grocery Vendor App (iOS&Android)",
        description: "Mobile and IOS development",
        link: "/foodyman-multi-service",
      },
      {
        img: "customer.png",
        title: "Foodyman - Restaurant and Grocery Delivery App (iOS&Android)",
        description: "Mobile and IOS development",
        link: "/foodyman-multi-service",
      },
      {
        img: "customer.png",
        title: "Foodyman - Restaurant and Grocery Customer App (iOS&Android)",
        description: "Mobile and IOS development",
        link: "/foodyman-multi-service",
      },
      {
        img: "damda.jpg",
        title: "Damda landlord",
        description: "Mobile development",
        link: "/damda-landlord",
      },
      {
        img: "16.jpg",
        title: "Damda - Damda Sayyoh",
        description: "Mobile development",
        link: "/damda-app",
      },
    ],
  },
  {
    tabContent: [
      {
        img: "app-banner.png",
        title: "Goshops - Customer app",
        description: "Mobile and IOS development",
        link: "/goshop-multi-shop",
      },
      {
        img: "app-banner.png",
        title: "Goshops - Delivery App",
        description: "Mobile and IOS development",
        link: "/goshop-multi-shop",
      },
      {
        img: "app-banner.png",
        title: "Goshops - Customer App",
        description: "Mobile and IOS development",
        link: "/goshop-multi-shop",
      },
    ],
  },
  {
    tabContent: [
      {
        img: "sunday.jpg",
        title: "Sundaymart Customer apps",
        description: "Mobile and IOS development",
        link: "/sundaymart-multi-vendor",
      },
    ],
  },
  {
    tabContent: [
      {
        img: "customer.png",
        title: "Foodyman - Restaurant and Grocery Vendor App (iOS&Android)",
        description: "Mobile and IOS development",
        link: "/foodyman-multi-service",
      },
      {
        img: "customer.png",
        title: "Foodyman - Restaurant and Grocery Delivery App (iOS&Android)",
        description: "Mobile and IOS development",
        link: "/foodyman-multi-service",
      },
      {
        img: "customer.png",
        title: "Foodyman - Restaurant and Grocery Customer App (iOS&Android)",
        description: "Mobile and IOS development",
        link: "/foodyman-multi-service",
      },
    ],
  },
  {
    tabContent: [
      {
        img: "damda.jpg",
        title: "Damda landlord",
        description: "Mobile development",
        link: "/damda-landlord",
      },
      {
        img: "16.jpg",
        title: "Damda - Damda Sayyoh",
        description: "Mobile development",
        link: "/damda-app",
      },
    ],
  },
];
