import React, { useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { GiCampfire } from "react-icons/gi";
import { FcIdea } from "react-icons/fc";
import { Link } from "react-router-dom";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

const InstallOnServer = () => {
  const [text, setText] = useState(null);

  const copyToClipBoard = async (copyMe, id) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setText(id);
    } catch (err) {
      setText("Failed to copy!");
    }
  };
  //
  return (
    <div
      className="introduction "
      data-aos="fade-right"
      data-aos-delay="300"
      data-aos-duration="1000"
    >
      <h1 className="title"> Install on server</h1>
      <div className="alert alert-warning mt-3" role="alert">
        <div className="mb-2">
          <AiOutlineInfoCircle size={22} /> <strong>Warning</strong>
        </div>
        You have to set SSL certificate for your admin website. Some functions
        doesn't work if your website doesn't have SSL certificate.
      </div>
      <div className="alert alert-primary mt-3" role="alert">
        <div className="mb-2">
          <AiOutlineInfoCircle size={22} /> <strong>INFO</strong>
        </div>
        Our admin panel consists of two parts: front and backend. Thus to
        install admin panel, we need two subdomains like admin.xyz.com and
        api.xyz.com.
      </div>
      • Download the code from codecayon <br />
      • Extract the zip files
      <br />
      • Upload the admin_front and admin_backend folders to your server <br />
      <h3 className="introduction-contentTitle"> Admin Panel front </h3>
      • Open /public/index.html and change google map key with your google map
      key
      <br />
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a
              href="/assets/img/doc/index-map-key.png"
              data-fancybox
              rel="nofollow"
            >
              <img
                src="/assets/img/doc/index-map-key.png"
                alt="admin config"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      • Open /src/configs/app_global.js and change BASE_URL with your server url
      <br />
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a
              href="/assets/img/doc/adminConfig.png"
              data-fancybox
              rel="nofollow"
            >
              <img
                src="/assets/img/doc/adminConfig.png"
                alt="admin config"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a
              href="/assets/img/doc/configFirebase.png"
              data-fancybox
              rel="nofollow"
            >
              <img
                src="/assets/img/doc/configFirebase.png"
                alt="admin firebase"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      • Also select the main language
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a href="/assets/img/doc/theme.png" data-fancybox rel="nofollow">
              <img
                src="/assets/img/doc/theme.png"
                alt="theme config"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      • Build frontend using following command <br />
      <div className="introduction-code">
        <div>
          <span className="ps-0">yarn build</span>
          <span
            className={text === 1 ? "bg-success copy" : "copy"}
            onClick={() => copyToClipBoard("yarn build", 1)}
          >
            {text === 1 ? "copied!" : "copy"}
          </span>
        </div>
      </div>
      or
      <div className="introduction-code">
        <div>
          <span className="ps-0">npm run build</span>
          <span
            className={text === 2 ? "bg-success copy" : "copy"}
            onClick={() => copyToClipBoard("npm run build", 2)}
          >
            {text === 2 ? "copied!" : "copy"}
          </span>
        </div>
      </div>
      <div className="alert alert-danger mt-4" role="alert">
        <div className="mb-2">
          <GiCampfire size={22} /> <strong> Note</strong>
        </div>
        Set <strong>/build</strong> directory as root directory and set
        <strong> /build/index.html</strong> as a root file
      </div>
      <h3 className="introduction-contentTitle"> Admin Panel backend </h3>
      • Make sure that your system has installed composer <br />
      <div className="alert alert-success mt-4" role="alert">
        <div className="mb-2">
          <FcIdea size={22} /> <strong> TIP</strong>
        </div>
        If composer isn't installed, follow instructions:
        <a
          href="https://www.hostinger.com/tutorials/how-to-install-composer"
          className="ms-2"
        >
          https://www.hostinger.com/tutorials/how-to-install-composer
        </a>
      </div>
      <p> • If composer is installed, run following command</p>
      <div className="introduction-code">
        <div>
          <span className="ps-0">composer install</span>
          <span
            className={text === 3 ? "bg-success copy" : "copy"}
            onClick={() => copyToClipBoard("composer install", 3)}
          >
            {text === 3 ? "copied!" : "copy"}
          </span>
        </div>
      </div>
      <div className="alert alert-danger mt-3" role="alert">
        <div className="mb-2">
          <GiCampfire size={22} /> <strong>WARNING</strong>
        </div>
        Do not install the admin panel or web app in a sub directory ( Like:
        ourdomain.com/folder ) <br />
      </div>
      <br />
      <h3 className="introduction-title"> Admin Panel Installation</h3>
      <div className="step-one">
        <span className="introduction-step-1">Step 1:</span>
        Goshops requires an SSL certificate to be installed on your domain in
        order to function with all the services.
        <br />
        You can easily install free SSL certificate on your domain on a website
        <span className="introduction-step">sslforfree.com</span>. <br />
        Create the database appropriately. Create a new database from your
        server MYSQL database. Create a DB user to the database and then link
        that database to the DB user. <br />
      </div>
      <div className="mb-3 mt-3">
        <span className="introduction-step-1">Step 2:</span>
        When you click your site URL it will automatically take you to the
        installation. You will get the checking required information and if
        everything is fine, then click on next step. <br />
      </div>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a
              href="/assets/img/doc/admin-install-1.png"
              data-fancybox
              rel="nofollow"
            >
              <img
                src="/assets/img/doc/admin-install-1.png"
                alt="admin install"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <div className="mb-3 mt-3">
        <span className="introduction-step-1">Step 3:</span>
        Please, install required version of programs. <br />
      </div>
      <div className="mb-3 mt-3">
        <span className="introduction-step-1">Step 4:</span>
        Enter your codecanyon username and purchase code and then click continue
        button. <br />
      </div>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a
              href="/assets/img/doc/admin-install-3.png"
              data-fancybox
              rel="nofollow"
            >
              <img
                src="/assets/img/doc/admin-install-3.png"
                alt="install 3"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <div className="mb-3 mt-3">
        <span className="introduction-step-1">Step 5:</span>
        Set Database name, Database username, Database password and click
        continue button.
        <br />
      </div>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a
              href="/assets/img/doc/admin-install-2.png"
              data-fancybox
              rel="nofollow"
            >
              <img
                src="/assets/img/doc/admin-install-2.png"
                alt="install 2"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <div className="mb-3 mt-3">
        <span className="introduction-step-1">Step 6:</span>
        Click on Import Database button in order to import the SQL file. <br />
      </div>
      <div className="mb-3 mt-3">
        <span className="introduction-step-1">Step 7:</span>
        Next step you fill up the information and click on continue button.{" "}
        <br />
      </div>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a
              href="/assets/img/doc/admin-install-4.png"
              data-fancybox
              rel="nofollow"
            >
              <img
                src="/assets/img/doc/admin-install-4.png"
                alt="install 4"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <div className="mb-2 mt-2">
        <span className="introduction-step-1">Step 8:</span>
        Great job! Now your software is ready to run. Click on the admin panel
        or view landing page. <br />
      </div>
      <div className="mt-4" />
      <div className="iframe-wrapper">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/_cY1QbtYYYc"
          title="How to run admin panel on MacOS"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
      <div className="center-page-container">
        <Link to="/goshops-documentation/admin" className="btn  previous">
          <p>Previous</p>
          <p className="link"> Requirements </p>
        </Link>
        <Link
          to="/goshops-documentation/troubleshooting-admin"
          className="btn  next"
        >
          <p>Next</p>
          <p className="link"> Troubleshooting admin </p>
        </Link>
      </div>
    </div>
  );
};

export default InstallOnServer;
