import React, { useState } from "react";
import { useFormik } from "formik";
import contactService from "../service/ContactService";
import { toast } from "react-toastify";
import LoadingIndicator from "./Loader/loadingIndicator";
import { ArrowTopRight } from "./Svg/goTop-svg";
import UploadButton from "./UploadButton";
import { MdDeleteForever } from "react-icons/md";
import ticketService from "../service/TicketService";

const JobForm = () => {
  const [image, setImage] = useState([]);
  const [loadingBtn, setLoadingBtn] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      company_name: "",
      phone: "",
      project_goals: "",
    },
    onSubmit: (values, { setSubmitting }) => {
      contactService
        .create(values)
        .then(() => toast.success("Your request has been sent successfully"))
        .finally(() => setSubmitting(false));
    },
    validate: (values) => {
      const errors = {};
      if (!values.name) {
        errors.name = "Required";
      }
      if (!values.email) {
        errors.email = "Required";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
      ) {
        errors.email = "Invalid email address";
      }
      if (!values.project_goals) {
        errors.project_goals = "Required";
      }
      return errors;
    },
  });

  const handleChange = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("type", "service");
    setLoadingBtn(true);
    ticketService
      .fileUpload(formData)
      .then((res) => setImage([...image, res.data.title]))
      .finally(() => setLoadingBtn(false));
  };

  const handleDelete = (index) => {
    image.splice(index, 1);
  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit} className="contact_form">
        <div className="ptf-form-group">
          <label data-number="01">What’s your name?</label>
          <input
            type="text"
            name="name"
            className={
              formik.errors?.name && formik.touched?.name ? "error" : ""
            }
            value={formik.values.name}
            onChange={formik.handleChange}
            autoComplete="off"
          />
        </div>

        <div className="ptf-form-group">
          <label data-number="02">What’s your profession</label>
          <input
            type="text"
            name="company_name"
            className={
              formik.errors?.company_name && formik.touched?.company_name
                ? "error"
                : ""
            }
            value={formik.values.company_name}
            onChange={formik.handleChange}
            autoComplete="off"
          />
        </div>

        <div className="ptf-form-group">
          <label data-number="03">how many years have you worked ?</label>
          <input
            type="number"
            name="years"
            className={
              formik.errors?.years && formik.touched?.years ? "years" : ""
            }
            value={formik.values.years}
            onChange={formik.handleChange}
            autoComplete="off"
          />
        </div>

        <div className="ptf-form-group">
          <label data-number="04">What’s your phone number?</label>
          <input
            type="number"
            name="phone"
            className={
              formik.errors?.phone && formik.touched?.phone ? "error" : ""
            }
            value={formik.values.phone}
            onChange={formik.handleChange}
            autoComplete="off"
          />
        </div>

        <div className="ptf-form-group">
          <label data-number="05">Tell us about your project.</label>
          <textarea
            name="project_goals"
            className={
              formik.errors?.project_goals && formik.touched?.project_goals
                ? "error"
                : ""
            }
            value={formik.values.project_goals}
            onChange={formik.handleChange}
            autoComplete="off"
          />
        </div>

        <div className="ptf-form-group">
          <label data-number="06">LinkedIn Profile</label>
          <input
            type="text"
            name="link"
            className={
              formik.errors?.link && formik.touched?.link ? "error" : ""
            }
            value={formik.values.link}
            onChange={formik.handleChange}
            autoComplete="off"
          />
        </div>

        <div className="upload-group">
          <label data-number="07">Resume</label>
          <label htmlFor="file" className="fileInput">
            <div className="uploadButton">
              <input
                hidden
                id="file"
                type="file"
                accept=".pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                onChange={handleChange}
              />
              <UploadButton loading={loadingBtn} />
            </div>
            {image?.map((file, index) => (
              <div key={index} className="imageContainer">
                <img
                  src={process.env.REACT_APP_BASE_URL + file}
                  alt="images"
                  className="images"
                />
                <button onClick={() => handleDelete(index)} className="delete">
                  <MdDeleteForever className="deleteIcon" color={"white"} />
                </button>
              </div>
            ))}
          </label>
        </div>

        <div className="ptf-spacer" style={{ "--ptf-xxl": "5.625rem" }} />

        <button
          className="ptf-submit-button"
          type="submit"
          disabled={formik.isSubmitting}
        >
          <span>Submit</span>
          {formik.isSubmitting ? <LoadingIndicator /> : <ArrowTopRight />}
        </button>
      </form>
    </div>
  );
};

export default JobForm;
