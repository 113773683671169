import React from "react";
import { Link } from "react-router-dom";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

const Troubleshooting = () => {
  return (
    <div
      className="introduction "
      data-aos="fade-right"
      data-aos-delay="300"
      data-aos-duration="1000"
    >
      <h1 className="title">Troubleshooting</h1>

      <div className="accordion" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              A404 error in Laravel routing
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>
                if you meet 404 error when you open an exist page in the
                backend, use following web
                <a
                  style={{ color: "blue" }}
                  href="https://stackoverflow.com/questions/36303706/url-was-not-found-on-this-server-laravel"
                >
                  <strong> resource to solve this</strong>
                </a>
              </p>
              <div className="introduction-img-container mb-5">
                <SimpleReactLightbox>
                  <SRLWrapper>
                    <a
                      href="/assets/img/githubit2.jpg"
                      data-fancybox
                      rel="nofollow"
                    >
                      <img
                        style={{ height: "50vh", width: "100%" }}
                        src="/assets/img/githubit2.jpg"
                        alt="admin install"
                        loading="lazy"
                        className="img-responsive-full"
                      />
                    </a>
                  </SRLWrapper>
                </SimpleReactLightbox>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingTwo">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              Switch off demo mode
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <strong>
                delete .env file in admin_frontend folder to disable demo mode
              </strong>
              <div className="introduction-img-container mb-5">
                <SimpleReactLightbox>
                  <SRLWrapper>
                    <a
                      href="/assets/img/githubit3.jpg"
                      data-fancybox
                      rel="nofollow"
                    >
                      <img
                        style={{ height: "50vh", width: "100%" }}
                        src="/assets/img/githubit3.jpg"
                        alt="admin install"
                        loading="lazy"
                        className="img-responsive-full"
                      />
                    </a>
                  </SRLWrapper>
                </SimpleReactLightbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Troubleshooting;
