import { Link } from "react-router-dom";
import { foodymanDocMenu } from "../../fake-data/Foody-doc-menu";

const allMenus = foodymanDocMenu.flatMap((menu) => {
  if (menu.routerPath) return menu;
  return menu.dropDownItems;
});


function NavigationBtns() {
  const windowPath = window.location.pathname;
  const currentMenuIdx = allMenus.findIndex(
    (menu) => menu.routerPath === windowPath
  );
  if (currentMenuIdx === -1) return null;

  const currentMenus = (() => {
    if (currentMenuIdx === 0) return [null, allMenus[1]];
    if (currentMenuIdx === allMenus.length - 1)
      return [allMenus[allMenus.length - 2], null];

    return [allMenus[currentMenuIdx - 1], allMenus[currentMenuIdx + 1]];
  })();

  return (
    <div className="center-page-container">
      {currentMenus.map(
        (menu, idx) =>
          menu && (
            <Link to={menu.routerPath} className="btn  previous">
              <p>{idx === 0 ? "Previos" : "Next"}</p>
              <p className="link"> {menu.name} </p>
            </Link>
          )
      )}
    </div>
  );
}

export default NavigationBtns;
