import React from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import InputPassword from "../../../../components/Input/PasswordInput";
import authService from "../../../../service/AuthService";
import { useDispatch } from "react-redux";
import { setUserData } from "../../../../redux/slices/auth";
import LoadingIndicator from "../../../../components/Loader/loadingIndicator";
import { ArrowTopRight } from "../../../../components/Svg/goTop-svg";
import GoogleSignIn from "../GoogleSignIn";
import EnvatoSignIn from "../EnvatoSingIn";

const RegistrationDetailsForm = ({ verifyEmail }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      name: "",
      password: "",
    },
    onSubmit: (values, { setSubmitting }) => {
      const body = {
        name: values.name,
        password: values.password,
        email: verifyEmail,
      };
      authService
        .registrationForm(body)
        .then((res) => {
          const token = res.data.access_token;
          localStorage.setItem("token", token);
          dispatch(
            setUserData({ ...res.data, ...res.data.user, user: undefined })
          );
          navigate("/");
        })
        .finally(() => setSubmitting(false));
    },
    validate: (values) => {
      const errors = {};
      if (!values.name) {
        errors.name = "Required name";
      }
      if (!values.password) {
        errors.password = "Required password";
      } else if (values.password.length < 6) {
        errors.password = "password must be at least 6 characters";
      }
      if (!values.password_confirm) {
        errors.password_confirm = "Required password confirm";
      } else if (values.password !== values.password_confirm) {
        errors.password_confirm = "ForgotPassword doesn't match";
      }

      return errors;
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="ptf-form-group">
        <label data-number="01">What’s your Name?</label>
        <input
          name="name"
          type="text"
          className={formik.errors?.name && formik.touched?.name ? "error" : ""}
          value={formik.values.name}
          onChange={formik.handleChange}
          autoComplete="off"
        />
      </div>
      <div className="ptf-form-group">
        <label data-number="01">What’s your password?</label>
        <InputPassword
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          className={
            formik.errors?.password && formik.touched?.password ? "error" : ""
          }
        />
      </div>
      <div className="ptf-form-group">
        <label data-number="01">What’s your password confirm?</label>
        <InputPassword
          name="password_confirm"
          value={formik.values.password_confirm}
          onChange={formik.handleChange}
          className={
            formik.errors?.password_confirm && formik.touched?.password_confirm
              ? "error"
              : ""
          }
        />
      </div>
      <div className="login-footer">
        <button
          className="ptf-submit-button"
          type="submit"
          disabled={formik.isSubmitting}
        >
          <span>Submit</span>
          {formik.isSubmitting ? <LoadingIndicator /> : <ArrowTopRight />}
        </button>
        <div className="footer-social">
          <EnvatoSignIn />
          <GoogleSignIn />
        </div>
      </div>
    </form>
  );
};

export default RegistrationDetailsForm;
