import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

const Introduction = () => {
  return (
    <div
      className="introduction "
      data-aos="fade-right"
      data-aos-delay="300"
      data-aos-duration="1000"
    >
      <div className="alert alert-primary mb-5" role="alert">
        <div className="mb-2">
          <AiOutlineInfoCircle size={22} /> <strong>INFO</strong>
        </div>
        Keep /configs/credential.php and /configs/init.php file while updating.
        If you don’t keep them, you may have a problem with lisence.
      </div>

      <h1 className="title">Introduction</h1>

      <h3 className="introduction-title">Overview</h3>
      <strong className="introduction-subTitle">
        Welcome to - <strong className="strong">Qrcode.</strong>
      </strong>

      <div>
        Benefits of QR Menu <br /> No need for printed menus.
        <br /> QR Menu that showcases food images and videos to upsell your
        food. <br />
        Increased efficiency as the order and re-orders are placed without the
        staff approaching the guest. <br />
        Simple and easy interface similar to most popular delivery apps making
        it easy for the guest to use. <br />
        No download of any app required. When the guest scans the QR Code a link
        pops up on the browser.
        <br /> The guest is able to place their order and pay directly through
        the digital menu.
        <br />
        <div className="introduction-img-container">
          <SimpleReactLightbox>
            <SRLWrapper>
              <a
                href="/assets/img/project/qrcode-admin.png"
                data-fancybox
                rel="nofollow"
              >
                <img
                  src="/assets/img/project/qrcode-admin.png"
                  alt="admin"
                  loading="lazy"
                  className="img-responsive-full"
                />
              </a>
            </SRLWrapper>
          </SimpleReactLightbox>
        </div>
        <div className="line-break" />
        <h3 className="introduction-contentTitle">
          2 Apps developed using Flutter
        </h3>
        • Waiter application <br />
        • POS application <br />
        <h3 className="introduction-contentTitle">
          3 web panel developed using Laravel & React Js
        </h3>
        • Admin web panel
        <br />
        • Manager web panel <br />
        • Waiter web panel <br />
        <h3 className="introduction-contentTitle">
          1 web site developed using Javascript & Vite
        </h3>
        • Customer web site <br />
        <div className="line-break" />
      </div>

      <div className="next-page-container">
        <Link to="/qrcode-documentation/basic" className="btn  next">
          <p>Next</p>
          <p className="link"> Basic </p>
        </Link>
      </div>
    </div>
  );
};

export default Introduction;
