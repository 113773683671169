import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { GiCampfire } from "react-icons/gi";
import { Link } from "react-router-dom";

const Basic = () => {
  return (
    <div
      className="introduction "
      data-aos="fade-right"
      data-aos-delay="300"
      data-aos-duration="1000"
    >
      <h1 className="title">Basic</h1>
      As mentioned above, saas is a multi-shop/seller & single vendor
      marketplace, full solution ( apps, web, admin). To set up the project, you
      should have some programming knowledge and tool installed on your
      computer.
      <br />
      <h3 className="introduction-contentTitle">Tools</h3>
      • For Mobile development: Visual Studio Code or Android studio , SDK and
      JDK with path setup in your IDE <br />
      • For Backend development: Open Server Panel , XAMPP , Wamp Server ,
      PhpStorm <br />
      • For Frontend development: Nodejs , Visual Studio Code or WebStorm <br />
      <h3 className="introduction-contentTitle">Knowledge</h3>
      • For Mobile development: Dart , Flutter , basic Java and basic Swift
      knowledge <br />
      • For Backend development: PHP , MySQL , Laravel <br />• For Frontend
      development: Next Js , React Js, Google map , Firebase <br />
      <div className="alert alert-danger mt-4" role="alert">
        <div className="mb-2">
          <GiCampfire size={22} /> <strong>WARNING</strong>
        </div>
        We would like to notify that the Envato price doesn’t include any kind
        of installation and app publishing support. We kindly ask you to follow
        the documentation step by step for installation, setup and other
        branding related changes. Please note that, we bear no responsibility
        for your mistake. You are fully in charge for any kind of customizations
        made by your own.
      </div>
      <div className="alert alert-primary mt-3" role="alert">
        <div className="mb-2">
          <AiOutlineInfoCircle size={22} /> <strong>Note</strong>
        </div>
        <p className="h5">The terminal in your server should support nodejs</p>
      </div>
      <div className="center-page-container">
        <Link to="/saas-documentation/introduction" className="btn  previous">
          <p>Previous</p>
          <p className="link"> Introduction </p>
        </Link>
        <Link to="/saas-documentation/server" className="btn  next">
          <p>Next</p>
          <p className="link"> Backend </p>
        </Link>
      </div>
    </div>
  );
};

export default Basic;
