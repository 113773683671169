import React from "react";
import { FcIdea } from "react-icons/fc";
import { Link } from "react-router-dom";

const MobileApp = () => {
  return (
    <div
      className="introduction "
      data-aos="fade-right"
      data-aos-delay="300"
      data-aos-duration="1000"
    >
      <h1 className="title">Mobile app</h1>
      • Android studio <br />
      <div className="alert alert-success mt-4" role="alert">
        <div className="mb-2">
          <FcIdea size={22} /> <strong> TIP</strong>
        </div>
        You can download android studio via this link: <br />
        <a href="https://developer.android.com/studio?gclid=CjwKCAiAiKuOBhBQEiwAId_sK4X0PLQrES_2pG_S8nPflALgWSOCUEqRRAFpbS4AmR5mXmU6hIhvHxoCfBgQAvD_BwE&gclsrc=aw.ds">
          https://developer.android.com/studio?gclid=CjwKCAiAiKuOBhBQEiwAId_sK4X0PLQrES_2pG_S8nPflALgWSOCUEqRRAFpbS4AmR5mXmU6hIhvHxoCfBgQAvD_BwE&gclsrc=aw.ds
        </a>
      </div>
      • Flutter SDK setup (version 3.13.5{" "}
      <strong className="strong">Stable</strong>) <br />
      • JDK with path setup (only for vs code) <br />
      • Xcode for IPA file build <br />
      • State management -> bloc <br />
      <div className="center-page-container">
        <Link
          to="/uzmart-documentation/recommendations"
          className="btn  previous"
        >
          <p>Previous</p>
          <p className="link"> Recommendations </p>
        </Link>
        <Link to="/uzmart-documentation/flutter-sdk" className="btn  next">
          <p>Next</p>
          <p className="link"> Mobile App </p>
        </Link>
      </div>
    </div>
  );
};

export default MobileApp;
