import React from "react";
import { Helmet } from "react-helmet";
import HeaderDark from "../../components/Header/HeaderDark";
import FooterThreeDark from "../../components/Footer/FooterThreeDark";
import Portfolio from "../../components/Portfolio/Portfolio";
import ContactJarallax from "../../components/Jarallax/ContactJarallax";
import CopyRight from "../../components/Footer/Copyright/CopyRight";
import HeroModern from "../../components/Hero/HeroModern";
import { AiFillAndroid, AiOutlineGlobal } from "react-icons/ai";

const data = [
  {
    icon: <AiFillAndroid />,
    link: "/all-android-project",
    title: "Android demo",
  },
  {
    icon: <AiOutlineGlobal />,
    link: "/all-web-project",
    title: "Web demo",
  },
];

const HomeDark = () => {
  return (
    <div className="ptf-site-wrapper animsition ptf-is--home-dark ">
      <Helmet>
        <title>GitHubit</title>
      </Helmet>

      <HeaderDark />

      <div className="ptf-site-wrapper__inner">
        <div className="ptf-main">
          <div className="ptf-page ptf-page--home-dark" />

          <section>
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "4.375rem", "--ptf-md": "2.1875rem" }}
            />

            <div className="container-xxl">
              <HeroModern skillContent={data} />
            </div>

            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
            />
          </section>

          <section>
            <div className="container-xxl">
              <div
                className="ptf-divider"
                style={{
                  "--ptf-height": "1px",
                  "--ptf-color": "var(--text)",
                }}
              />
            </div>
          </section>

          <section className="ptf-custom--1674">
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
            />

            <div className="container-xxl">
              <div className="row">
                <div className="col-lg-12 has-dark-color">
                  <Portfolio />
                </div>
              </div>
            </div>

            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "10rem", "--ptf-md": "9rem" }}
            />
          </section>

          <section>
            <div className="container-xxl">
              <div
                className="ptf-divider"
                style={{
                  "--ptf-height": "1px",
                  "--ptf-color": "var(--text)",
                }}
              />
            </div>
          </section>

          <section>
            <div className="container-xxl">
              <div
                className="ptf-divider"
                style={{
                  "--ptf-height": "1px",
                  "--ptf-color": "var(--text)",
                }}
              />
            </div>
          </section>

          <section className="jarallax jarallax-img">
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": " 12.5rem", "--ptf-md": "6.25rem" }}
            />
            <ContactJarallax />
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
            />
          </section>
        </div>
      </div>

      <footer className="ptf-footer ptf-footer--style-5">
        <div className="container-xxl">
          <div
            className="ptf-divider"
            style={{
              "--ptf-height": "1px",
              "--ptf-color": "var(--border-color)",
            }}
          />
          <div className="ptf-footer__top">
            <FooterThreeDark />
          </div>

          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default HomeDark;
