import React from "react";
import { Helmet } from "react-helmet";
import CopyRight from "../../../components/Footer/Copyright/CopyRight";
import HeaderDark from "../../../components/Header/HeaderDark";
import FooterThreeDark from "../../../components/Footer/FooterThreeDark";
import ImageGridDemo from "../../../components/Image-grid/ImageGridDemo";
import { FaUserCog } from "react-icons/fa";
import { RiCustomerService2Fill } from "react-icons/ri";
import { TbTruckDelivery } from "react-icons/tb";
import { AiFillYoutube } from "react-icons/ai";
import { IoDocumentText } from "react-icons/io5";
import OnePage from "../../../components/Hero/OnePage";
import { features } from "../../../fake-data/Goshops-feature";
import { BiRadioCircle } from "react-icons/bi";
import KeyFeatures from "../../../components/KeyFeatures";
import PaymentList from "../../../components/Payment";
import { TiVendorAndroid } from "react-icons/ti";

const imageList = [
  {
    img: "goshops-new",
    column: "col-lg-6",
    alt: "Goshops multi seller",
    link: "https://goshops.org",
    new: true,
  },
  {
    img: "goshops",
    column: "col-lg-6",
    alt: "Goshops multi shop",
    link: "https://demo.goshops.org",
    new: false,
  },
  {
    img: "goshops-single",
    column: "col-lg-12",
    alt: "Goshops single shop",
    link: "https://grocery.goshops.org",
    new: false,
  },
];

const skillContent = [
  {
    icon: <FaUserCog />,
    link: "https://admin.goshops.org",
    title: "Admin panel",
    delayAnimation: "100",
    target: "_blank",
  },
  {
    icon: <RiCustomerService2Fill />,
    link: "https://drive.google.com/file/d/1p4WMw5hWMs-V8oWJQ2gwvIaLvJsNQYoz/view?usp=sharing",
    title: "Customer app",
    delayAnimation: "200",
    target: "_blank",
  },
  {
    icon: <TbTruckDelivery />,
    link: "https://drive.google.com/file/d/13EsDEYnw3UMCSpNgbvRTRpOqKuGI8Xho/view",
    title: "Delivery app",
    delayAnimation: "300",
    target: "_blank",
  },
  {
    icon: <TiVendorAndroid />,
    link: "https://codecanyon.net/item/foodyman-vendor-app-iosandroid/43679100?s_rank=2",
    title: "Vendor app",
    delayAnimation: "300",
    target: "_blank",
  },
  {
    icon: <AiFillYoutube />,
    link: "https://www.youtube.com/@githubit1528/videos",
    title: "Website",
    delayAnimation: "400",
    target: "_blank",
  },
  {
    icon: <IoDocumentText />,
    link: "/goshops-documentation/introduction",
    title: "Documentation",
    delayAnimation: "600",
    target: "_blank",
  },
];

export default function GoshopSingleShop() {
  return (
    <div className="ptf-site-wrapper animsition ptf-is--work-showcase-1">
      <Helmet>
        <title>Githubit - Goshops E-Commerce</title>
      </Helmet>

      <HeaderDark />
      <div className="ptf-site-wrapper__inner">
        <div className="main">
          <article className="ptf-page ptf-page--single-work-1">
            <section>
              <OnePage
                skillContent={skillContent}
                buy_link={
                  "https://codecanyon.net/item/multipurposesinglemultivendorshop-allinoneapps-web-admin-pos-ecommerce-marketplace/39741183?s_rank=5"
                }
                descriptions="Goshops is Laravel, Flutter & Next js Framework based online Commerce Full Solution.
                Which provide Admin, Seller Web panel, Website & Flutter (Customer, Delivery Boy, Seller) Mobile App"
                subTitle="Single-Shop e-commerce marketplace (Website + Customer/Courier apps + POS +Admin panel)"
                title="Goshops"
                img_url="Banner 1 version2.png"
              />
            </section>

            <section>
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "10rem", "--ptf-md": "3.125rem" }}
              />

              <KeyFeatures
                text={
                  "Goshops is Laravel, Flutter & Next js Framework based online Commerce Full Solution. Which provide Admin, Seller Web panel, Website & Flutter (Customer, Delivery Boy, Seller) Mobile App"
                }
                features={features}
              />

              <PaymentList />

              <div className="container-xxl">
                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <p className="fz-20 has-black-color">
                    Welcome to GoShops: a complete marketplace solution for
                    E-commerce.
                  </p>
                  <p className="fz-20 has-black-color">
                    Wandering what would you get when you purchase GoShops?
                    Please read the text and see our visuals below:
                  </p>
                  <p className="fz-20 has-black-color">
                    <strong>All-in-one:</strong> <br />
                    <BiRadioCircle size={10} /> Website; (
                    <a href="https://goshops.org">https://goshops.org</a> )
                    <br />
                    <BiRadioCircle size={10} /> Mobile Customer apps (Android
                    and iOS); <br />
                    <BiRadioCircle size={10} /> Delivery Boy apps (iOS and
                    Android); <br />
                    <BiRadioCircle size={10} /> Admin Panel (web); 5. Seller
                    Panel (web); <br />
                    <BiRadioCircle size={10} /> POS system (MacOS, Linux,
                    Windows desktop); <br />A Vendor app is available but sold
                    separately.
                  </p>
                  <p className="fz-20 has-black-color">
                    <strong>SEO and lighting fast page loading times: </strong>
                    If you already have or strive your E-commerce to expand
                    beyond your neighborhood, you will need a solution that
                    provides an excellent page load time, search engine
                    optimization (SEO) and high-throughput readiness . GoShops
                    uses various technologies to offer these key features.
                    Specifically, we built the front-end (website) with Nextjs
                    to maximize page loading time and SEO (please note Flutter
                    based websites do not offer best SEO). Our database is
                    optimized for high throughput (no impact on performance in a
                    live project with over 70,000 unique products).
                  </p>
                  <p className="fz-20 has-black-color">
                    <strong>
                      Single Shop, Multi-Shop or Multi-Seller options
                    </strong>
                  </p>
                  <p className="fz-20 has-black-color">
                    <strong>A growing list of features:</strong> <br />
                    <BiRadioCircle size={10} /> Top-notch UI design <br />
                    <BiRadioCircle size={10} /> Shop from multiple stores into
                    one cart <br />
                    <BiRadioCircle size={10} /> Cashbacks; <br />
                    <BiRadioCircle size={10} /> Lookbook; <br />
                    <BiRadioCircle size={10} /> An extensive list of reports.
                    <br />
                    <BiRadioCircle size={10} /> Refund; <br />
                    <BiRadioCircle size={10} />
                    Multiple zone selection for deliveries and many more.
                  </p>
                  <p className="fz-20 has-black-color">
                    <strong>Versatile: </strong> <br />
                    designed with a range of department stores in mind (Food,
                    Grocery and Pharmacy.)
                  </p>
                  <p className="fz-20 has-black-color">
                    <strong className="fas fa-band-aid">
                      Core technologies:
                    </strong>
                    <br />
                    <BiRadioCircle size={10} /> Laravel (backend) <br />
                    <BiRadioCircle size={10} /> Flutter (mobile apps)
                    <br />
                    <BiRadioCircle size={10} /> Reactjs (admin panels)
                    <br />
                    <BiRadioCircle size={10} /> Typescript and Nextjs (website)
                    <br />
                    <BiRadioCircle size={10} /> Rest API
                  </p>
                </div>

                <div className="mt-4" />

                <div
                  className="ptf-divider"
                  style={{
                    "--ptf-height": "1px",
                    "--ptf-color": "var(--ptf-color-14)",
                  }}
                />

                <div className="mt-5" />

                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <h2>All demos</h2>
                </div>

                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "2.8125rem" }}
                />

                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <ImageGridDemo list={imageList} />
                </div>

                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "2.8125rem" }}
                />
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "2.8125rem" }}
                />
              </div>
            </section>
          </article>
        </div>
      </div>

      <footer className="ptf-footer ptf-footer--style-5">
        <div className="container-xxl">
          <div
            className="ptf-divider"
            style={{
              "--ptf-height": "1px",
              "--ptf-color": "var(--border-color)",
            }}
          />
          <div className="ptf-footer__top">
            <FooterThreeDark />
          </div>

          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
  );
}
