import React, { useState } from "react";
import { Link } from "react-router-dom";

const AppBuildReleaseCustomer = () => {
  const [text, setText] = useState(null);

  const copyToClipBoard = async (copyMe, id) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setText(id);
    } catch (err) {
      setText("Failed to copy!");
    }
  };

  return (
    <div
      className="introduction "
      data-aos="fade-right"
      data-aos-delay="300"
      data-aos-duration="1000"
    >
      <h1 className="title">App build & release</h1>
      <h3 className="introduction-title">Build for Android</h3>
      For debug build you can run command:
      <div className="introduction-code mb-4">
        <div>
          <span className="ps-0">flutter build apk</span>
          <span
            className={text === 1 ? "bg-success copy" : "copy"}
            onClick={() => copyToClipBoard("flutter build apk", 1)}
          >
            {text === 1 ? "copied!" : "copy"}
          </span>
        </div>
      </div>
      You will get a larger merged apk with this. But you can split them with
      this command:
      <div className="introduction-code mb-4">
        <div>
          <span className="ps-0">
            flutter build apk --target-platform
            android-arm,android-arm64,android-x64 --split-per-abi
          </span>
          <span
            className={text === 2 ? "bg-success copy" : "copy"}
            onClick={() =>
              copyToClipBoard(
                "flutter build apk --target-platform android-arm,android-arm64,android-x64 --split-per-abi",
                2
              )
            }
          >
            {text === 2 ? "copied!" : "copy"}
          </span>
        </div>
      </div>
      Build file location:
      <span className="introduction-step"> /build/app/outputs/apk/</span>
      For deploying it please follow this documentation:
      <strong className="strong">
        https://docs.flutter.dev/deployment/android
      </strong>
      <h4 className="introduction-contentTitle">Build for iOS</h4>
      There are no general way to generate app for iOS. Apple doesn’t allow to
      install app like this debug way. If you want to install it on your iOS
      device then you have to deploy it on TestFlight or AppStore. For deploying
      it please follow this documentation:
      <strong className="strong">
        https://docs.flutter.dev/deployment/ios
      </strong>
      <div className="center-page-container">
        <Link
          to="/sundaymart-documentation/customization-customer"
          className="btn  previous"
        >
          <p>Previous</p>
          <p className="link"> Customization customer</p>
        </Link>
        <Link to="/sundaymart-documentation/vendor-app" className="btn  next">
          <p>Next</p>
          <p className="link"> Requirements Vendor </p>
        </Link>
      </div>
    </div>
  );
};

export default AppBuildReleaseCustomer;
