import React, {useState} from 'react';
import {GiCampfire} from "react-icons/gi";
import {Link} from "react-router-dom";
import SimpleReactLightbox, {SRLWrapper} from "simple-react-lightbox";

const UpdateAppWeb = () => {
    const [text, setText] = useState(null)

    const copyToClipBoard = async (copyMe, id) => {
        try {
            await navigator.clipboard.writeText(copyMe)
            setText(id)
        } catch (err) {
            setText('Failed to copy!')
        }
    }
    return (
        <div className="introduction "
             data-aos="fade-right"
             data-aos-delay="300"
             data-aos-duration="1000">
            <h1 className="title">App</h1>
            <h3 className="introduction-title">Update mobile App</h3>
            Download source code for flutter app. <br/>
            For running your project in latest stable flutter version first run this command in your terminal root
            directory:<br/>
            <div className="introduction-code">
                <div>
                        <span className="ps-0">
                        flutter upgrade
                        </span>
                    <span className={text === 1 ? "bg-success copy" : "copy"}
                          onClick={() => copyToClipBoard("flutter upgrade", 1)}>{text === 1 ? "copied!" : "copy"}</span>
                </div>
            </div>
            <br/>
            • Go to your project folder with terminal and update your packages: <br/>
            <div className="introduction-code">
                <div>
                        <span className="ps-0">
                        flutter packages upgrade
                        </span>
                    <span className={text === 2 ? "bg-success copy" : "copy"}
                          onClick={() => copyToClipBoard("flutter packages upgrade", 2)}>{text === 2 ? "copied!" : "copy"}</span>
                </div>
            </div>
            <br/>
            • Update your code files where you need to change. <br/>
            • Then for updating your app version go to pubspec.yaml file and change version:
            <SimpleReactLightbox>
                <SRLWrapper>
                    <a
                        href="/assets/img/doc/vendor_vergen.jpg"
                        data-fancybox
                        rel="nofollow"
                    >
                        <img
                            src="/assets/img/doc/vendor_vergen.jpg"
                            alt="image"
                            loading="lazy"
                            className="img-responsive-full"
                        />
                    </a>
                </SRLWrapper>
            </SimpleReactLightbox>

            <div className="alert alert-danger mt-4" role="alert">
                <div className="mb-2"><GiCampfire size={22}/> <strong>WARNING</strong></div>
                You have to be a developer in this case. If something goes wrong, we won't be responsible for that.
            </div>

            <div className="previous-page-container">
                <Link to="/vendor-documentation/app-build-release" className="btn  previous">
                    <p>Previous</p>
                    <p className="link"> App build & release </p>
                </Link>
            </div>
        </div>
    );
};

export default UpdateAppWeb;