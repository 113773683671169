import React from "react";
import { Helmet } from "react-helmet";
import { useParams, Link } from "react-router-dom";
import HeaderDark from "../../../../components/Header/HeaderDark";
import FooterThreeDark from "../../../../components/Footer/FooterThreeDark";
import CopyRight from "../../../../components/Footer/Copyright/CopyRight";
import TicketsShowChat from "./ticketsShowChat";

const TicketChat = () => {
  const { id } = useParams();

  return (
    <div>
      <Helmet>
        <title>Githubit - Tickets chat</title>
      </Helmet>

      <HeaderDark />

      <div className="ptf-main">
        <div className="ptf-page ptf-page--contact">
          <section>
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
            />
            <div className="container-xxl">
              <div className="row">
                <div className="col-xl-10">
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <h3 className="large-heading text-white">
                      <Link to="/tickets">Tickets</Link> / View Ticket #{id}
                    </h3>
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "3rem", "--ptf-md": "2.5rem" }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="ptf-spacer" />
          </section>

          <section>
            <div className="container-xxl">
              <div className="row">
                <div className="col-lg-12">
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="300"
                  >
                    <div className="ptf-spacer" />
                    <TicketsShowChat />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "8rem", "--ptf-md": "5rem" }}
            />
          </section>
        </div>
      </div>

      <footer className="ptf-footer ptf-footer--style-5">
        <div className="container-xxl">
          <div
            className="ptf-divider"
            style={{
              "--ptf-height": "1px",
              "--ptf-color": "var(--border-color)",
            }}
          />
          <div className="ptf-footer__top">
            <FooterThreeDark />
          </div>

          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default TicketChat;
