import React from "react";
import { Helmet } from "react-helmet";
import CopyRight from "../../../../components/Footer/Copyright/CopyRight";
import HeaderDark from "../../../../components/Header/HeaderDark";
import FooterThreeDark from "../../../../components/Footer/FooterThreeDark";
import ImageGridDemo from "../../../../components/Image-grid/ImageGridDemo";
import OnePage from "../../../../components/Hero/OnePage";
import { FaUserCog } from "react-icons/fa";
import { RiCustomerService2Fill } from "react-icons/ri";
import { AiFillYoutube } from "react-icons/ai";
import { IoDocumentText } from "react-icons/io5";
import { features } from "../../../../fake-data/Damda-feature";
import KeyFeatures from "../../../../components/KeyFeatures";

const imageList = [
  {
    img: "16",
    column: "col-lg-12",
    alt: "Damda Travel to Uzbekistan",
    link: "https://damda.uz",
    new: false,
  },
];

const skillContent = [
  {
    icon: <FaUserCog />,
    link: "https://play.google.com/store/apps/details?id=com.damda.damdac&hl=uz&gl=US",
    title: "Damda Travel app",
    delayAnimation: "500",
    target: "_blank",
  },
  {
    icon: <RiCustomerService2Fill />,
    link: "https://play.google.com/store/apps/details?id=uz.damda.damda_landlord&hl=uz&gl=US",
    title: "Damda landlord app",
    delayAnimation: "500",
    target: "_blank",
  },
  {
    icon: <RiCustomerService2Fill />,
    link: "https://play.google.com/store/apps/details?id=com.damda.damdac&hl=uz&gl=US",
    title: "Damda app",
    delayAnimation: "500",
    target: "_blank",
  },
  {
    icon: <AiFillYoutube />,
    link: "https://youtu.be/T1Zsw9zYj-U",
    title: "Damda Travel",
    delayAnimation: "500",
    target: "_blank",
  },
  {
    icon: <IoDocumentText />,
    link: "https://damda.uz/en/for/guides",
    title: "Documentation",
    delayAnimation: "600",
    target: "_blank",
  },
];

const DamdaService = () => {
  return (
    <div className="ptf-site-wrapper animsition ptf-is--work-showcase-1">
      <Helmet>
        <title>Githubit - Damda Traveling</title>
      </Helmet>
      <HeaderDark />
      <div className="ptf-site-wrapper__inner">
        <div className="main">
          <article className="ptf-page ptf-page--single-work-1">
            <section>
              <OnePage
                skillContent={skillContent}
                buy_link={"https://damda.uz"}
                descriptions="If you want to travel around Uzbekistan, damda.uz can be your personal guide. You can find all the information about the best touristic attractions, holiday destinations, open-air museums and book online any hotel, resort, dacha just by one click. Moreover, you can contact with professional guides through this website."
                subTitle="Travel to Uzbekistan"
                title="Damda"
                img_url="16.jpg"
                button_text={"Show project"}
              />
            </section>

            <section>
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "10rem", "--ptf-md": "3.125rem" }}
              />

              <KeyFeatures
                text={
                  " If you want to travel around Uzbekistan, damda.uz can be your personal guide. You can find all the information about the best touristic attractions, holiday destinations, open-air museums and book online any hotel, resort, dacha just by one click. Moreover, you can contact with professional guides through this website."
                }
                features={features}
              />

              <div className="container-xxl">
                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <p className="fz-20 has-black-color">
                    Damda.Uz is a portal that represents a site for online
                    booking of hotels, sanatoriums, resorts, houses/cottages and
                    in Uzbekistan, as well as a selection of tours abroad and
                    booking guides services. Our portal helps travelers and
                    vacationers to find the best rooms at a bargain price.
                  </p>
                  <p className="fz-20 has-black-color">
                    On our site you will find a place of rest for yourself and
                    your family, a hotel or a hotel for business trips, you can
                    book a room at any time from anywhere in Uzbekistan. You can
                    pay for your reservation without leaving your home using
                    your credit card.
                  </p>
                  <p className="fz-20 has-black-color">
                    Damda.Uz allows you to receive information about the
                    availability of rooms and cost of living, as well as compare
                    prices and select the most appropriate options. Visitor
                    reviews will help you decide on the best option. Information
                    about prices and the number of rooms available for booking
                    through the site is set by the administration of hotels,
                    resorts or sanatoriums and correspond to the price list of
                    objects.
                  </p>
                  <p className="fz-20 has-black-color">
                    Damda.Uz is a portal that represents a site for online
                    booking of hotels, sanatoriums, resorts, houses/cottages in
                    Uzbekistan, as well as a selection of tours abroad and
                    booking guides services. Our portal helps travelers and
                    vacationers to find the best rooms at a bargain price.
                    Damda.Uz allows you to receive information about the
                    availability of rooms and cost of living, as well as compare
                    prices and select the most appropriate options. Visitor
                    reviews will help you decide on the best option. Information
                    about prices and the number of rooms available for booking
                    through the site is set by the administration of hotels,
                    resorts or sanatoriums and correspond to the price list of
                    objects.
                  </p>
                </div>

                <div className="mt-4" />

                <div
                  className="ptf-divider"
                  style={{
                    "--ptf-height": "1px",
                    "--ptf-color": "var(--ptf-color-14)",
                  }}
                />

                <div className="mt-5" />

                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <h2>All demos</h2>
                </div>

                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "2.8125rem" }}
                />

                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <ImageGridDemo list={imageList} />
                </div>

                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "2.8125rem" }}
                />
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "2.8125rem" }}
                />
              </div>
            </section>
          </article>
        </div>
      </div>

      <footer className="ptf-footer ptf-footer--style-5">
        <div className="container-xxl">
          <div
            className="ptf-divider"
            style={{
              "--ptf-height": "1px",
              "--ptf-color": "var(--border-color)",
            }}
          />
          <div className="ptf-footer__top">
            <FooterThreeDark />
          </div>

          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default DamdaService;
