import React from "react";
import { Route } from "react-router-dom";
import SaasDoc from "../views/saas-doc";
import SaasIntroduction from "../views/saas-doc/Introduction";
import SaasBasic from "../views/saas-doc/Basic";
import SaasServer from "../views/saas-doc/Server";
import SaasMobileApp from "../views/saas-doc/Mobile-app";
import SaasFlutterSDK from "../views/saas-doc/Flutter-SDK";
import SaasLocalServer from "../views/saas-doc/Local-server";
import SaasInstallOnServer from "../views/saas-doc/Install-on-server";
import SaasMandatorySetup from "../views/saas-doc/Mandatory-setup";
import SaasCustomization from "../views/saas-doc/Customization";
import SaasMandatorySetupMobile from "../views/saas-doc/Mandatory-setup-mobile";
import SaasMandatorySetupDeliveryboy from "../views/saas-doc/Moderator-setup-deliveryboy";
import SaasMandatorySetupVendor from "../views/saas-doc/Moderator-setup-vendor";
import SaasMandatorySetupCustomer from "../views/saas-doc/Moderator-setup-customer";
import SaasMandatorySetupPos from "../views/saas-doc/Moderator-setup-pos";
import SaasCustomizationMobile from "../views/saas-doc/Customization-mobile";
import SaasMandatorySetupWeb from "../views/saas-doc/Mandatory-setup-web";
import SaasMandatorySetupBackend from "../views/saas-doc/Mandatory-setup-backend";
import SaasBuildCodeAndSetupOnServer from "../views/saas-doc/Build-code-and-setup-on-server";
import SaasBuildCodeAndSetupOnServerBackend from "../views/saas-doc/Build-code-and-setup-on-server-backend";
import SaasUpdateAdminPanel from "../views/saas-doc/Update-admin-panel";
import SaasUpdateAppWeb from "../views/saas-doc/Update-app-web";
import SaasFront from "../views/saas-doc/front";
import SaasAdmin from "../views/saas-doc/admin";
import SaasLocalFront from "../views/saas-doc/local-front";
import SaasSupportPlan from "../views/saas-doc/supportPlan";
import SaasFirebaseSetup from "../views/saas-doc/firebase-setup";
import TroubleshootingBackendSaas from "../views/saas-doc/Troubleshooting-backend";
import CustomizationMobileCustomerSaas from "../views/saas-doc/Customization-mobile-customer";
import CustomizationMobilePosSaas from "../views/saas-doc/Customization-mobile-pos";
import CustomizationMobileDeliverySaas from "../views/saas-doc/Customization-mobile-delivery";
import TroubleshootingAdminSaas from "../views/saas-doc/Troubleshooting-admin";
import UpdateFooymanSaas from "../views/saas-doc/update";
import Recommendations from "../views/saas-doc/recommendations";
import SaasMandatorySetupLanding from "../views/saas-doc/Mandatory-setup-landing";
import SaasLanding from "../views/saas-doc/landing";
import AppBuildReleasePos from "../views/saas-doc/App-build-release-pos";
import AppBuildReleaseDelivery from "../views/saas-doc/App-build-release-deliveryboy";
import AppBuildReleaseVendor from "../views/saas-doc/App-build-release-vendor";
import AppBuildReleaseCustomer from "../views/saas-doc/App-build-release-customer";

const Saas = () => {
  return (
    <Route path="/saas-documentation" element={<SaasDoc />}>
      <Route
        path="/saas-documentation/introduction"
        element={<SaasIntroduction />}
      />
      <Route
        path="/saas-documentation/pos-app-build-release"
        element={<AppBuildReleasePos />}
      />
      <Route path="/saas-documentation/landing" element={<SaasLanding />} />
      <Route
        path="/saas-documentation/mandatory-setup-landing"
        element={<SaasMandatorySetupLanding />}
      />
      <Route path="/saas-documentation/basic" element={<SaasBasic />} />
      <Route path="/saas-documentation/server" element={<SaasServer />} />
      <Route path="/saas-documentation/front" element={<SaasFront />} />
      <Route path="/saas-documentation/admin" element={<SaasAdmin />} />
      <Route
        path="/saas-documentation/local-front"
        element={<SaasLocalFront />}
      />
      <Route
        path="/saas-documentation/mobile-app"
        element={<SaasMobileApp />}
      />
      <Route
        path="/saas-documentation/vendor-app"
        element={<SaasMobileApp />}
      />
      <Route
        path="/saas-documentation/driver-app"
        element={<SaasMobileApp />}
      />
      <Route
        path="/saas-documentation/pos-app"
        element={<SaasMobileApp pageTitle="Pos app" />}
      />
      <Route
        path="/saas-documentation/flutter-sdk"
        element={<SaasFlutterSDK />}
      />
      <Route
        path="/saas-documentation/local-server"
        element={<SaasLocalServer />}
      />
      <Route
        path="/saas-documentation/install-on-server"
        element={<SaasInstallOnServer />}
      />
      <Route
        path="/saas-documentation/mandatory-setup"
        element={<SaasMandatorySetup />}
      />
      <Route
        path="/saas-documentation/customization"
        element={<SaasCustomization />}
      />
      <Route
        path="/saas-documentation/mandatory-setup-mobile"
        element={<SaasMandatorySetupMobile />}
      />
      <Route
        path="/saas-documentation/mandatory-setup-backend"
        element={<SaasMandatorySetupBackend />}
      />
      <Route
        path="/saas-documentation/mandatory-setup-vendor"
        element={<SaasMandatorySetupVendor />}
      />
      <Route
        path="/saas-documentation/mandatory-setup-customer"
        element={<SaasMandatorySetupCustomer />}
      />
      <Route
        path="/saas-documentation/mandatory-setup-pos"
        element={<SaasMandatorySetupPos />}
      />
      <Route
        path="/saas-documentation/waiter-app"
        element={<SaasMobileApp pageTitle="Waiter App" />}
      />
      <Route
        path="/saas-documentation/mandatory-setup-deliveryboy"
        element={<SaasMandatorySetupDeliveryboy />}
      />
      <Route
        path="/saas-documentation/mandatory-setup-waiter"
        element={<SaasMandatorySetupDeliveryboy />}
      />
      <Route
        path="/saas-documentation/customization-vendor"
        element={<SaasCustomizationMobile />}
      />
      <Route
        path="/saas-documentation/customization-deliveryboy"
        element={<CustomizationMobileDeliverySaas />}
      />
      <Route
        path="/saas-documentation/customization-waiter"
        element={<CustomizationMobileDeliverySaas />}
      />
      <Route
        path="/saas-documentation/customization-customer"
        element={<CustomizationMobileCustomerSaas />}
      />
      <Route
        path="/saas-documentation/customization-pos"
        element={<CustomizationMobilePosSaas />}
      />
      <Route
        path="/saas-documentation/customer-app-build-release"
        element={<AppBuildReleaseCustomer />}
      />
      <Route
        path="/saas-documentation/vendor-app-build-release"
        element={<AppBuildReleaseVendor />}
      />
      <Route
        path="/saas-documentation/deliveryboy-app-build-release"
        element={<AppBuildReleaseDelivery />}
      />
      <Route
        path="/saas-documentation/waiter-app-build-release"
        element={<AppBuildReleaseDelivery />}
      />
      <Route
        path="/saas-documentation/recommendations"
        element={<Recommendations />}
      />
      <Route
        path="/saas-documentation/mandatory-setup-web"
        element={<SaasMandatorySetupWeb />}
      />
      <Route
        path="/saas-documentation/build-code-and-setup-on-server"
        element={<SaasBuildCodeAndSetupOnServer />}
      />
      <Route
        path="/saas-documentation/build-code-and-setup-on-server-backend"
        element={<SaasBuildCodeAndSetupOnServerBackend />}
      />
      <Route
        path="/saas-documentation/update-admin-panel"
        element={<SaasUpdateAdminPanel />}
      />
      <Route
        path="/saas-documentation/update-app-web"
        element={<SaasUpdateAppWeb />}
      />
      <Route
        path="/saas-documentation/support-plan"
        element={<SaasSupportPlan />}
      />
      <Route
        path="/saas-documentation/firebase"
        element={<SaasFirebaseSetup />}
      />

      <Route
        path="/saas-documentation/troubleshooting-backend"
        element={<TroubleshootingBackendSaas />}
      />
      <Route
        path="/saas-documentation/troubleshooting-admin"
        element={<TroubleshootingAdminSaas />}
      />
      <Route
        path="/saas-documentation/update"
        element={<UpdateFooymanSaas />}
      />
    </Route>
  );
};

export default Saas;
