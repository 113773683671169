export const vendorMenu = [

    {
        name: "Mobile Apps",
        dropDownItems: [
            {
                name: "Mandatory setup",
                routerPath: "/vendor-documentation/mandatory-setup-mobile",
            },
            {
                name: "Customization",
                routerPath: "/vendor-documentation/customization-mobile",
            },
            {
                name: "App build & release",
                routerPath: "/vendor-documentation/app-build-release",
            },
        ],
    },
    {
        name: "Update",
        dropDownItems: [
            {
                name: "App",
                routerPath: "/vendor-documentation/update-app",
            },
        ],
    },
];