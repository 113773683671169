import React from "react";
import { Helmet } from "react-helmet";
import CopyRight from "../../../components/Footer/Copyright/CopyRight";
import CareerMenu from "../../../components/careerMenu";
import CareerContent from "../../../components/careerContent";
import HeaderDark from "../../../components/Header/HeaderDark";

const Career = () => {
  return (
    <div className="docContainer">
      <Helmet>
        <title>GitHubit Documentation</title>
      </Helmet>

      <HeaderDark />
      <div className="ptf-main">
        <div className="ptf-page ptf-page--contact">
          <section>
            <div className="container-xxl">
              <div className="row">
                <div className="col-lg-5">
                  <CareerMenu />
                </div>
                <div className="col-lg-7">
                  <CareerContent />
                </div>
              </div>
            </div>
          </section>
        </div>
        <div
          className="ptf-spacer"
          style={{ "--ptf-xxl": "3rem", "--ptf-md": "2.5rem" }}
        />
      </div>

      <footer className="ptf-footer ptf-footer--style-5 ptf-footer-doc">
        <div className="container-xxl">
          <CopyRight />
        </div>
      </footer>
    </div>
  );
};

export default Career;
