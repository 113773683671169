import { HiOutlineReceiptRefund } from "react-icons/hi";
import { BsCart3 } from "react-icons/bs";
import { SiBloglovin } from "react-icons/si";

export const features = [
  {
    tag: "Booking",
    title: "Booking",
    subTitle: "Booking",
    icon: <BsCart3 />,
    color: "#F0EAF6",
    bgColor: "#E2626B",
    delayAnimation: "100",
  },
  {
    tag: "Refunds",
    title: "Order refunds",
    subTitle: "Return your created order",
    icon: <HiOutlineReceiptRefund />,
    color: "#E0E9FA",
    bgColor: "#747ED1",
    delayAnimation: "400",
  },
  {
    tag: "Blog",
    title: "Blog",
    subTitle: "Create new blog",
    icon: <SiBloglovin />,
    color: "#ecd9c6",
    bgColor: "#86592d",
    delayAnimation: "400",
  },
];
