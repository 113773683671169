import React, { useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { GiCampfire } from "react-icons/gi";
import { Link } from "react-router-dom";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

const MandatorySetupMobile = () => {
  const [text, setText] = useState(null);

  const copyToClipBoard = async (copyMe, id) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setText(id);
    } catch (err) {
      setText("Failed to copy!");
    }
  };

  return (
    <div
      className="introduction "
      data-aos="fade-right"
      data-aos-delay="300"
      data-aos-duration="1000"
    >
      <h1 className="title"> Mandatory setup</h1>
      <div className="alert alert-primary mt-3" role="alert">
        <div className="mb-2">
          <AiOutlineInfoCircle size={22} /> <strong>INFO</strong>
        </div>
        The same documentation for Delivery app
      </div>
      <h4 className="introduction-contentTitle">
        {" "}
        Run an existing flutter project on IDE{" "}
      </h4>
      <h4 className="introduction-contentTitle"> Change App Logo </h4>
      You can generate app icon using this website
      https://romannurik.github.io/AndroidAssetStudio/icons-launcher.html <br />
      • Then go to{" "}
      <span className="introduction-step"> /android/app/src/main/res</span>
      and replace all mipmap folder with your /android folder <br />• Again go
      to <span className="introduction-step">/ios/Runner</span> and replace
      Assets.xcassets with your generated Assets.xcassets folder <br />
      <h4 className="introduction-contentTitle"> Change App Name </h4>
      <div className="mt-4 mb-3">
        1.Change the value of label from
        <span className="introduction-step">
          /android/app/src/main/AndroidManifest.xml
        </span>
        <div className="introduction-code">
          <p>/android/app/src/main/AndroidManifest.xml</p>
          <hr />
          <div>
            <span>android:label="My App"</span>
            <span
              className={text === 2 ? "bg-success copy" : "copy"}
              onClick={() => copyToClipBoard('android:label="My App"’', 2)}
            >
              {text === 2 ? "copied!" : "copy"}
            </span>
          </div>
        </div>
      </div>
      <div className="mt-4 mb-3">
        2.Change the value of CFBundleName from
        <span className="introduction-step"> /iOS/Runner/info.plist</span>
        <div className="introduction-code">
          <p>/iOS/Runner/info.plist</p>
          <hr />
          <div>
            <span>
              {`<key>CFBundleName</key>`} <br />
              {`<string>My App</string>`}
            </span>
            <span
              className={text === 3 ? "bg-success copy" : "copy"}
              onClick={() =>
                copyToClipBoard(
                  "<key>CFBundleName</key><string>My App</string>",
                  3
                )
              }
            >
              {text === 3 ? "copied!" : "copy"}
            </span>
          </div>
        </div>
      </div>
      <h4 className="introduction-contentTitle"> Change Base URL</h4>
      Please do NOT put slash ( / ) at the end of your base url. Use your admin
      url as base url. First you have to install your admin panel. For example:
      If your admin url is{" "}
      <span className="introduction-step"> https://your_domain.com/admin</span>
      then base url will be https://your_domain.com. Open{" "}
      <span className="introduction-step">
        {" "}
        /lib/src/core/constants/app_constants.dart
      </span>
      and replace baseUrl variable value with your own URL.
      <div className="introduction-code">
        <p>/lib/src/core/constants/app_constants.dart</p>
        <hr />
        <div>
          <span className="ps-0">
            static const String baseUrl=
            <span className="text-black">'https://your_domain.com'</span>
          </span>
          <span
            className={text === 4 ? "bg-success copy" : "copy"}
            onClick={() =>
              copyToClipBoard("baseUrl=https://your_domain.com", 4)
            }
          >
            {text === 4 ? "copied!" : "copy"}
          </span>
        </div>
      </div>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a href="/assets/img/doc/mobile-1.jpg" data-fancybox rel="nofollow">
              <img
                src="/assets/img/doc/mobile-1.jpg"
                alt="image01"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <h4 className="introduction-contentTitle">Change App Package</h4>
      Firstly, find out the existing package name. You can find it out from top
      of
      <span className="introduction-step">
        /app/src/main/AndroidManifest.xml
      </span>
      file. Then right click on project folder from android studio and click on
      <span className="introduction-step">replace in path</span>
      You will see a popup window with two input boxes. In first box you have to
      put existing package name that you saw in{" "}
      <span className="introduction-step">AndroidManifest.xml</span>
      file previously and then write down your preferred package name in second
      box and then click on{" "}
      <span className="introduction-step">Replace All</span> button.
      <h4 className="introduction-contentTitle">
        Setup Firebase for Push Notification
      </h4>
      • Firstly, change your package name. If you didn’t then go to this link{" "}
      <br />• Create your own firebase project from{" "}
      <strong className="strong">https://console.firebase.google.com </strong>
      and also add there an android app with your own package name and app name{" "}
      <br />
      <div className="alert alert-danger mt-4" role="alert">
        <div className="mb-2">
          <GiCampfire size={22} /> <strong>WARNING</strong>
        </div>
        Please do NOT create multiple projects if you have multiple app like
        User App, Delivery App. Create only one project and add multiple apps
        under one project.
      </div>
      • Click register app and download google-services.json file from there.{" "}
      <br />• Copy that file and paste it under
      <span className="introduction-step"> /android/app/ folder</span>
      <br />• Create a totally white png logo for notification icon. Paste it on{" "}
      <span className="introduction-step">
        {" "}
        /android/app/src/main/res/drawable/
      </span>
      and replace notification_icon.png with your whiter version logo. <br />•
      For IOS again create an app under the same project and download{" "}
      <span className="introduction-step">GoogleService-Info.plist</span>
      and paste it under
      <span className="introduction-step">/iOS/ folder</span> <br />
      Also you are advised to follow this documentation for full setup for IOS:
      <strong className="strong">
        {" "}
        https://firebase.flutter.dev/docs/messaging/apple-integration{" "}
      </strong>
      <br />• Paste firebase server key into admin panel Notification Settings
      section. You can receive server key from{" "}
      <span className="introduction-step">
        Firebase project settings{"->"}Cloud Messaging{"->"} Server Key{" "}
      </span>
      <br />
      After setup, please restart your IDE and uninstall your previously
      installed app, and then run it. Also do NOT try to test it on emulator or
      simulator. Emulator and simulators are unable to get push. Use real device
      for this purpose.
      <h4 className="introduction-contentTitle"> Add Google Map API Key</h4>
      • Please generate the google API key. You can visit this link -
      https://developers.google.com/maps/documentation/embed/get-api-key <br />
      • You need to enable mentioned APIs: Direction API, Distance Matrix API,
      Geocoding API, Maps SDK for Android, Maps SDK for iOS, Place API. <br />
      • Then you have to enable billing account. Visit this URL for activation:
      https://support.google.com/googleapi/answer/6158867?hl=en <br />
      • After generating API key, you have to paste it on 3 different places:
      Android, iOS and web. <br />
      For android, open
      <span className="introduction-step">
        /android/app/src/main/AndroidManifest.xml
      </span>{" "}
      and place the value of{" "}
      <span className="introduction-step">com.google.android.geo.API_KEY </span>
      <div className="introduction-code">
        <p> /android/app/src/main/AndroidManifest.xml</p>
        <hr />
        <div>
          <span className="ps-0">
            {
              '<meta-data android:name="com.google.android.geo.API_KEY" android:value=“YOUR_MAP_API_KEY_HERE”/>'
            }
          </span>
          <span
            className={text === 5 ? "bg-success copy" : "copy"}
            onClick={() =>
              copyToClipBoard(
                '<meta-data android:name="com.google.android.geo.API_KEY" android:value=“YOUR_MAP_API_KEY_HERE”/>',
                5
              )
            }
          >
            {text === 5 ? "copied!" : "copy"}
          </span>
        </div>
      </div>
      <div className="mt-4">
        For iOS: open{" "}
        <span className="introduction-step">/iOS/Runner/AppDelegate.swift</span>
        and place the value of{" "}
        <span className="introduction-step">GMSServices.provideAPIKey</span>
        <div className="introduction-code">
          <p>/iOS/Runner/AppDelegate.swift</p>
          <hr />
          <div>
            <span className="ps-0">
              GMSServices.provideAPIKey(“YOUR_MAP_API_KEY_HERE")
            </span>
            <span
              className={text === 6 ? "bg-success copy" : "copy"}
              onClick={() =>
                copyToClipBoard(
                  'GMSServices.provideAPIKey(“YOUR_MAP_API_KEY_HERE")',
                  6
                )
              }
            >
              {text === 6 ? "copied!" : "copy"}
            </span>
          </div>
        </div>
      </div>
      <div className="center-page-container">
        <Link
          to="/foodyman-documentation/build-code-and-setup-on-server"
          className="btn  previous"
        >
          <p>Previous</p>
          <p className="link"> Build code and setup -on-server </p>
        </Link>
        <Link
          to="/foodyman-documentation/customization-mobile"
          className="btn  next"
        >
          <p>Next</p>
          <p className="link"> Customization Mobile App </p>
        </Link>
      </div>
    </div>
  );
};

export default MandatorySetupMobile;
