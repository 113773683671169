import React from "react";
import { SiEnvato } from "react-icons/si";

export default function EnvatoSignIn() {
  const handleEnvatoSignIn = () => {
    window.location.replace(
      `https://api.envato.com/authorization?response_type=code&client_id=${process.env.REACT_APP_ENVATO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_ENVATO_REDIRECT_URI}`
    );
  };

  return (
    <button onClick={handleEnvatoSignIn} className="social-btn" type="button">
      <SiEnvato size={26} />
      Login with Envato
    </button>
  );
}
