import Troubleshooting from "../views/goshops-docs/Troubleshooting";
import ChangeStaticData from "../views/goshops-docs/change-static-data";
import React from "react";
import { Route } from "react-router-dom";
import SundayDoc from "../views/Sundaymart-doc";
import SundayIntroduction from "../views/Sundaymart-doc/Introduction";
import SundayRecommendations from "../views/Sundaymart-doc/recommendations";
import SundayBasic from "../views/Sundaymart-doc/Basic";
import SundayServer from "../views/Sundaymart-doc/Server";
import SundayFront from "../views/Sundaymart-doc/front";
import SundayLocalFront from "../views/Sundaymart-doc/local-front";
import SundayMobileApp from "../views/Sundaymart-doc/Mobile-app";
import SundayFlutterSDK from "../views/Sundaymart-doc/Flutter-SDK";
import SundayLocalServer from "../views/Sundaymart-doc/Local-server";
import SundayInstallOnServer from "../views/Sundaymart-doc/Install-on-server";
import SundayMandatorySetup from "../views/Sundaymart-doc/Mandatory-setup";
import SundayCustomization from "../views/Sundaymart-doc/Customization";
import SundayMandatorySetupMobile from "../views/Sundaymart-doc/Mandatory-setup-mobile";
import SundayCustomizationMobile from "../views/Sundaymart-doc/Customization-mobile";
import SundayAppBuildRelease from "../views/Sundaymart-doc/App-build-release";
import SundayMandatorySetupWeb from "../views/Sundaymart-doc/Mandatory-setup-web";
import SundayBuildCodeAndSetupOnServer from "../views/Sundaymart-doc/Build-code-and-setup-on-server";
import SundayUpdateAdminPanel from "../views/Sundaymart-doc/Update-admin-panel";
import SundayUpdateAppWeb from "../views/Sundaymart-doc/Update-app-web";
import SundaySupportPlan from "../views/Sundaymart-doc/supportPlan";
import SundayFirebaseSetup from "../views/Sundaymart-doc/firebase-setup";
import SundayMandatorySetupBackend from "../views/Sundaymart-doc/Mandatory-setup-backend";
import SundayTroubleshootingBackend from "../views/Sundaymart-doc/Troubleshooting-backend";
import SundayAdmin from "../views/Sundaymart-doc/admin";
import SundayTroubleshootingAdmin from "../views/Sundaymart-doc/Troubleshooting-admin";
import SundayMandatorySetupCustomer from "../views/Sundaymart-doc/Moderator-setup-customer";
import SundayCustomizationMobileCustomer from "../views/Sundaymart-doc/Customization-mobile-customer";
import SundayAppBuildReleaseCustomer from "../views/Sundaymart-doc/App-build-release-customer";
import SundayMandatorySetupVendor from "../views/Sundaymart-doc/Moderator-setup-vendor";
import SundayAppBuildReleaseVendor from "../views/Sundaymart-doc/App-build-release-vendor";
import SundayMandatorySetupDeliveryboy from "../views/Sundaymart-doc/Moderator-setup-deliveryboy";
import SundayCustomizationMobileDelivery from "../views/Sundaymart-doc/Customization-mobile-delivery";
import SundayAppBuildReleaseDelivery from "../views/Sundaymart-doc/App-build-release-deliveryboy";
import SundayMandatorySetupPos from "../views/Sundaymart-doc/Moderator-setup-pos";
import SundayCustomizationMobilePos from "../views/Sundaymart-doc/Customization-mobile-pos";
import SundayAppBuildReleasePos from "../views/Sundaymart-doc/App-build-release-pos";
import UpdateSundaymart from "../views/Sundaymart-doc/update";

const Sundaymart = () => {
  return (
      <Route path="/sundaymart-documentation" element={<SundayDoc />}>
          <Route
              path="/sundaymart-documentation/introduction"
              element={<SundayIntroduction />}
          />
          <Route
              path="/sundaymart-documentation/recommendations"
              element={<SundayRecommendations />}
          />
          <Route
              path="/sundaymart-documentation/basic"
              element={<SundayBasic />}
          />
          <Route
              path="/sundaymart-documentation/server"
              element={<SundayServer />}
          />
          <Route
              path="/sundaymart-documentation/front"
              element={<SundayFront />}
          />
          <Route
              path="/sundaymart-documentation/local-front"
              element={<SundayLocalFront />}
          />
          <Route
              path="/sundaymart-documentation/mobile-app"
              element={<SundayMobileApp />}
          />
          <Route
              path="/sundaymart-documentation/vendor-app"
              element={<SundayMobileApp />}
          />
          <Route
              path="/sundaymart-documentation/driver-app"
              element={<SundayMobileApp />}
          />
          <Route
              path="/sundaymart-documentation/flutter-sdk"
              element={<SundayFlutterSDK />}
          />
          <Route
              path="/sundaymart-documentation/local-server"
              element={<SundayLocalServer />}
          />
          <Route
              path="/sundaymart-documentation/install-on-server"
              element={<SundayInstallOnServer />}
          />
          <Route
              path="/sundaymart-documentation/mandatory-setup"
              element={<SundayMandatorySetup />}
          />
          <Route
              path="/sundaymart-documentation/customization"
              element={<SundayCustomization />}
          />
          <Route
              path="/sundaymart-documentation/mandatory-setup-mobile"
              element={<SundayMandatorySetupMobile />}
          />
          <Route
              path="/sundaymart-documentation/customization-mobile"
              element={<SundayCustomizationMobile />}
          />
          <Route
              path="/sundaymart-documentation/app-build-release"
              element={<SundayAppBuildRelease />}
          />
          <Route
              path="/sundaymart-documentation/mandatory-setup-web"
              element={<SundayMandatorySetupWeb />}
          />
          <Route
              path="/sundaymart-documentation/build-code-and-setup-on-server"
              element={<SundayBuildCodeAndSetupOnServer />}
          />
          <Route
              path="/sundaymart-documentation/update-admin-panel"
              element={<SundayUpdateAdminPanel />}
          />
          <Route
              path="/sundaymart-documentation/update-app-web"
              element={<SundayUpdateAppWeb />}
          />
          <Route
              path="/sundaymart-documentation/support-plan"
              element={<SundaySupportPlan />}
          />
          <Route
              path="/sundaymart-documentation/firebase"
              element={<SundayFirebaseSetup />}
          />
          <Route
              path="/sundaymart-documentation/troubleshooting"
              element={<Troubleshooting />}
          />
          <Route
              path="/sundaymart-documentation/mandatory-setup-backend"
              element={<SundayMandatorySetupBackend />}
          />
          <Route
              path="/sundaymart-documentation/troubleshooting-backend"
              element={<SundayTroubleshootingBackend />}
          />
          <Route
              path="/sundaymart-documentation/admin"
              element={<SundayAdmin />}
          />
          <Route
              path="/sundaymart-documentation/troubleshooting-admin"
              element={<SundayTroubleshootingAdmin />}
          />
          <Route
              path="/sundaymart-documentation/mandatory-setup-customer"
              element={<SundayMandatorySetupCustomer />}
          />
          <Route
              path="/sundaymart-documentation/customization-customer"
              element={<SundayCustomizationMobileCustomer />}
          />
          <Route
              path="/sundaymart-documentation/customer-app-build-release"
              element={<SundayAppBuildReleaseCustomer />}
          />
          <Route
              path="/sundaymart-documentation/mandatory-setup-vendor"
              element={<SundayMandatorySetupVendor />}
          />
          <Route
              path="/sundaymart-documentation/customization-vendor"
              element={<SundayCustomizationMobile />}
          />
          <Route
              path="/sundaymart-documentation/vendor-app-build-release"
              element={<SundayAppBuildReleaseVendor />}
          />
          <Route
              path="/sundaymart-documentation/mandatory-setup-deliveryboy"
              element={<SundayMandatorySetupDeliveryboy />}
          />
          <Route
              path="/sundaymart-documentation/customization-deliveryboy"
              element={<SundayCustomizationMobileDelivery />}
          />
          <Route
              path="/sundaymart-documentation/deliveryboy-app-build-release"
              element={<SundayAppBuildReleaseDelivery />}
          />
          <Route
              path="/sundaymart-documentation/pos-app"
              element={<SundayMobileApp />}
          />
          <Route
              path="/sundaymart-documentation/mandatory-setup-pos"
              element={<SundayMandatorySetupPos />}
          />
          <Route
              path="/sundaymart-documentation/customization-pos"
              element={<SundayCustomizationMobilePos />}
          />
          <Route
              path="/sundaymart-documentation/pos-app-build-release"
              element={<SundayAppBuildReleasePos />}
          />
          <Route
              path="/sundaymart-documentation/update"
              element={<UpdateSundaymart />}
          />
          <Route
              path="/sundaymart-documentation/change-static-data"
              element={<ChangeStaticData />}
          />
      </Route>
  );
};

export default Sundaymart;
