import React from "react";
import { useFormik } from "formik";
import authService from "../../../../service/AuthService";
import { ArrowTopRight } from "../../../../components/Svg/goTop-svg";
import LoadingIndicator from "../../../../components/Loader/loadingIndicator";
import GoogleSignIn from "../GoogleSignIn";
import EnvatoSignIn from "../EnvatoSingIn";

const RegistrationForm = ({ setStep, setVerifyEmail }) => {
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: (values, { setSubmitting }) => {
      const body = {
        email: values.email,
      };
      authService
        .register(body)
        .then(() => {
          setStep(1);
          setVerifyEmail({ body });
        })
        .finally(() => setSubmitting(false));
    },
    validate: (values) => {
      const errors = {};
      if (!values.email) {
        errors.email = "Required";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
      ) {
        errors.email = "Invalid email address";
      }
      return errors;
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} name="registration">
      <div className="ptf-form-group">
        <label data-number="01">What’s your email address?</label>
        <input
          name="email"
          type="email"
          className={
            formik.errors?.email && formik.touched?.email ? "error" : ""
          }
          value={formik.values.email}
          onChange={formik.handleChange}
          autoComplete="off"
        />
      </div>
      <div className="login-footer">
        <button
          className="ptf-submit-button"
          type="submit"
          disabled={formik.isSubmitting}
        >
          <span>Submit</span>
          {formik.isSubmitting ? <LoadingIndicator /> : <ArrowTopRight />}
        </button>
        <div className="footer-social">
          <EnvatoSignIn />
          <GoogleSignIn />
        </div>
      </div>
    </form>
  );
};

export default RegistrationForm;
