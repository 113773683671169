export const gStoreDocMenu = [
  {
    name: "Requirements",
    routerPath: "/gstore-documentation/mobile-app",
  },
  {
    name: "Installation",
    routerPath: "/gstore-documentation/mandatory-setup-customer",
  },
  {
    name: "Customization",
    routerPath: "/gstore-documentation/customization-customer",
  },
  {
    name: "App build & release",
    routerPath: "/gstore-documentation/customer-app-build-release",
  },
  {
    name: "Firebase setup",
    routerPath: "/gstore-documentation/firebase",
  },
];
