import React from "react";
import { GiCampfire } from "react-icons/gi";
import { Link } from "react-router-dom";

const Front = () => {
  return (
    <div
      className="introduction "
      data-aos="fade-right"
      data-aos-delay="300"
      data-aos-duration="1000"
    >
      <h1 className="title">Admin frontend requirements</h1>
      • NodeJS v16
      <br />
      • React js v17- <br />
      <div className="mt-4" />
      <div className="mt-4" />
      <h3 className="introduction-contentTitle">Tools</h3>
      • For Frontend development: Nodejs , Visual Studio Code or WebStorm <br />
      <h3 className="introduction-contentTitle">Knowledge</h3>• For Frontend
      development: React Js, Google map , Firebase <br />
      <div className="alert alert-danger mt-4" role="alert">
        <div className="mb-2">
          <GiCampfire size={22} /> <strong>WARNING</strong>
        </div>
        We would like to notify that the Envato price doesn’t include any kind
        of installation and app publishing support. We kindly ask you to follow
        the documentation step by step for installation, setup and other
        branding related changes. Please note that, we bear no responsibility
        for your mistake. You are fully in charge for any kind of customizations
        made by your own.
      </div>
      <div className="alert alert-danger mt-4" role="alert">
        <div className="mb-2">
          <GiCampfire size={22} /> <strong>WARNING</strong>
        </div>
        Please do this very carefully. We bear no responsibility for your
        mistake.
      </div>
      <div className="center-page-container">
        <Link
          to="/qrcode-documentation/troubleshooting-backend"
          className="btn  previous"
        >
          <p>Previous</p>
          <p className="link"> Troubleshooting backend </p>
        </Link>
        <Link
          to="/qrcode-documentation/install-on-server"
          className="btn  next"
        >
          <p>Next</p>
          <p className="link"> Installation </p>
        </Link>
      </div>
    </div>
  );
};

export default Front;
