import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

const InstallOnServer = () => {
  return (
    <div
      className="introduction "
      data-aos="fade-right"
      data-aos-delay="300"
      data-aos-duration="1000"
    >
      <h1 className="title"> Install QR code</h1>
      <div className="alert alert-primary mt-3" role="alert">
        <div className="mb-2">
          <AiOutlineInfoCircle size={22} /> <strong>INFO</strong>
        </div>
        Change title inside /public/index.html
      </div>
      {/* <div className="alert alert-primary mt-3" role="alert">
        <div className="mb-2">
          <AiOutlineInfoCircle size={22} /> <strong>INFO</strong>
        </div>
        Thus to install admin_panel, we need subdomains like admin.xyz.com and
        api.xyz.com.
      </div> */}
      {/* <div className="alert alert-warning mt-3" role="alert">
        <div className="mb-2">
          <AiOutlineInfoCircle size={22} /> <strong>Warning</strong>
        </div>
        You have to set SSL certificate for your admin website. Some functions
        doesn't work if your website doesn't have SSL certificate.
      </div> */}
      • Download the code from codecayon <br />• Extract the zip files
      <div className="ptf-spacer" style={{ "--ptf-xxl": "3.75rem" }}></div>
      <p className="mb-0">
        • Create the <strong>dist</strong> File
      </p>
      <p>
        In your application's root directory, run <strong>yarn </strong>
        to install the updated dependencies. Once this has finished, the next
        command you'll run is <strong>yarn build</strong> (
        <strong>npm install</strong> and <strong>npm build</strong> work, too).
      </p>
      <p>
        You'll notice this creates a new directory in your project called
        <strong> dist</strong>. The dist folder is essentially a
        super-compressed version of your program that has everything your
        browser needs to identify and run your app.
      </p>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a
              href="/assets/img/project/qrcode-build.jpg"
              data-fancybox
              rel="nofollow"
            >
              <img
                src="/assets/img/project/qrcode-build.jpg"
                alt="admin config"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <div className="ptf-spacer" style={{ "--ptf-xxl": "3.75rem" }}></div>
      <p className="mb-0">• Connect to cPanel</p>
      <p>Your cPanel manager should look something like this:</p>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a
              href="/assets/img/food-doc/c-pannel.webp"
              data-fancybox
              rel="nofollow"
            >
              <img
                src="/assets/img/food-doc/c-pannel.webp"
                alt="admin config"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <div className="ptf-spacer" style={{ "--ptf-xxl": "3.75rem" }}></div>
      <p className="mb-0">
        • Add the Build File Contents to <strong>public_html</strong>
      </p>
      <p>
        Navigate to the dist file in your app's root directory. Open it up and
        select all the contents <strong>inside the dist file.</strong> If you
        upload the entire dist file itself, the process will not work.
      </p>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a
              href="/assets/img/project/view-qr-build.jpg"
              data-fancybox
              rel="nofollow"
            >
              <img
                src="/assets/img/project/view-qr-build.jpg"
                alt="admin config"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <p>
        Once you've copied all the contents inside the dist file, upload them
        into <strong>public_html.</strong>
      </p>
      <div className="center-page-container">
        <Link
          to="/foodyman-single-documentation/qr-app"
          className="btn  previous"
        >
          <p>Previous</p>
          <p className="link">Requirements </p>
        </Link>
        <Link
          to="/foodyman-single-documentation/firebase"
          className="btn  next"
        >
          <p>Next</p>
          <p className="link"> Firebase setup </p>
        </Link>
      </div>
    </div>
  );
};

export default InstallOnServer;
