import React from "react";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingIndicator from "./Loader/loadingIndicator";
import { ArrowTopRight } from "./Svg/goTop-svg";
import contactService from "../service/ContactService";

const ContactForm = () => {
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      company_name: "",
      phone: "",
      project_goals: "",
    },
    onSubmit: (values, { setSubmitting }) => {
      contactService
        .create(values)
        .then(() => toast.success("Your request has been sent successfully"))
        .finally(() => setSubmitting(false));
    },
    validate: (values) => {
      const errors = {};
      if (!values.name) {
        errors.name = "Required";
      }
      if (!values.email) {
        errors.email = "Required";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
      ) {
        errors.email = "Invalid email address";
      }
      if (!values.project_goals) {
        errors.project_goals = "Required";
      }
      return errors;
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit} className="contact_form">
        <div className="ptf-form-group">
          <label data-number="01">What’s your name?</label>
          <input
            type="text"
            name="name"
            className={
              formik.errors?.name && formik.touched?.name ? "error" : ""
            }
            value={formik.values.name}
            onChange={formik.handleChange}
            autoComplete="off"
          />
        </div>

        <div className="ptf-form-group">
          <label data-number="02">
            What’s the name of your organization? (optional)
          </label>
          <input
            type="text"
            name="company_name"
            className={
              formik.errors?.company_name && formik.touched?.company_name
                ? "error"
                : ""
            }
            value={formik.values.company_name}
            onChange={formik.handleChange}
            autoComplete="off"
          />
        </div>

        <div className="ptf-form-group">
          <label data-number="03">What’s your email address?</label>
          <input
            type="email"
            name="email"
            className={
              formik.errors?.email && formik.touched?.email ? "error" : ""
            }
            value={formik.values.email}
            onChange={formik.handleChange}
            autoComplete="off"
          />
        </div>

        <div className="ptf-form-group">
          <label data-number="04">What’s your phone number? (optional)</label>
          <input
            type="text"
            name="phone"
            className={
              formik.errors?.phone && formik.touched?.phone ? "error" : ""
            }
            value={formik.values.phone}
            onChange={formik.handleChange}
            autoComplete="off"
          />
        </div>

        <div className="ptf-form-group">
          <label data-number="05">Tell us about your project goals.</label>
          <textarea
            name="project_goals"
            className={
              formik.errors?.project_goals && formik.touched?.project_goals
                ? "error"
                : ""
            }
            value={formik.values.project_goals}
            onChange={formik.handleChange}
            autoComplete="off"
          />
        </div>

        <div className="ptf-form-group">
          <div className="has-black-color">
            By submitting this form I consent to having Githubit collect and
            process my personal details and agree with{" "}
            <Link to="/" className="text-decoration-underline text-danger">
              Privacy policy
            </Link>
          </div>
        </div>

        <div className="ptf-spacer" style={{ "--ptf-xxl": "5.625rem" }} />

        <button
          className="ptf-submit-button"
          type="submit"
          disabled={formik.isSubmitting}
        >
          <span>Submit</span>
          {formik.isSubmitting ? <LoadingIndicator /> : <ArrowTopRight />}
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
