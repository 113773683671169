import React from "react";
import { Helmet } from "react-helmet";
import CopyRight from "../../../components/Footer/Copyright/CopyRight";
import HeaderDark from "../../../components/Header/HeaderDark";
import FooterThreeDark from "../../../components/Footer/FooterThreeDark";
import ImageGridDemo from "../../../components/Image-grid/ImageGridDemo";
import OnePage from "../../../components/Hero/OnePage";
import { features } from "../../../fake-data/Sundaymart-feature";
import { RiCustomerService2Fill } from "react-icons/ri";
import { FaUserCog } from "react-icons/fa";
import { AiFillYoutube } from "react-icons/ai";
import { IoDocumentText, IoEarth } from "react-icons/io5";
import { BiRadioCircle } from "react-icons/bi";
import KeyFeatures from "../../../components/KeyFeatures";
import PaymentList from "../../../components/Payment";
import { TbTruckDelivery } from "react-icons/tb";
import { AiOutlineHistory } from "react-icons/ai";
import { TiVendorAndroid } from "react-icons/ti";

const imageList = [
  {
    img: "View all store",
    column: "col-lg-12",
    alt: "Goshops multi shop",
    link: "https://sundaymart.net",
    new: true,
  },
];

const skillContent = [
  {
    icon: <FaUserCog />,
    link: "https://admin.sundaymart.net",
    title: "Admin panel",
    delayAnimation: "100",
    target: "_blank",
  },
  {
    icon: <RiCustomerService2Fill />,
    link: "https://play.google.com/store/apps/details?id=net.sundaymart.user",
    title: "Customer app",
    delayAnimation: "200",
    target: "_blank",
  },
  {
    icon: <TbTruckDelivery />,
    link: "https://testflight.apple.com/join/KYummdg7",
    title: "Delivery app",
    delayAnimation: "300",
    target: "_blank",
  },
  {
    icon: <TiVendorAndroid />,
    link: "https://play.google.com/store/apps/details?id=net.sundaymart.user",
    title: "Vendor app",
    delayAnimation: "300",
    target: "_blank",
  },
  {
    icon: <IoEarth />,
    link: "https://sundaymart.net",
    title: "Website",
    delayAnimation: "400",
    target: "_blank",
  },
  {
    icon: <IoDocumentText />,
    link: "/sundaymart-documentation/introduction",
    title: "Documentation",
    delayAnimation: "600",
    target: "_blank",
  },
  {
    icon: <AiOutlineHistory />,
    link: "/sundaymart-documentation/update",
    title: "Update",
    delayAnimation: "600",
    target: "_blank",
  },
]

export default function SundaymartMultiVendor() {
  return (
    <div className="ptf-site-wrapper animsition ptf-is--work-showcase-1">
      <Helmet>
        <title>Githubit - Sundaymart E-Commerce</title>
      </Helmet>

      <HeaderDark />
      <div className="ptf-site-wrapper__inner">
        <div className="main">
          <article className="ptf-page ptf-page--single-work-1">
            <section>
              <OnePage
                skillContent={skillContent}
                buy_link={
                  "https://codecanyon.net/item/multipurposesinglemultivendorshop-allinoneapps-web-admin-pos-ecommerce-marketplace/39741183?s_rank=5"
                }
                descriptions="Sundaymart is Laravel, Flutter & Next js Framework based online Commerce Full Solution.
                Which provide Admin, Seller Web panel, Website & Flutter (Customer, Delivery Boy, Seller) Mobile App"
                subTitle="Sundaymart - Multi & single vendor grocery e-commerce marketplace (Web, Customer apps, Admin panel)"
                title="Sundaymart"
                img_url="sundaymartGlobal.jpg"
              />
            </section>

            <section>
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "10rem", "--ptf-md": "3.125rem" }}
              />

              <KeyFeatures
                text={
                  "Sundaymart is Laravel, Flutter & Next js Framework based online Commerce Full Solution. Which provide Admin, Seller Web panel, Website & Flutter (Customer, Delivery Boy, Seller) Mobile App"
                }
                features={features}
              />

              <PaymentList />

              <div className="container-xxl">
                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <p className="fz-20 has-black-color">
                    Welcome to Sundaymart – a complete marketplace solution for
                    E-commerce.
                  </p>
                  <p className="fz-20 has-black-color">
                    Wandering what would you get when you purchase Sundaymart?
                    Please read the text and see our visuals below:
                  </p>
                  <p className="fz-20 has-black-color">
                    <strong>All-in-one:</strong> <br />
                    <BiRadioCircle size={10} /> Website; (
                    <a target="_blank" href="https://sundaymart.net">
                      sundaymart.net
                    </a>
                    )
                    <br />
                    <BiRadioCircle size={10} /> Mobile Customer apps
                    <a
                      target="_blank"
                      href="https://codecanyon.net/item/ecommerce-seller-mobile-application-goshops/41074014?s_rank=4"
                    >
                      ( Android and iOS )
                    </a>
                    <br />
                    <BiRadioCircle size={10} />{" "}
                    <a target="_blank" href="https://admin.sundaymart.net">
                      Admin Panel (web);
                    </a>
                    <br />
                    <BiRadioCircle size={10} /> POS system (MacOS, Linux,
                    Windows desktop); <br />A Vendor app is available but sold
                    separately.
                  </p>
                  <p className="fz-20 has-black-color">
                    <strong>SEO and lighting fast page loading times: </strong>
                    If you already have or istrive your E-commerce to expand
                    beyond your neighborhood, you will need a solution that
                    provides
                    <strong>
                      an excellent page load time, search engine optimization
                      (SEO) and high-throughput readiness.
                    </strong>
                    Sundaymart uses various technologies to offer these key
                    features. Specifically, we built the front-end (website)
                    with Nextjs to maximize page loading time and SEO (please
                    note Flutter based websites do not offer best SEO). Our
                    database is optimized for high throughput (no impact on
                    performance in a live project with over 70,000 unique
                    products).
                  </p>
                  <p className="fz-20 has-black-color">
                    <strong>Single Shop and Multi-Shop options</strong>
                  </p>
                  <p className="fz-20 has-black-color">
                    <strong>A growing list of features:</strong> <br />
                    <BiRadioCircle size={10} /> Top-notch UI design; <br />
                    <BiRadioCircle size={10} /> Time and revenue based
                    subscription (extended license);
                    <br />
                    <BiRadioCircle size={10} /> Cashback; <br />
                    <BiRadioCircle size={10} /> Recipes;
                    <br />
                    <BiRadioCircle size={10} /> An extensive list of reports.
                    <br />
                    <BiRadioCircle size={10} /> Refund; <br />
                    <BiRadioCircle size={10} /> Multiple zone selection for
                    deliveries; <br />
                    <BiRadioCircle size={10} /> Simplified product replacement;
                    <br />
                    <BiRadioCircle size={10} /> Group order; <br />
                    <BiRadioCircle size={10} /> Email signup and subscription;{" "}
                    <br />
                    <BiRadioCircle size={10} /> E-Order board and many more.
                    <br />
                  </p>
                  <p className="fz-20 has-black-color">
                    <strong>A single stock keeping unit option: </strong> <br />
                    designed with a range of department stores in mind (Grocery,
                    Electronics, Pharmacy, Furniture, and etc.) where vendors
                    sell the same products so no need to create multiple copies
                    of the same product in the marketplace. A scan code will
                    help vendors find a product in the marketplace inventory
                    that can be quickly added to the store to avoid the hassle
                    of creating new products.
                  </p>
                  <p className="fz-20 has-black-color">
                    <strong className="fas fa-band-aid">
                      Core technologies:
                    </strong>
                    <br />
                    <BiRadioCircle size={10} /> Laravel (backend) <br />
                    <BiRadioCircle size={10} /> Flutter (mobile apps)
                    <br />
                    <BiRadioCircle size={10} /> Reactjs (admin panels)
                    <br />
                    <BiRadioCircle size={10} /> Typescript and Nextjs (website)
                    <br />
                    <BiRadioCircle size={10} /> Rest API
                  </p>
                </div>

                <div className="mt-4" />

                <div
                  className="ptf-divider"
                  style={{
                    "--ptf-height": "1px",
                    "--ptf-color": "var(--ptf-color-14)",
                  }}
                />

                <div className="mt-5" />

                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <h2>All demos</h2>
                </div>

                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "2.8125rem" }}
                />

                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <ImageGridDemo list={imageList} />
                </div>

                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "2.8125rem" }}
                />
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "2.8125rem" }}
                />
              </div>
            </section>
          </article>
        </div>
      </div>

      <footer className="ptf-footer ptf-footer--style-5">
        <div className="container-xxl">
          <div
            className="ptf-divider"
            style={{
              "--ptf-height": "1px",
              "--ptf-color": "var(--border-color)",
            }}
          />
          <div className="ptf-footer__top">
            <FooterThreeDark />
          </div>

          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
  );
}
