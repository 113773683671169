import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../components/Loader";
import { useQueryParams } from "../../../../hook/UseQueryParams";
import { setUserData } from "../../../../redux/slices/auth";
import authService from "../../../../service/AuthService";

export default function EnvatoAuth() {
  const { values } = useQueryParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    authService
      .envatoAuth({ code: values.code })
      .then((res) => {
        const token = res.data.access_token;
        localStorage.setItem("token", token);
        dispatch(
          setUserData({ ...res.data, ...res.data.user, user: undefined })
        );
      })
      .finally(() => navigate("/"));
  }, []);

  return (
    <div className="ptf-main">
      <div className="ptf-page ptf-page--contact">
        <section>
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: "100vh" }}
          >
            <Loader />
          </div>
        </section>
      </div>
    </div>
  );
}
