import React, {useState} from 'react'
import {AiOutlineEye, AiOutlineEyeInvisible} from "react-icons/ai";

export default function InputPassword(props) {
    const [passwordType, setPasswordType] = useState('password')

    const togglePassword = () => {
        if (passwordType === 'password') {
            setPasswordType('text')
            return
        }
        setPasswordType('password')
    }

    return (
        <div>
            <div style={{display: 'flex'}}>
                <input
                    autoComplete='off'
                    {...props}
                    type={passwordType}
                    style={{position: "relative"}}
                />
                <button
                    type='button'
                    style={{position: 'absolute', right: 0, bottom: 30}}
                    className="ptf-submit-button"
                    onClick={togglePassword}
                >
                    {passwordType === 'password' ? (
                        <AiOutlineEye/>
                    ) : (
                        <AiOutlineEyeInvisible/>
                    )}
                </button>
            </div>
        </div>
    )
}
