import React, { useState } from "react";
import { useFormik } from "formik";
import { shallowEqual, useSelector } from "react-redux";
import ticketService from "../../../../service/TicketService";
import UploadButton from "../../../../components/UploadButton";
import { MdDeleteForever } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import LoadingIndicator from "../../../../components/Loader/loadingIndicator";
import { ArrowTopRight } from "../../../../components/Svg/goTop-svg";
import { Link } from "react-router-dom";

const NewTicketForm = () => {
  const { user } = useSelector((state) => state.auth, shallowEqual);
  const navigate = useNavigate();
  const [image, setImage] = useState([]);
  const [loadingBtn, setLoadingBtn] = useState(false);

  const formik = useFormik({
    initialValues: {
      subject: "",
      message: "",
    },
    onSubmit: (values, { setSubmitting }) => {
      const body = {
        subject: values.subject,
        message: values.message,
        images: image.length ? image : undefined,
        client_id: user.id,
      };
      ticketService
        .create(body)
        .then(() => {
          formik.resetForm();
          navigate("/tickets");
          setImage([]);
        })
        .finally(() => setSubmitting(false));
    },
    validate: (values) => {
      const errors = {};
      if (!values.subject) {
        errors.subject = "Required";
      }
      if (!values.message) {
        errors.message = "Required";
      }
      return errors;
    },
  });

  const handleChange = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("type", "service");
    setLoadingBtn(true);
    ticketService
      .fileUpload(formData)
      .then((res) => setImage([...image, res.data.title]))
      .finally(() => setLoadingBtn(false));
  };

  const handleDelete = (index) => {
    image.splice(index, 1);
  };

  return (
    <form onSubmit={formik.handleSubmit} className="contact_form">
      <div className="ptf-form-group">
        <label data-number="01">Subject</label>
        <input
          type="text"
          name="subject"
          onChange={formik.handleChange}
          value={formik.values.subject}
          autoComplete="off"
          className={
            formik.errors?.subject && formik.touched?.subject ? "error" : ""
          }
        />
      </div>

      <div className="ptf-form-group">
        <label data-number="02">Message</label>
        <textarea
          name="message"
          onChange={formik.handleChange}
          value={formik.values.message}
          autoComplete="off"
          className={
            formik.errors?.message && formik.touched?.message ? "error" : ""
          }
        />
      </div>

      <div className="upload-group">
        <label data-number="03">Images</label>
        <label htmlFor="file" className="fileInput">
          <div className="uploadButton">
            <input hidden id="file" type="file" onChange={handleChange} />
            <UploadButton loading={loadingBtn} />
          </div>
          {image?.map((file, index) => (
            <div key={index} className="imageContainer">
              <img
                src={process.env.REACT_APP_BASE_URL + file}
                alt="images"
                className="images"
              />
              <button onClick={() => handleDelete(index)} className="delete">
                <MdDeleteForever className="deleteIcon" color={"white"} />
              </button>
            </div>
          ))}
        </label>
      </div>

      <div className="ptf-spacer" style={{ "--ptf-xxl": "2.5rem" }} />

      <div className="ptf-form-group">
        <div className="">
          By submitting this form I consent to having Githubit collect and
          process my personal details and agree with{" "}
          <Link to="/privacy-policy" className="text-decoration-underline">
            Privacy policy
          </Link>
        </div>
      </div>

      <div className="ptf-spacer" style={{ "--ptf-xxl": "5.625rem" }} />

      <div className="d-flex justify-content-between align-items-center">
        <button
          className="ptf-submit-button"
          type="submit"
          disabled={formik.isSubmitting}
        >
          <span>Submit</span>
          {formik.isSubmitting ? <LoadingIndicator /> : <ArrowTopRight />}
        </button>
      </div>
    </form>
  );
};
export default NewTicketForm;
