import React from "react";
import { GiCampfire } from "react-icons/gi";
import { Link } from "react-router-dom";
import NavigationBtns from "./Navigation-btns";

const Front = () => {
  return (
    <div
      className="introduction "
      data-aos="fade-right"
      data-aos-delay="300"
      data-aos-duration="1000"
    >
      <h1 className="title">Frontend requirements</h1>
      • NodeJS v16+
      <br />
      • Vite v4+ <br />
      • ReactJS v18+ <br />
      <div className="mt-4" />
      <div className="mt-4" />
      <h3 className="introduction-contentTitle">Tools</h3>
      • For Frontend development: Nodejs , Visual Studio Code or WebStorm <br />
      <h3 className="introduction-contentTitle">Knowledge</h3>• For Frontend
      development: Vite , React Js <br />
      <div className="alert alert-danger mt-4" role="alert">
        <div className="mb-2">
          <GiCampfire size={22} /> <strong>WARNING</strong>
        </div>
        We would like to notify that the Envato price doesn’t include any kind
        of installation and app publishing support. We kindly ask you to follow
        the documentation step by step for installation, setup and other
        branding related changes. Please note that, we bear no responsibility
        for your mistake. You are fully in charge for any kind of customizations
        made by your own.
      </div>
      <div className="alert alert-danger mt-4" role="alert">
        <div className="mb-2">
          <GiCampfire size={22} /> <strong>WARNING</strong>
        </div>
        Please do this very carefully. We bear no responsibility for your
        mistake.
      </div>
      <NavigationBtns />
    </div>
  );
};

export default Front;
