import React from "react";
import { AiOutlineSwapRight } from "react-icons/ai";

const HeroModern = ({ skillContent }) => {
  return (
    <div className="container-xxl">
      <div className="row align-items-center">
        <div className="col-xl-6">
          <div
            className="ptf-spacer"
            style={{ "--ptf-xxl": "2.5rem", "--ptf-md": "1.25rem" }}
          />
          <div
            className="ptf-animated-block"
            data-aos="fade"
            data-aos-delay="100"
          >
            <h2 className="large-heading fw-semibold ">
              <span style={{ color: "green" }}>Githubit</span> - Multi-vendor
              e-commerce marketplace and also booking system (Website + Customer/Courier apps + POS
              +Admin panel)
            </h2>
          </div>
          <div
            className="ptf-spacer"
            style={{
              "--ptf-xxl": "4.375rem",
              "--ptf-md": "2.1875rem",
            }}
          />
          <div
            className="ptf-animated-block"
            data-aos="fade"
            data-aos-delay="200"
          >
            <div
              className="ptf-animated-block"
              data-aos="fade"
              data-aos-delay="0"
            >
              <p className="fz-18 mb-3 has-white-color">
                We are young but experienced explorers that are curious and
                passionate about information technology, graphic design and
                well, every thing we do. We pride ourselves on our honesty,
                great sense of creativity, and commitment to creating products
                that last and of great value to our clients. We’d love to talk
                to you, listen to your ideas and help you find the best ways
                your business can excel tooled with different information
                technologies.
              </p>
            </div>
          </div>

          <a
            target="_blank"
            href="https://codecanyon.net/user/githubit/portfolio"
          >
            <button className="outlined-btn mb-3">
              Buy Now <AiOutlineSwapRight size={30} />
            </button>
          </a>

          <div className="hero-modern mt-3">
            <ul>
              {skillContent.map((item, i) => (
                <li key={i + item?.title}>
                  <a href={item?.link} className="has-white-color">
                    {item?.icon}
                    {item?.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="col-xl-6">
          <div
            className="ptf-animated-block"
            data-aos="fade"
            data-aos-delay="400"
          >
            <div className="text-center">
              <div className="ptf-mask-image ">
                <img
                  src="/assets/img/project/18.png"
                  alt="layer"
                  loading="lazy"
                  className="rounded"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroModern;
