import request from "./api-interceptor";

const ticketService = {
  create: (data) => request.post("api/v1/ticket", data),
  getTickets: (params) => request.get("api/v1/ticket", { params }),
  showTickets: (data) => request.get(`api/v1/ticket/${data}`),
  chat: (params) => request.post("/api/v1/chat", params, null),
  fileUpload: (params) => request.post("/api/v1/gallery", params, null),
  rate: (id, data) => request.post(`/api/v1/ticket/rate/${id}`, data),
};

export default ticketService;
