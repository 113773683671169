import React from "react";
import {BsFillBagCheckFill} from "react-icons/bs";
import {AiOutlineDollarCircle} from "react-icons/ai";
import {RiMailSendLine} from "react-icons/ri";

const careerContent = () => {
    return (
        <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="frontend" role="tabpanel"
                 aria-labelledby="home-tab">
                <div className="card-header">
                    <h4 className="card-header-title">Frontend Developer</h4>
                    <div className="card-header-body">Githubit IT company</div>
                    <div className="card-header-body">Tashkent Uzbekistan</div>
                    <div className="card-header-body">$1,000 - $2,000 a month - Full-time</div>
                    <a href="/career/form">
                        <button type="button" className="apple-button">
                            Apply on Githubit <RiMailSendLine/>
                        </button>
                    </a>

                </div>
                <div className="card-body">
                    <h5>Job details</h5>
                    No matching job preferences
                    <span className="job-title">
                        <AiOutlineDollarCircle/> Salary
                   </span>
                    <span className="price"> $1,000 - $2,000 a month</span>

                    <span className="job-title">
                        <BsFillBagCheckFill/> Job Type <br/>
                    </span>
                    <div className="card-status">
                        <span className="status"> Full-time</span>
                        <span className="status"> Remote</span>
                    </div>
                    <br/>
                    <h5>Full Job Description</h5>
                    Frontend Developer <br/>
                    A career with Gravity Payments is an opportunity to be on a collaborative team where creative
                    leadership, passion for progress, and responsibility are paramount. Our team members focus and
                    commit to providing for our clients and our community because we care deeply for others.
                    This is a full-time position with our Portals team. This team builds, maintains and supports an
                    established SaaS platform that helps small to enterprise-sized organizations accept online payments
                    and interface with customers and clients directly. We are looking for a full stack engineer to join
                    us in helping to expand and improve the platform.
                    We are a small, entrepreneurial team with a strong sense of ownership and willingness to step in
                    wherever needed. All candidates must bring a high degree of both competence and humility. <br/>
                    <br/>
                    Job Responsibilities <br/>
                    Write code to enhance the platform, solve problems, and correct defects
                    Support the enhancement, technical execution and operation of the platform.
                    Work with team members, business stakeholders, as well as Engineering and Product Managers to
                    provide analysis, insight, and guidance on the direction of the platform.
                    Assist in prioritizing the engineering work assignments and deliverables for contract software
                    engineers located remotely.
                    Perform code review, merge code to master, coordinate and perform deployments, and troubleshoot
                    production issues.
                    Manage the technical support of the platform for existing clients and assist in the onboarding of
                    new clients.
                    Mentor junior team members and foster a sense of shared responsibility.
                    Technology Background

                    PHP – The platform consists of a backend and frontend that are both written in PHP; the frontend
                    utilizes Bootstrap/JQuery framework.
                    MySQL/Aurora RDS – Data is persisted in a relational database provided by the MySQL flavor of Aurora
                    RDS on AWS.
                    AWS – The platform makes use of a number of AWS technologies: EC2, Aurora RDS, Cloud Watch, SES, and
                    S3.
                    Unix-like development system – Current developers use MacOS or Linux and Unix tools for development,
                    and the technical operation of the system requires usage of a command-line environment.
                    Experience with distributed computing and enterprise-wide systems with high availability
                    Hands-on expertise in many disparate technologies, typically ranging from front-end user interfaces
                    through to back-end systems and all points in between
                    Knowledge of virtualization technologies (e.g.: Docker, Vagrant, etc…)
                    Experience with native and/or hybrid mobile development (e.g.: iOS, Android, PhoneGap, ionic, etc…)
                    Candidates will need to have experience with each of the above (PHP required), and has 5-7 years
                    experience in the development field.
                    Remote office requirements:

                    Must have access to a wired internet connection
                    Must have access to at least a 25 megabits per second (mbps) download and 20 mbps upload speed
                    connection
                    The salary for this position is $120-140k. We are open to negotiating outside of this range if the
                    desired salary aligns with the needs of the candidate and company.
                    This is a salaried position with no commission.
                    Gravity Payments is an equal opportunity employer, and all qualified applicants will receive
                    consideration for employment without regard to race, color, religion, sex, national origin, sexual
                    orientation, gender identity/expression, age, disability status, protected veteran status, or any
                    other characteristic protected by law. <br/> <br/>

                    Job Type: Full-time <br/>

                    Pay: $1,000 - $2,000 a month <br/>

                    Hiring Insights <br/>
                    Job activity <br/>
                </div>
            </div>
            <div className="tab-pane fade" id="backend" role="tabpanel" aria-labelledby="profile-tab">
                <div className="card-header">
                    <h4 className="card-header-title">Backend Developer</h4>
                    <div className="card-header-body">Githubit IT company</div>
                    <div className="card-header-body">Tashkent Uzbekistan</div>
                    <div className="card-header-body">$1,000 - $2,000 a month - Full-time</div>
                    <a href="/career/form">
                        <button type="button" className="apple-button">
                            Apply on Githubit <RiMailSendLine/>
                        </button>
                    </a>
                </div>
                <div className="card-body">
                    <h5>Job details</h5>
                    No matching job preferences
                    <span className="job-title">
                        <AiOutlineDollarCircle/> Salary
                   </span>
                    <span className="price"> $1,000 - $2,000 a month</span>

                    <span className="job-title">
                        <BsFillBagCheckFill/> Job Type <br/>
                    </span>
                    <div className="card-status">
                        <span className="status"> Full-time</span>
                        <span className="status"> Remote</span>
                    </div>
                    <br/>
                    <h5>Full Job Description</h5>
                    Frontend Developer <br/>
                    A career with Gravity Payments is an opportunity to be on a collaborative team where creative
                    leadership, passion for progress, and responsibility are paramount. Our team members focus and
                    commit to providing for our clients and our community because we care deeply for others.
                    This is a full-time position with our Portals team. This team builds, maintains and supports an
                    established SaaS platform that helps small to enterprise-sized organizations accept online payments
                    and interface with customers and clients directly. We are looking for a full stack engineer to join
                    us in helping to expand and improve the platform.
                    We are a small, entrepreneurial team with a strong sense of ownership and willingness to step in
                    wherever needed. All candidates must bring a high degree of both competence and humility. <br/>
                    <br/>
                    Job Responsibilities <br/>
                    Write code to enhance the platform, solve problems, and correct defects
                    Support the enhancement, technical execution and operation of the platform.
                    Work with team members, business stakeholders, as well as Engineering and Product Managers to
                    provide analysis, insight, and guidance on the direction of the platform.
                    Assist in prioritizing the engineering work assignments and deliverables for contract software
                    engineers located remotely.
                    Perform code review, merge code to master, coordinate and perform deployments, and troubleshoot
                    production issues.
                    Manage the technical support of the platform for existing clients and assist in the onboarding of
                    new clients.
                    Mentor junior team members and foster a sense of shared responsibility.
                    Technology Background

                    PHP – The platform consists of a backend and frontend that are both written in PHP; the frontend
                    utilizes Bootstrap/JQuery framework.
                    MySQL/Aurora RDS – Data is persisted in a relational database provided by the MySQL flavor of Aurora
                    RDS on AWS.
                    AWS – The platform makes use of a number of AWS technologies: EC2, Aurora RDS, Cloud Watch, SES, and
                    S3.
                    Unix-like development system – Current developers use MacOS or Linux and Unix tools for development,
                    and the technical operation of the system requires usage of a command-line environment.
                    Experience with distributed computing and enterprise-wide systems with high availability
                    Hands-on expertise in many disparate technologies, typically ranging from front-end user interfaces
                    through to back-end systems and all points in between
                    Knowledge of virtualization technologies (e.g.: Docker, Vagrant, etc…)
                    Experience with native and/or hybrid mobile development (e.g.: iOS, Android, PhoneGap, ionic, etc…)
                    Candidates will need to have experience with each of the above (PHP required), and has 5-7 years
                    experience in the development field.
                    Remote office requirements:

                    Must have access to a wired internet connection
                    Must have access to at least a 25 megabits per second (mbps) download and 20 mbps upload speed
                    connection
                    The salary for this position is $120-140k. We are open to negotiating outside of this range if the
                    desired salary aligns with the needs of the candidate and company.
                    This is a salaried position with no commission.
                    Gravity Payments is an equal opportunity employer, and all qualified applicants will receive
                    consideration for employment without regard to race, color, religion, sex, national origin, sexual
                    orientation, gender identity/expression, age, disability status, protected veteran status, or any
                    other characteristic protected by law. <br/> <br/>

                    Job Type: Full-time <br/>

                    Pay: $1,000 - $2,000 a month <br/>

                    Hiring Insights <br/>
                    Job activity <br/>
                </div>
            </div>
            <div className="tab-pane fade" id="flutter" role="tabpanel" aria-labelledby="contact-tab">
                <div className="card-header">
                    <h4 className="card-header-title">Flutter Developer</h4>
                    <div className="card-header-body">Githubit IT company</div>
                    <div className="card-header-body">Tashkent Uzbekistan</div>
                    <div className="card-header-body">$1,000 - $2,000 a month - Full-time</div>
                    <a href="/career/form">
                        <button type="button" className="apple-button">
                            Apply on Githubit <RiMailSendLine/>
                        </button>
                    </a>
                </div>
                <div className="card-body">
                    <h5>Job details</h5>
                    No matching job preferences
                    <span className="job-title">
                        <AiOutlineDollarCircle/> Salary
                   </span>
                    <span className="price"> $1,000 - $2,000 a month</span>

                    <span className="job-title">
                        <BsFillBagCheckFill/> Job Type <br/>
                    </span>
                    <div className="card-status">
                        <span className="status"> Full-time</span>
                        <span className="status"> Remote</span>
                    </div>
                    <br/>
                    <h5>Full Job Description</h5>
                    Frontend Developer <br/>
                    A career with Gravity Payments is an opportunity to be on a collaborative team where creative
                    leadership, passion for progress, and responsibility are paramount. Our team members focus and
                    commit to providing for our clients and our community because we care deeply for others.
                    This is a full-time position with our Portals team. This team builds, maintains and supports an
                    established SaaS platform that helps small to enterprise-sized organizations accept online payments
                    and interface with customers and clients directly. We are looking for a full stack engineer to join
                    us in helping to expand and improve the platform.
                    We are a small, entrepreneurial team with a strong sense of ownership and willingness to step in
                    wherever needed. All candidates must bring a high degree of both competence and humility. <br/>
                    <br/>
                    Job Responsibilities <br/>
                    Write code to enhance the platform, solve problems, and correct defects
                    Support the enhancement, technical execution and operation of the platform.
                    Work with team members, business stakeholders, as well as Engineering and Product Managers to
                    provide analysis, insight, and guidance on the direction of the platform.
                    Assist in prioritizing the engineering work assignments and deliverables for contract software
                    engineers located remotely.
                    Perform code review, merge code to master, coordinate and perform deployments, and troubleshoot
                    production issues.
                    Manage the technical support of the platform for existing clients and assist in the onboarding of
                    new clients.
                    Mentor junior team members and foster a sense of shared responsibility.
                    Technology Background

                    PHP – The platform consists of a backend and frontend that are both written in PHP; the frontend
                    utilizes Bootstrap/JQuery framework.
                    MySQL/Aurora RDS – Data is persisted in a relational database provided by the MySQL flavor of Aurora
                    RDS on AWS.
                    AWS – The platform makes use of a number of AWS technologies: EC2, Aurora RDS, Cloud Watch, SES, and
                    S3.
                    Unix-like development system – Current developers use MacOS or Linux and Unix tools for development,
                    and the technical operation of the system requires usage of a command-line environment.
                    Experience with distributed computing and enterprise-wide systems with high availability
                    Hands-on expertise in many disparate technologies, typically ranging from front-end user interfaces
                    through to back-end systems and all points in between
                    Knowledge of virtualization technologies (e.g.: Docker, Vagrant, etc…)
                    Experience with native and/or hybrid mobile development (e.g.: iOS, Android, PhoneGap, ionic, etc…)
                    Candidates will need to have experience with each of the above (PHP required), and has 5-7 years
                    experience in the development field.
                    Remote office requirements:

                    Must have access to a wired internet connection
                    Must have access to at least a 25 megabits per second (mbps) download and 20 mbps upload speed
                    connection
                    The salary for this position is $120-140k. We are open to negotiating outside of this range if the
                    desired salary aligns with the needs of the candidate and company.
                    This is a salaried position with no commission.
                    Gravity Payments is an equal opportunity employer, and all qualified applicants will receive
                    consideration for employment without regard to race, color, religion, sex, national origin, sexual
                    orientation, gender identity/expression, age, disability status, protected veteran status, or any
                    other characteristic protected by law. <br/> <br/>

                    Job Type: Full-time <br/>

                    Pay: $1,000 - $2,000 a month <br/>

                    Hiring Insights <br/>
                    Job activity <br/>
                </div>
            </div>
        </div>
    );
};

export default careerContent;