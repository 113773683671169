import React from "react";
import { Helmet } from "react-helmet";
import HeaderDark from "../Header/HeaderDark";
import { useFormik } from "formik";
import authService from "../../service/AuthService";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setUserData } from "../../redux/slices/auth";

const UserProfile = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth, shallowEqual);

  const formik = useFormik({
    initialValues: {
      gender: "",
      firstname: "",
      lastname: "",
    },
    onSubmit: (values, { setSubmitting }) => {
      const body = {
        gender: values.gender,
        firstname: values.firstname,
        lastname: values.lastname,
      };
      authService
        .login(body)
        .then((res) => {
          dispatch(
            setUserData({ ...res.data, ...res.data.user, user: res.data })
          );
        })
        .finally(() => setSubmitting(false));
    },
    validate: (values) => {
      const errors = {};
      if (!values.lastname) {
        errors.lastname = "Required lastname";
      }
      if (!values.firstname) {
        errors.firstname = "Required firstname";
      }
      return errors;
    },
  });

  console.log("user", user);
  return (
    <div className="container-xxl user-profile">
      <Helmet>
        <title>GitHubit</title>
      </Helmet>

      <HeaderDark />

      <section className="container">
        <form onSubmit={formik.handleSubmit} name="login">
          <div className="row">
            <div className="col-lg-4 mb-sm-3">
              <div className="card">
                <div className="card-body text-center">
                  <img
                    src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3.webp"
                    alt="avatar"
                    className="rounded-circle img-fluid"
                    style={{ width: "150px" }}
                  />
                  <h5 className="my-3">John Smith</h5>
                  <div className="d-flex justify-content-center pt-3">
                    <button
                      className="btn btn-primary me-3"
                      type="submit"
                      disabled={formik.isSubmitting}
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-primary ms-1"
                    >
                      Update password
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-8">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Firstname</p>
                    </div>
                    <div className="col-sm-9">
                      <input
                        name="firstname"
                        className="text-muted w-75 rounded ps-2 pe-2"
                        value={formik.values.firstname}
                        onChange={formik.handleChange}
                      />
                      <br />
                      {formik.errors?.firstname && formik.touched?.firstname
                        ? "error"
                        : ""}
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Lastname</p>
                    </div>
                    <div className="col-sm-9">
                      <input
                        className="text-muted w-75 rounded ps-2 pe-2"
                        name="lastname"
                        value={formik.values.lastname}
                        onChange={formik.handleChange}
                      />
                      <br />

                      {formik.errors?.lastname && formik.touched?.lastname
                        ? "error"
                        : ""}
                    </div>
                  </div>
                  <hr />

                  <div className="row ">
                    <div className="col-sm-3">
                      <p className="mb-0">Gender</p>
                    </div>
                    <div className="col-sm-9 w-75">
                      <select
                        name="gender"
                        value={formik.values.gender}
                        onChange={formik.handleChange}
                      >
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>
                    </div>
                  </div>

                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Email</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0">example@example.com</p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Phone</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0">(097) 234-5678</p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Address</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0">
                        Bay Area, San Francisco, CA
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
};

export default UserProfile;
