import React from "react";
import { Helmet } from "react-helmet";
import CounterTwo from "../../../components/Counter/CounterTwo";
import CopyRight from "../../../components/Footer/Copyright/CopyRight";
import FooterThreeDark from "../../../components/Footer/FooterThreeDark";
import HeaderDark from "../../../components/Header/HeaderDark";
import HeroAgency from "../../../components/Hero/HeroAgency";
import PortfolioFive from "../../../components/Portfolio/PortfolioFive";
import { portfolioContent } from "../../../components/Portfolio/portfolioWebContent";

const AllWebProduct = () => {
  return (
    <div className="ptf-site-wrapper animsition ptf-is--home-agency">
      <Helmet>
        <title>Foodyman</title>
      </Helmet>
      {/* End Page SEO Content */}

      <div className="ptf-site-wrapper__inner">
        <HeaderDark />
        {/* End Header Agency */}

        <div className="ptf-main">
          <div className="ptf-page ptf-page--home-agency">
            {/*=============================================
          Start Our Hero Section howcase 3
          ============================================== */}
            <div className="ptf-showcase-3">
              <HeroAgency />
            </div>

            {/*=============================================
          Start Call to Counter section
          ============================================== */}
            <section>
              {/* <!--Spacer--> */}
              <div className="ptf-spacer" style={{ "--ptf-xxl": "3.75rem" }} />
              <div className="container">
                <div
                  className="row align-items-center"
                  style={{ "--bs-gutter-y": "2rem" }}
                >
                  <div className="col-12 col-xl-6">
                    {/* <!--Animated Block--> */}
                    <div
                      className="ptf-animated-block"
                      data-aos="fade"
                      data-aos-delay="0"
                    >
                      {/* <!--Phone Block--> */}
                      <div className="ptf-phone-block">
                        <div className="ptf-phone-block__icon">
                          <i className="lnil lnil-phone" />{" "}
                        </div>
                        <div className="ptf-phone-block__caption has-white-color">
                          Call Us
                          <br />
                          Directly
                        </div>
                        <div className="ptf-phone-block__phone has-white-color">
                          <a href="tel:+12023401032">(+1) 2023401032</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* End .col */}
                  <CounterTwo />
                </div>
              </div>
              {/* <!--Spacer--> */}
              <div className="ptf-spacer" style={{ "--ptf-xxl": "3.75rem" }} />
            </section>

            <section>
              <div className="container">
                {/* <!--Divider--> */}
                <div className="ptf-divider" />
              </div>
            </section>
            {/* End .ptf-devider */}

            <section>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "8.125rem", "--ptf-md": "4.0625rem" }}
              />
            </section>
            {/* End  Advertising Big Title */}

            <section className="pb-5">
              <div className="container mb-5">
                <PortfolioFive content={portfolioContent} />
              </div>
            </section>

            <footer className="ptf-footer ptf-footer--style-5">
              <div className="container-xxl">
                <div
                  className="ptf-divider"
                  style={{
                    "--ptf-height": "1px",
                    "--ptf-color": "var(--border-color)",
                  }}
                />
                <div className="ptf-footer__top">
                  <FooterThreeDark />
                </div>

                <div className="ptf-footer__bottom">
                  <CopyRight />
                </div>
              </div>
            </footer>
          </div>
          {/* End .ptf-page */}
        </div>
        {/* End .ptf-main */}
      </div>
      {/* End .ptf-site-wrapper__inner */}
    </div>
  );
};

export default AllWebProduct;
