import React from "react";
import { Helmet } from "react-helmet";
import CopyRight from "../../components/Footer/Copyright/CopyRight";
import HeaderSunday from "../../components/Header/HeaderSunday";
import DocumentationMenu from "../../components/DocMenu/demand24";
import { docMenu } from "../../fake-data/Demand24-doc-menu";
import { Outlet } from "react-router-dom";

const Documentation = () => {
  return (
    <div className="docContainer">
      <Helmet>
        <title>GitHubit - Demand24 Documentation</title>
      </Helmet>
      <HeaderSunday docMenu={docMenu} />
      <div className="ptf-main">
        <div className="ptf-page ptf-page--contact">
          <section>
            <div className="container-xxl">
              <div className="row">
                <div className="col-lg-3">
                  <div className="docMenuContainer">
                    <DocumentationMenu />
                  </div>
                </div>
                <div className="col-lg-9 docContent">
                  <Outlet />
                </div>
              </div>
            </div>
          </section>
        </div>
        <div
          className="ptf-spacer"
          style={{ "--ptf-xxl": "3rem", "--ptf-md": "2.5rem" }}
        />
      </div>

      <footer className="ptf-footer ptf-footer--style-5 ptf-footer-doc">
        <div className="container-xxl">
          <CopyRight />
        </div>
      </footer>
    </div>
  );
};

export default Documentation;
