import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";

const PaymentInstallation = () => {
  return (
    <div
      className="introduction"
      data-aos="fade-right"
      data-aos-delay="300"
      data-aos-duration="1000"
    >
      <h1 className="title">Install Payments</h1>
      <div className="alert alert-primary mt-3" role="alert">
        <div className="mb-2">
          <AiOutlineInfoCircle size={22} /> <strong>INFO</strong>
        </div>
        All other payment methods are done in a similar way through the admin panel and adding a webhook on the payment system website
      </div>
      <div className="alert alert-danger mt-3" role="alert">
        <div className="mb-2">
          <AiOutlineInfoCircle size={22} /> <strong>Warning</strong>
        </div>
        Webhook urls: <br/>
         - stripe: <b>https://your-api-url.com/api/v1/webhook/stripe/payment</b> <br/>
         - razorpay: <b>https://your-api-url.com/api/v1/webhook/razorpay/payment</b> <br/>
         - paystack: <b>https://your-api-url.com/api/v1/webhook/paystack/payment</b> <br/>
         - paytabs: <b>https://your-api-url.com/api/v1/webhook/paytabs/payment</b> <br/>
         - flutterwave: <b>https://your-api-url.com/api/v1/webhook/flw/payment</b> <br/>
         - paypal: <b>https://your-api-url.com/api/v1/webhook/paypal/payment</b> <br/>
         - mercado-pago: <b>https://your-api-url.com/api/v1/webhook/mercado-pago/payment</b> <br/>
         - moyasar: <b>https://your-api-url.com/api/v1/webhook/moya-sar/payment</b> <br/>
         - mollie: <b>https://your-api-url.com/api/v1/webhook/mollie/payment</b> <br/>
      </div>
      <div className="ptf-spacer" style={{ "--ptf-xxl": "3.75rem" }}></div>
      <p>
        • Stripe integration.
      </p>
      <div className="introduction-img-container">
        <video src="/assets/video/stripe.mp4" width="100%" height="300" controls="controls" autoPlay={false}/>
      </div>
      <div className="ptf-spacer" style={{ "--ptf-xxl": "3.75rem" }}></div>
      <p>
        • Paystack integration.
      </p>
      <div className="introduction-img-container">
        <video src="/assets/video/paystack.mp4" width="100%" height="300" controls="controls" autoPlay={false}/>
      </div>
      <div className="ptf-spacer" style={{ "--ptf-xxl": "3.75rem" }}></div>
      <p>
        • Razorpay integration.
      </p>
      <div className="introduction-img-container">
        <video src="/assets/video/razorpay.mp4" width="100%" height="300" controls="controls" autoPlay={false}/>
      </div>
      <div className="ptf-spacer" style={{ "--ptf-xxl": "3.75rem" }}></div>
    </div>
  );
};

export default PaymentInstallation;
