import React from "react";
import { Link } from "react-router-dom";

const LocalServer = () => {
  return (
    <div
      className="introduction "
      data-aos="fade-right"
      data-aos-delay="300"
      data-aos-duration="1000"
    >
      <h1 className="title">Backend</h1>
      <p className="inner-text">
        {" "}
        To run backend in your local machine, install one of these programs in
        your computer:{" "}
      </p>
      <div className="mt-4" />
      <div className="iframe-wrapper">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/xy3qeGYQNtw"
          title="How to setup Docker on MacOS"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </div>
      <div className="mt-4" />
      <br />
      <h3 className="introduction-contentTitle"> Xampp:</h3>{" "}
      <a
        href="https://www.apachefriends.org/download.html"
        className="introduction-link"
      >
        https://www.apachefriends.org/download.html
      </a>
      <br />
      <h3 className="introduction-contentTitle">WampServer:</h3>{" "}
      <a href="https://www.wampserver.com/en/" className="introduction-link">
        https://www.wampserver.com/en/
      </a>
      <br />
      <div className="center-page-container">
        <Link to="/qrcode-documentation/local-front" className="btn  previous">
          <p>Previous</p>
          <p className="link">Frontend</p>
        </Link>
        <Link
          to="/qrcode-documentation/mandatory-setup-backend"
          className="btn  next"
        >
          <p>Next</p>
          <p className="link"> Install on server </p>
        </Link>
      </div>
    </div>
  );
};

export default LocalServer;
