import React, { useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { GiCampfire } from "react-icons/gi";
import { FcIdea } from "react-icons/fc";
import { Link } from "react-router-dom";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

const InstallOnServer = () => {
  const [text, setText] = useState(null);

  const copyToClipBoard = async (copyMe, id) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setText(id);
    } catch (err) {
      setText("Failed to copy!");
    }
  };
  //
  return (
    <div
      className="introduction "
      data-aos="fade-right"
      data-aos-delay="300"
      data-aos-duration="1000"
    >
      <h1 className="title"> Change static data</h1>
      <h3 className="introduction-contentTitle"> Default title </h3>
      <p>
        • Open /components/seo/index.js and change site_title with your site
      </p>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a
              href="/assets/img/doc/static-sunday/title.png"
              data-fancybox
              rel="nofollow"
            >
              <img
                src="/assets/img/doc/static-sunday/title.png"
                alt="admin config"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <h3 className="introduction-contentTitle"> Why shop at Goshops </h3>
      <p>• Open /components/home/GroceryDelivery.js and change the you want</p>
      <p>• Video files are located in "/pulic/assets/media"</p>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a
              href="/assets/img/doc/static-sunday/why-sunday.png"
              data-fancybox
              rel="nofollow"
            >
              <img
                src="/assets/img/doc/static-sunday/why-sunday.png"
                alt="admin config"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <h3 className="introduction-contentTitle"> About Company </h3>
      <p>• Open /components/home/AboutCompany.js and change the you want</p>
      <div className="introduction-img-container">
        <SimpleReactLightbox>
          <SRLWrapper>
            <a
              href="/assets/img/doc/static-sunday/about-sunday.png"
              data-fancybox
              rel="nofollow"
            >
              <img
                src="/assets/img/doc/static-sunday/about-sunday.png"
                alt="admin config"
                loading="lazy"
                className="img-responsive-full"
              />
            </a>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <div className="center-page-container">
        <Link
          to="/goshops-documentation/mandatory-setup-web"
          className="btn  previous"
        >
          <p>Previous</p>
          <p className="link"> Installation </p>
        </Link>
        <Link to="/goshops-documentation/mobile-app" className="btn  next">
          <p>Next</p>
          <p className="link"> Requirements </p>
        </Link>
      </div>
    </div>
  );
};

export default InstallOnServer;
