import React from "react";
import { Route } from "react-router-dom";
import PaymentInstallation from "../views/Uzmart-doc/Payment-Installation";
import FoodySingleDoc from "../views/Foodyman-single-doc";
import FoodySingleIntroduction from "../views/Foodyman-single-doc/Introduction";
import FoodySingleBasic from "../views/Foodyman-single-doc/Basic";
import FoodySingleServer from "../views/Foodyman-single-doc/Server";
import FoodySingleFront from "../views/Foodyman-single-doc/front";
import FoodySingleAdmin from "../views/Foodyman-single-doc/admin";
import SingleRecommendations from "../views/Foodyman-single-doc/recommendations";
import FoodySingleLocalFront from "../views/Foodyman-single-doc/local-front";
import FoodySingleMobileApp from "../views/Foodyman-single-doc/Mobile-app";
import FoodySingleQRApp from "../views/Foodyman-single-doc/qr-code";
import FoodySingleFlutterSDK from "../views/Foodyman-single-doc/Flutter-SDK";
import FoodySingleLocalServer from "../views/Foodyman-single-doc/Local-server";
import FoodySingleInstallOnServer from "../views/Foodyman-single-doc/Install-on-server";
import FoodySingleInstallQRcode from "../views/Foodyman-single-doc/install-qrcode";
import FoodySingleMandatorySetup from "../views/Foodyman-single-doc/Mandatory-setup";
import FoodySingleCustomization from "../views/Foodyman-single-doc/Customization";
import FoodySingleMandatorySetupMobile from "../views/Foodyman-single-doc/Mandatory-setup-mobile";
import FoodySingleMandatorySetupBackend from "../views/Foodyman-single-doc/Mandatory-setup-backend";
import FoodySingleMandatorySetupVendor from "../views/Foodyman-single-doc/Moderator-setup-vendor";
import FoodySingleMandatorySetupCustomer from "../views/Foodyman-single-doc/Moderator-setup-customer";
import FoodySingleMandatorySetupPos from "../views/Foodyman-single-doc/Moderator-setup-pos";
import FoodySingleMandatorySetupDeliveryboy from "../views/Foodyman-single-doc/Moderator-setup-deliveryboy";
import FoodySingleCustomizationMobileVendor from "../views/Foodyman-single-doc/Customization-mobile-vendor";
import SingleCustomizationMobileDelivery from "../views/Foodyman-single-doc/Customization-mobile-delivery";
import SingleCustomizationMobileCustomer from "../views/Foodyman-single-doc/Customization-mobile-customer";
import SingleCustomizationMobilePos from "../views/Foodyman-single-doc/Customization-mobile-pos";
import AppBuildReleaseSingleCustomer from "../views/Foodyman-single-doc/App-build-release-customer";
import AppBuildReleaseSinglePos from "../views/Foodyman-single-doc/App-build-release-pos";
import AppBuildReleaseSingleVendor from "../views/Foodyman-single-doc/App-build-release-vendor";
import AppBuildReleaseSingleDelivery from "../views/Foodyman-single-doc/App-build-release-deliveryboy";
import FoodySingleMandatorySetupWeb from "../views/Foodyman-single-doc/Mandatory-setup-web";
import FoodySingleBuildCodeAndSetupOnServer from "../views/Foodyman-single-doc/Build-code-and-setup-on-server";
import FoodySingleBuildCodeAndSetupOnServerBackend from "../views/Foodyman-single-doc/Build-code-and-setup-on-server-backend";
import FoodySingleUpdateAdminPanel from "../views/Foodyman-single-doc/Update-admin-panel";
import FoodySingleUpdateAppWeb from "../views/Foodyman-single-doc/Update-app-web";
import FoodySingleSupportPlan from "../views/Foodyman-single-doc/supportPlan";
import FoodySingleFirebaseSetup from "../views/Foodyman-single-doc/firebase-setup";
import SingleTroubleshootingBackend from "../views/Foodyman-single-doc/Troubleshooting-backend";
import SingleTroubleshootingAdmin from "../views/Foodyman-single-doc/Troubleshooting-admin";
import UpdateFooymanSingle from "../views/Foodyman-single-doc/update";
import MandatorySetupKiosk from "../views/Foodyman-single-doc/Moderator-setup-kiosk";
import CustomizationMobileKiosk from "../views/Foodyman-single-doc/Customization-mobile-kiosk";
import AppBuildReleaseSingleKiosk from "../views/Foodyman-single-doc/App-build-release-kiosk";

const SingleFoodyman = () => {
  return (
    <Route path="/foodyman-single-documentation" element={<FoodySingleDoc />}>
      <Route
        path="/foodyman-single-documentation"
        element={<FoodySingleIntroduction />}
      />
      <Route
        path="/foodyman-single-documentation/basic"
        element={<FoodySingleBasic />}
      />
      <Route
        path="/foodyman-single-documentation/server"
        element={<FoodySingleServer />}
      />
      <Route
        path="/foodyman-single-documentation/front"
        element={<FoodySingleFront />}
      />
      <Route
        path="/foodyman-single-documentation/admin"
        element={<FoodySingleAdmin />}
      />
      <Route
        path="/foodyman-single-documentation/recommendations"
        element={<SingleRecommendations />}
      />
      <Route
        path="/foodyman-single-documentation/local-front"
        element={<FoodySingleLocalFront />}
      />
      <Route
        path="/foodyman-single-documentation/mobile-app"
        element={<FoodySingleMobileApp />}
      />
      <Route
        path="/foodyman-single-documentation/vendor-app"
        element={<FoodySingleMobileApp />}
      />
      <Route
        path="/foodyman-single-documentation/driver-app"
        element={<FoodySingleMobileApp />}
      />
      <Route
        path="/foodyman-single-documentation/pos-app"
        element={<FoodySingleMobileApp />}
      />
      <Route
        path="/foodyman-single-documentation/kiosk-app"
        element={<FoodySingleMobileApp />}
      />
      <Route
        path="/foodyman-single-documentation/qr-app"
        element={<FoodySingleQRApp />}
      />
      <Route
        path="/foodyman-single-documentation/flutter-sdk"
        element={<FoodySingleFlutterSDK />}
      />
      <Route
        path="/foodyman-single-documentation/local-server"
        element={<FoodySingleLocalServer />}
      />
      <Route
        path="/foodyman-single-documentation/install-on-server"
        element={<FoodySingleInstallOnServer />}
      />
      <Route
        path="/foodyman-single-documentation/install-qrcode"
        element={<FoodySingleInstallQRcode />}
      />
      <Route
        path="/foodyman-single-documentation/mandatory-setup"
        element={<FoodySingleMandatorySetup />}
      />
      <Route
        path="/foodyman-single-documentation/customization"
        element={<FoodySingleCustomization />}
      />
      <Route
        path="/foodyman-single-documentation/mandatory-setup-mobile"
        element={<FoodySingleMandatorySetupMobile />}
      />
      <Route
        path="/foodyman-single-documentation/mandatory-setup-backend"
        element={<PaymentInstallation />}
      />
      <Route
        path="/foodyman-single-documentation/payment-installation"
        element={<FoodySingleMandatorySetupBackend />}
      />
      <Route
        path="/foodyman-single-documentation/mandatory-setup-vendor"
        element={<FoodySingleMandatorySetupVendor />}
      />
      <Route
        path="/foodyman-single-documentation/mandatory-setup-customer"
        element={<FoodySingleMandatorySetupCustomer />}
      />
      <Route
        path="/foodyman-single-documentation/mandatory-setup-pos"
        element={<FoodySingleMandatorySetupPos />}
      />
      <Route
        path="/foodyman-single-documentation/mandatory-setup-kiosk"
        element={<MandatorySetupKiosk />}
      />
      <Route
        path="/foodyman-single-documentation/mandatory-setup-deliveryboy"
        element={<FoodySingleMandatorySetupDeliveryboy />}
      />
      <Route
        path="/foodyman-single-documentation/customization-vendor"
        element={<FoodySingleCustomizationMobileVendor />}
      />
      <Route
        path="/foodyman-single-documentation/customization-deliveryboy"
        element={<SingleCustomizationMobileDelivery />}
      />
      <Route
        path="/foodyman-single-documentation/customization-customer"
        element={<SingleCustomizationMobileCustomer />}
      />
      <Route
        path="/foodyman-single-documentation/customization-pos"
        element={<SingleCustomizationMobilePos />}
      />
      <Route
        path="/foodyman-single-documentation/customization-kiosk"
        element={<CustomizationMobileKiosk />}
      />
      <Route
        path="/foodyman-single-documentation/customer-app-build-release"
        element={<AppBuildReleaseSingleCustomer />}
      />
      <Route
        path="/foodyman-single-documentation/pos-app-build-release"
        element={<AppBuildReleaseSinglePos />}
      />
      <Route
        path="/foodyman-single-documentation/kiosk-app-build-release"
        element={<AppBuildReleaseSingleKiosk />}
      />
      <Route
        path="/foodyman-single-documentation/vendor-app-build-release"
        element={<AppBuildReleaseSingleVendor />}
      />
      <Route
        path="/foodyman-single-documentation/deliveryboy-app-build-release"
        element={<AppBuildReleaseSingleDelivery />}
      />
      <Route
        path="/foodyman-single-documentation/mandatory-setup-web"
        element={<FoodySingleMandatorySetupWeb />}
      />
      <Route
        path="/foodyman-single-documentation/build-code-and-setup-on-server"
        element={<FoodySingleBuildCodeAndSetupOnServer />}
      />
      <Route
        path="/foodyman-single-documentation/build-code-and-setup-on-server-backend"
        element={<FoodySingleBuildCodeAndSetupOnServerBackend />}
      />
      <Route
        path="/foodyman-single-documentation/update-admin-panel"
        element={<FoodySingleUpdateAdminPanel />}
      />
      <Route
        path="/foodyman-single-documentation/update-app-web"
        element={<FoodySingleUpdateAppWeb />}
      />
      <Route
        path="/foodyman-single-documentation/support-plan"
        element={<FoodySingleSupportPlan />}
      />
      <Route
        path="/foodyman-single-documentation/firebase"
        element={<FoodySingleFirebaseSetup />}
      />
      <Route
        path="/foodyman-single-documentation/troubleshooting-backend"
        element={<SingleTroubleshootingBackend />}
      />
      <Route
        path="/foodyman-single-documentation/troubleshooting-admin"
        element={<SingleTroubleshootingAdmin />}
      />
      <Route
        path="/foodyman-single-documentation/update"
        element={<UpdateFooymanSingle />}
      />
    </Route>
  );
};

export default SingleFoodyman;
