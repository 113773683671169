import React from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import LoadingIndicator from "./Loader/loadingIndicator";
import { ArrowTopRight } from "./Svg/goTop-svg";
import axios from "axios";

const ContactForm = () => {
  const formik = useFormik({
    initialValues: {
      ip: "",
      id: "",
      code: "",
    },
    onSubmit: (values, { setSubmitting }) => {
      axios
        .post("https://demo.githubit.com/api/v2/server/notification", values)
        .then(() => toast.success("Your request has been sent successfully"))
        .catch((error) => {
          console.log(error);
          toast.error("Your credentials are invalid");
        })
        .finally(() => setSubmitting(false));
    },
    validate: (values) => {
      const errors = {};
      if (!values.id) {
        errors.id = "Required";
      }
      if (!values.ip) {
        errors.ip = "Required";
      }
      if (!values.code) {
        errors.code = "Required";
      }
      return errors;
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit} className="contact_form">
        <div className="ptf-form-group">
          <label data-number="01">Your server ip address?</label>
          <input
            type="text"
            name="ip"
            className={formik.errors?.ip && formik.touched?.ip ? "error" : ""}
            value={formik.values.ip}
            onChange={formik.handleChange}
            autoComplete="off"
          />
        </div>

        <div className="ptf-form-group">
          <label data-number="02">Item Purchase code</label>

          <input
            type="text"
            name="code"
            className={
              formik.errors?.code && formik.touched?.code ? "error" : ""
            }
            value={formik.values.code}
            onChange={formik.handleChange}
            autoComplete="off"
          />
        </div>
        <div className="ptf-form-group">
          <label data-number="03">Item ID</label>
          <input
            type="text"
            name="id"
            className={formik.errors?.id && formik.touched?.id ? "error" : ""}
            value={formik.values.id}
            onChange={formik.handleChange}
            autoComplete="off"
          />
        </div>

        <div className="ptf-spacer" style={{ "--ptf-xxl": "5.625rem" }} />

        <button
          className="ptf-submit-button"
          type="submit"
          disabled={formik.isSubmitting}
        >
          <span>Activate</span>
          {formik.isSubmitting ? <LoadingIndicator /> : <ArrowTopRight />}
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
