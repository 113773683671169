import React from "react";
import { Link } from "react-router-dom";

const CustomizationMobileDelivery = () => {
  return (
    <div
      className="introduction "
      data-aos="fade-right"
      data-aos-delay="300"
      data-aos-duration="1000"
    >
      <h1 className="title"> Customization</h1>
      <h4 className="introduction-contentTitle">Translate mobile app</h4>
      • Translation admin panel is very easy. To translate admin panel, go to
      admin panel and open <br />
      <span className="introduction-step-2">
        Settings {">"} Translations menu
      </span>
      and Translate all words into your language
      <div className="introduction-img-container">
        <img
          src="/assets/img/doc/translation2.jpg"
          alt="img"
          className="img-responsive"
        />
        <img
          src="/assets/img/doc/translation1.jpg"
          alt="img"
          className="img-responsive"
        />
      </div>
      <h4 className="introduction-contentTitle">Change App Color</h4>• Open{" "}
      <span className="introduction-step">
        /lib/presentation/styles/style.dart
      </span>
      file and change colors as you want. <br />
      <div className="introduction-img-container">
        <img
          src="/assets/img/doc/color4.jpg"
          alt="img"
          className="img-responsive-full"
        />
      </div>
      <h4 className="introduction-contentTitle">Change App Font</h4>• in our
      apps, google font package is installed. You may change app fonts easily by
      selecting new fonts
      <div className="center-page-container">
        <Link
          to="/saas-documentation/mandatory-setup-deliveryboy"
          className="btn  previous"
        >
          <p>Previous</p>
          <p className="link"> Mandatory setup Mobile App</p>
        </Link>
        <Link
          to="/saas-documentation/deliveryboy-app-build-release"
          className="btn  next"
        >
          <p>Next</p>
          <p className="link"> App build & release </p>
        </Link>
      </div>
    </div>
  );
};

export default CustomizationMobileDelivery;
