import React from "react";
import { Helmet } from "react-helmet";
import CopyRight from "../../../components/Footer/Copyright/CopyRight";
import HeaderDark from "../../../components/Header/HeaderDark";
import FooterThreeDark from "../../../components/Footer/FooterThreeDark";
import ImageGridDemo from "../../../components/Image-grid/ImageGridDemo";
import OnePage from "../../../components/Hero/OnePage";
import { FaUserCog } from "react-icons/fa";
import { BiRadioCircle } from "react-icons/bi";
import { RiCustomerService2Fill } from "react-icons/ri";
import { TbTruckDelivery } from "react-icons/tb";
import { AiFillYoutube, AiOutlineHistory } from "react-icons/ai";
import { IoDocumentText } from "react-icons/io5";
import { TiVendorAndroid } from "react-icons/ti";
import {singleUzmart} from "../../../fake-data/Foodyman-feature";
import KeyFeatures from "../../../components/KeyFeatures";
import PaymentList from "../../../components/Payment";
import {SiGoogledomains} from "react-icons/si";
import {payments} from "../../../fake-data/Uzmart-doc-menu";

const imageList = [
  {
    img: "uzmart-single-21",
    column: "col-lg-12",
    alt: "Uzmart shop",
    link: "https://uzmart.org",
    new: false,
  },
];

const skillContent = [
  {
    icon: <FaUserCog />,
    link: "https://admin-single.uzmart.org/",
    title: "Admin panel",
    delayAnimation: "100",
    target: "_blank",
  },
  {
    icon: <SiGoogledomains />,
    link: "https://single.uzmart.org/",
    title: "Web Site",
    delayAnimation: "100",
    target: "_blank",
  },
  {
    icon: <RiCustomerService2Fill />,
    link: "https://drive.google.com/drive/folders/1o0AZd5wOkP9hev5h8fqBdSlK47sG2C9s?usp=drive_link",
    title: "Customer app",
    delayAnimation: "200",
    target: "_blank",
  },
  {
    icon: <TbTruckDelivery />,
    link: "https://testflight.apple.com/join/qBaj0od4",
    title: "Delivery app",
    delayAnimation: "300",
    target: "_blank",
  },
  {
    icon: <TiVendorAndroid />,
    link: "https://testflight.apple.com/join/wNjJx7jg",
    title: "Seller app",
    delayAnimation: "300",
    target: "_blank",
  },
  {
    icon: <AiFillYoutube />,
    link: "https://www.youtube.com/@githubit1528/videos",
    title: "Website",
    delayAnimation: "400",
    target: "_blank",
  },
  {
    icon: <IoDocumentText />,
    link: "/uzmart-single-documentation/introduction",
    title: "Documentation",
    delayAnimation: "600",
    target: "_blank",
  },
  {
    icon: <IoDocumentText />,
    link: "/uzmart-single-documentation/installation-video",
    title: "Documentation",
    delayAnimation: "600",
    target: "_blank",
  },
  {
    icon: <AiOutlineHistory />,
    link: "/uzmart-single-documentation/update",
    title: "Update",
    delayAnimation: "600",
    target: "_blank",
  },
];

export default function SingleUzmartMultiServise() {
  return (
    <div className="ptf-site-wrapper animsition ptf-is--work-showcase-1">
      <Helmet>
        <title>Githubit - Uzmart  - Single (Multi-branch) E-commerce Platform with Mobile App, Web, Seller, and Admin Panels</title>
      </Helmet>
      <HeaderDark />
      <div className="ptf-site-wrapper__inner">
        <div className="main">
          <article className="ptf-page ptf-page--single-work-1">
            <section>
              <OnePage
                skillContent={skillContent}
                buy_link={
                  "https://single.uzmart.org"
                }
                descriptions="Uzmart  - Single is Laravel, Flutter & Next js Framework based online Commerce Full Solution.
                Which provide Admin, Seller Web panel, Website & Flutter (Customer, Delivery Boy, Seller) Mobile App"
                title="Uzmart  - Single"
                subTitle="(Multi-branch) E-commerce Platform with Mobile App, Web, Seller, and Admin Panels"
                img_url="20.png"
              />
            </section>

            <section>
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "10rem", "--ptf-md": "3.125rem" }}
              />

              <KeyFeatures
                text={
                  "Uzmart  - Single is Laravel, Flutter & Next js Framework based online Commerce Full Solution. Which provide Admin, Seller Web panel, Website & Flutter (Customer, Delivery Boy, Seller) Mobile App"
                }
                features={singleUzmart}
              />

              <PaymentList data={payments} />

              <div className="container-xxl">
                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <p className="fz-20 has-black-color">
                    Welcome to Uzmart  - Single: Clothes and Electronics Ordering and Delivery
                    Marketplace.
                  </p>
                  <p className="fz-20 has-black-color">
                    Wandering what would you get when you purchase Uzmart  - Single?
                    Please read the text and see our visuals below:
                  </p>
                  <p className="fz-20 has-black-color">
                    <strong>All-in-one:</strong> <br />
                    <BiRadioCircle size={10} /> Website; (
                    <a href="https://single.uzmart.org/">https://single.uzmart.org/</a> )
                    <br />
                    <BiRadioCircle size={10} /> Mobile Customer apps (Android
                    and iOS); <br />
                    <BiRadioCircle size={10} /> Admin Panel (web); <br />
                    <BiRadioCircle size={10} /> Seller Panel (web). <br />A
                    Branch app and delivery app is available but sold
                    separately.
                  </p>
                  <p className="fz-20 has-black-color">
                    <strong>SEO and lighting fast page loading times: </strong>
                    If you already have or strive your E-commerce to expand
                    beyond your neighborhood, you will need a solution that
                    provides an excellent page load time, search engine
                    optimization (SEO) and high-throughput readiness . Uzmart  - Single
                    uses various technologies to offer these key features.
                    Specifically, we built the front-end (website) with Nextjs
                    to maximize page loading time and SEO. Our database is
                    optimized for high throughput (no impact on performance in a
                    live project with over 100,000 unique products).
                  </p>
                  <p className="fz-20 has-black-color">
                    <strong>Multi-branch options</strong>
                  </p>
                  <p className="fz-20 has-black-color">
                    <strong>A growing list of features:</strong> <br />
                    <BiRadioCircle size={10} /> Top-notch UI design; <br />
                    <BiRadioCircle size={10} /> Cashbacks; <br />
                    {/*<BiRadioCircle size={10} /> Referral; <br />*/}
                    <BiRadioCircle size={10} /> An extensive list of reports.
                    <br />
                    <BiRadioCircle size={10} /> Story; <br />
                    <BiRadioCircle size={10} /> Subscription (as part of an
                    extended license);
                    <br />
                    <BiRadioCircle size={10} /> Multiple countries selection for
                    deliveries and points.
                  </p>
                  <p className="fz-20 has-black-color">
                    <strong>Versatile: </strong> <br />
                    designed with a range of department stores in mind (Product,Digital.)
                  </p>
                  <p className="fz-20 has-black-color">
                    <strong className="fas fa-band-aid">
                      Core technologies:
                    </strong>
                    <br />
                    <BiRadioCircle size={10} /> Laravel (backend) <br />
                    <BiRadioCircle size={10} /> Flutter (mobile apps)
                    <br />
                    <BiRadioCircle size={10} /> Reactjs (admin panels)
                    <br />
                    <BiRadioCircle size={10} /> Typescript and Nextjs (website)
                    <br />
                    <BiRadioCircle size={10} /> Rest API
                  </p>
                </div>

                <div className="mt-4" />

                <div
                  className="ptf-divider"
                  style={{
                    "--ptf-height": "1px",
                    "--ptf-color": "var(--ptf-color-14)",
                  }}
                />

                <div className="mt-5" />

                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <h2>All demos</h2>
                </div>

                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "2.8125rem" }}
                />

                <div
                  className="ptf-animated-block"
                  data-aos="fade"
                  data-aos-delay="0"
                >
                  <ImageGridDemo list={imageList} />
                </div>

                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "2.8125rem" }}
                />
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "5.625rem", "--ptf-md": "2.8125rem" }}
                />
              </div>
            </section>
          </article>
        </div>
      </div>

      <footer className="ptf-footer ptf-footer--style-5">
        <div className="container-xxl">
          <div
            className="ptf-divider"
            style={{
              "--ptf-height": "1px",
              "--ptf-color": "var(--border-color)",
            }}
          />
          <div className="ptf-footer__top">
            <FooterThreeDark />
          </div>

          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
  );
}
