import React from "react";
import moment from "moment";

const UserMessage = ({ text, data, type, images }) => {
  return (
    <div className="user-sms-wrapper">
      <div className="user-message">
        {text}
        {images.map((item) => (
          <div key={item.id} className="d-flex mx-1">
            <img
              src={process.env.REACT_APP_BASE_URL + item.path}
              alt={item.title}
            />
          </div>
        ))}
        <div className="time">
          {type} {moment(data).format("HH:mm")}
        </div>
      </div>
    </div>
  );
};

export default UserMessage;
