import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FcGoogle } from "react-icons/fc";
import { useAuth } from "../../../context/AuthContext";
import authService from "../../../service/AuthService";
import { setUserData } from "../../../redux/slices/auth";

export default function GoogleSignIn() {
  const navigate = useNavigate();
  const { googleSignIn } = useAuth();
  const dispatch = useDispatch();

  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn().then((res) => {
        const body = {
          name: res.user.displayName,
          email: res.user.email,
          id: res.user.uid,
        };
        authService.socialRegister(body).then((result) => {
          const token = result.data.access_token;
          localStorage.setItem("token", token);
          dispatch(
            setUserData({
              ...result.data,
              ...result.data.user,
              user: undefined,
            })
          );
          navigate("/");
        });
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <button onClick={handleGoogleSignIn} className="social-btn" type="button">
      <FcGoogle size={26} />
      Continue with Google
    </button>
  );
}
