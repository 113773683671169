import React, { useState } from "react";
import { MdSend } from "react-icons/md";
import { useFormik } from "formik";
import TextareaAutosize from "react-textarea-autosize";
import { BsFillFileEarmarkArrowUpFill } from "react-icons/bs";
import ticketService from "../../service/TicketService";
import LoadingIndicator from "../Loader/loadingIndicator";

const MessageInput = ({ id, fetchTickets }) => {
  const [loading, setLoading] = useState(null);

  const formik = useFormik({
    initialValues: {
      message: "",
    },
    onSubmit: (values) => {
      const body = {
        message: values.message,
        ticket_id: id,
      };
      setLoading(true);
      ticketService
        .chat(body)
        .then(() => {
          fetchTickets();
          formik.resetForm();
        })
        .finally(() => setLoading(false));
    },
    validate: (values) => {
      const errors = {};
      const trimmedMessage = values.message.trim();
      if (trimmedMessage === "") {
        errors.message = "required text";
      }
      return errors;
    },
  });
  const handleChange = (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("type", "service");
    setLoading(true);
    ticketService
      .fileUpload(formData)
      .then(({ data }) => {
        const payload = {
          images: [data.title],
          ticket_id: id,
          message: "",
        };
        sendMessage(payload);
      })
      .catch(() => setLoading(false));
  };

  function sendMessage(payload) {
    ticketService
      .chat(payload)
      .then(() => fetchTickets())
      .finally(() => setLoading(false));
  }

  return (
    <form onSubmit={formik.handleSubmit} className="chat-message-container">
      <div className="chat-input">
        <TextareaAutosize
          rows={1}
          name="message"
          value={formik.values.message}
          onChange={formik.handleChange}
        />
        <label className="chat-input-label" htmlFor="file">
          <input type="file" id="file" hidden onChange={handleChange} />
          <BsFillFileEarmarkArrowUpFill className="chat-upload" />
        </label>
      </div>

      <button
        type="submit"
        className={formik.values.message === "" ? "chat-disabled" : "chat-btn"}
      >
        {!loading ? <MdSend /> : <LoadingIndicator />}
      </button>
    </form>
  );
};

export default MessageInput;
