import React, { useState } from "react";
import { Link } from "react-router-dom";
import MobileDocument from "./Menu/MobileSundayDoc";
import DarkMode from "../DarkMode";

const HeaderDark = ({ docMenu }) => {
  const [menu, setMenu] = useState(false);
  const handleClickMenu = () => setMenu(!menu);

  return (
    <>
      <header className="ptf-header--style-5 ptf-header--opaque">
        <div className={"ptf-navbar ptf-navbar--main ptf-navbar--sticky"}>
          <div className="container-xxl">
            <div className="ptf-navbar-inner">
              <Link className="ptf-navbar-logo" to="/">
                <h2 className="text-white">Githubit</h2>
              </Link>
              <DarkMode />
              <div
                className="ptf-offcanvas-menu-icon js-offcanvas-menu-toggle bar right mobile-document-menu"
                onClick={handleClickMenu}
              >
                <i className="lnir lnir-menu-alt-5" />
              </div>
              <div
                className="ptf-offcanvas-menu-icon js-offcanvas-menu-toggle bar right"
                onClick={handleClickMenu}
              />
            </div>
          </div>
        </div>
      </header>

      <div
        className={menu ? "ptf-offcanvas-menu is-open" : "ptf-offcanvas-menu "}
      >
        <div className="ptf-offcanvas-menu__header">
          <div className="ptf-language-switcher"></div>
          <span
            className="ptf-offcanvas-menu-icon js-offcanvas-menu-toggle"
            onClick={handleClickMenu}
          >
            <i className="lnir lnir-close" />
          </span>
        </div>
        <MobileDocument docMenu={docMenu} handleClickMenu={handleClickMenu} />
      </div>
    </>
  );
};

export default HeaderDark;
