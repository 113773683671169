import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiFillStar } from "react-icons/ai";
import ReactStars from "react-rating-stars-component";
import Pagination from "rc-pagination";
import ticketService from "../../../../service/TicketService";
import "../../../../assets/scss/components/ticketsTable.scss";
import Loader from "../../../../components/Loader";

const TicketsList = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const pageSize = 10;

  const fetchTickets = () => {
    setLoading(true);
    const body = {
      page,
      per_page: pageSize,
    };
    ticketService
      .getTickets(body)
      .then((res) => {
        setData(res.data.data);
        setTotal(res.data.total);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) fetchTickets();
  }, [page]);

  const handleSendId = (e) => navigate(`/tickets/${e}`);

  const setTicketRate = (id, rate) => {
    setLoading(true);
    ticketService
      .rate(id, { rate })
      .then(() => {
        const newList = data.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              rate,
            };
          }
          return item;
        });
        setData(newList);
      })
      .finally(() => setLoading(false));
  };

  const onChangePagination = (current) => {
    setPage(current);
  };

  return (
    <>
      {!loading ? (
        <div>
          <table className="table table-hover">
            <thead>
              <td>№</td>
              <td>Subject</td>
              <td>Status</td>
              <td>Creation date</td>
              <td>Rate</td>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr className="hover-table" key={item.id}>
                  <th onClick={() => handleSendId(item.id)}>{item.id}</th>
                  <td onClick={() => handleSendId(item.id)}>{item.subject}</td>
                  <td onClick={() => handleSendId(item.id)}>{item.status}</td>
                  <td onClick={() => handleSendId(item.id)}>
                    {item.created_at}
                  </td>
                  <td>
                    <ReactStars
                      size={25}
                      count={5}
                      color="#9f9f9f"
                      activeColor="#f19204"
                      value={item.rate}
                      classNames="star-table"
                      a11y={true}
                      emptyIcon={<AiFillStar />}
                      filledIcon={<AiFillStar />}
                      onChange={(newValue) => setTicketRate(item.id, newValue)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination">
            <Pagination
              total={total}
              pageSize={pageSize}
              current={page}
              onChange={onChangePagination}
            />
          </div>
        </div>
      ) : (
        <div className="text-center">
          <Loader />
        </div>
      )}
    </>
  );
};

export default TicketsList;
