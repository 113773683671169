import React from "react";
import { Link } from "react-router-dom";

const Portfolio = () => {
  return (
    <div className="ptf-content-slider swiper-container slide-portfolio">
      <div className="swiper-wrapper">
        <div className="row">
          <article className="ptf-work ptf-work--style-3 col-md-6 col-lg-4">
            <div className="ptf-work__media">
              <Link to={`/goshop-multi-shop`} className="ptf-work__link" />
              <img
                src={`assets/img/project/Banner 1 version2.png`}
                alt="gshop"
                loading="lazy"
              />
            </div>
            <div className="ptf-work__meta">
              <div className="ptf-work__category portfolio-text">
                Web & Mobile development
              </div>
              <h4 className="ptf-work__title">
                <Link to="/goshop-multi-shop">Gshop E-Commerce</Link>
              </h4>
            </div>
          </article>
          <article className="ptf-work ptf-work--style-3 col-md-6 col-lg-4">
            <div className="ptf-work__media">
              <Link
                to={`/sundaymart-multi-vendor`}
                className="ptf-work__link"
              />
              <img
                src={`assets/img/project/sundaymartGlobal.jpg`}
                alt="gshop"
                loading="lazy"
              />
            </div>
            <div className="ptf-work__meta">
              <div className="ptf-work__category portfolio-text">
                Web & Mobile development
              </div>
              <h4 className="ptf-work__title">
                <Link to="/sundaymart-multi-vendor">Sundaymart E-Commerce</Link>
              </h4>
            </div>
          </article>
          <article className="ptf-work ptf-work--style-3 col-md-6 col-lg-4">
            <div className="ptf-work__media">
              <Link to={`/foodyman-multi-service`} className="ptf-work__link" />
              <img
                src={`assets/img/project/foodyman-main-banner.jpg`}
                alt="gshop"
                loading="lazy"
              />
            </div>
            <div className="ptf-work__meta">
              <div className="ptf-work__category portfolio-text">
                Web & Mobile development
              </div>
              <h4 className="ptf-work__title">
                <Link to="/foodyman-multi-service">Foodyman E-Commerce</Link>
              </h4>
            </div>
          </article>
          <article className="ptf-work ptf-work--style-3 col-md-6 col-lg-4">
            <div className="ptf-work__media">
              <Link
                to={`/foodyman-single-service`}
                className="ptf-work__link"
              />
              <img
                src={`assets/img/project/foodyman-main-banner.jpg`}
                alt="gshop"
                loading="lazy"
              />
            </div>
            <div className="ptf-work__meta">
              <div className="ptf-work__category portfolio-text">
                Web & Mobile development
              </div>
              <h4 className="ptf-work__title">
                <Link to="/foodyman-single-service">Foodyman-Single</Link>
              </h4>
            </div>
          </article>
          <article className="ptf-work ptf-work--style-3 col-md-6 col-lg-4">
            <div className="ptf-work__media">
              <Link to={`/damda-service`} className="ptf-work__link" />
              <img
                src={`assets/img/project/16.jpg`}
                alt="damda"
                loading="lazy"
              />
            </div>
            <div className="ptf-work__meta">
              <div className="ptf-work__category portfolio-text">
                Web development
              </div>
              <h4 className="ptf-work__title">
                <Link to="/damda-service">Damda Traveling</Link>
              </h4>
            </div>
          </article>
          <article className="ptf-work ptf-work--style-3 col-md-6 col-lg-4">
            <div className="ptf-work__media">
              <Link to={`/uzmart-multi-service`} className="ptf-work__link" />
              <img
                src={`assets/img/project/18.png`}
                alt="uzmart"
                loading="lazy"
              />
            </div>
            <div className="ptf-work__meta">
              <div className="ptf-work__category portfolio-text">
                Web & Mobile development
              </div>
              <h4 className="ptf-work__title">
                <Link to="/uzmart-multi-service">Uzmart E-Commerce</Link>
              </h4>
            </div>
          </article>
          <article className="ptf-work ptf-work--style-3 col-md-6 col-lg-4">
            <div className="ptf-work__media">
              <Link to={`/uzmart-single-multi-service`} className="ptf-work__link" />
              <img
                src={`assets/img/project/20.png`}
                alt="uzmart"
                loading="lazy"
              />
            </div>
            <div className="ptf-work__meta">
              <div className="ptf-work__category portfolio-text">
                Web & Mobile development
              </div>
              <h4 className="ptf-work__title">
                <Link to="/uzmart-single-multi-service">Uzmart  - Single (Multi-branch) E-commerce</Link>
              </h4>
            </div>
          </article>
          <article className="ptf-work ptf-work--style-3 col-md-6 col-lg-4">
            <div className="ptf-work__media">
              <Link to={`/demand24-multi-service`} className="ptf-work__link" />
              <img
                src={`assets/img/project/19.png`}
                alt="demand24"
                loading="lazy"
              />
            </div>
            <div className="ptf-work__meta">
              <div className="ptf-work__category portfolio-text">
                Web & Mobile development
              </div>
              <h4 className="ptf-work__title">
                <Link to="/demand24-multi-service">Demand24 Booking & E-Commerce</Link>
              </h4>
            </div>
          </article>

          <article className="ptf-work ptf-work--style-3 col-md-6 col-lg-4">
            <div className="all-project">
              <Link
                to={`/all-web-project`}
                className="all-project-link has-white-color"
              >
                <span>More</span>
                <br />
                <span>projects</span>
                <br />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  style={{ height: "1em" }}
                  viewBox="0 0 17 17"
                >
                  <path d="M16 .997V10h-1V2.703L4.683 13l-.707-.708L14.291 1.997H6.975v-1H16z" />
                </svg>
              </Link>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
